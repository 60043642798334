export const light = {
  colors: {
    primary: {
      /*Cabeçalho, sidebar, nav e todos os cards*/
      normal: '#F1EEE9',
    },
    dark: {
      background: '#F1F1F1',
      /*Lis do menu, background atrás dos conteudo*/
      main: '#607EAA',
      /*Todo o texto da aplicação*/
      text: '#6e6b7b',
      cardText: "#f3f4f8",
      /*inputs */
      input: '#f3f4f8',
      json: '#0F3460',
      default: '#283046',
    },
    card: {
      background: "#6E85B7",
      text: "",
      border: '#A760FF',
    },
    button: {
      main: '#f3f4f8',
      hover: '#65b5f6'
    },
    menu: {
      active: '#89CFFD',
      statusCard: '#F1EFDC'
    },
    title: '#0096FF'
  }
}
