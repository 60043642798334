import styled from "styled-components";

export const IntegratorSearcherContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  background: ${({ theme }) => theme.colors.dark.main};
  padding: 15px;
  border-radius: 1rem;

  .form {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    input {
      margin-bottom: 0;
    }

    > div {
      width: 100%;

      > label {
        font-size: 14px;
        color: #fff;
      }
    }
}
`