import styled from "styled-components";

export const IfoodIntegratorContainer = styled.div`

  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;


  .table-wrapper {
    width: 100%;
    height: 40rem;
    
  }
    > .query-container{
      margin-top: 1rem;
      color: ${({ theme }) => theme.colors.dark.cardText};
    display: flex;
    width: 100%;
    background: ${({ theme }) => theme.colors.dark.main};
    border-radius: 1rem;
    padding: .8rem 2rem;
    margin-bottom: 1rem;
    gap: 2rem;
    flex-direction: row;
      > div{
        width: 100%;
        >label{
          font-size: 1.2rem;
        }
      }
  }

  
 
  @media (max-width: 800px) {
    .query-container{
      display: block;
      
    }
  }
  
  @media (max-width: 800px) and (max-height:900px){
    .query-container{
      display: block;
    }
    .table-wrapper {
    width: 100%;
    height: 30rem;
    
  }
  }
  @media (max-height:900px){
  
    .table-wrapper {
    width: 100%;
    height: 37rem;
    
  }
  }
`