import axios from "axios";
import { ErrorAlert } from '../../../components/SweetAlert/SweetAlert';

// axios.interceptors.request.use(request => {
//   return request
// })

// const errorHandler = (error: any) => {
//   return Promise.reject(error);
// };

export const Interceptors = () => axios.interceptors.response.use(
  response => {

    //200 -299
    return response
  },

  function (error) {
    // errorHandler(error)
    //300-599
    const StatusCode = error.response.status
    const ErrorMessage = error.response.data.Messages[0]
    if (error.response.status === 400) {

      ErrorAlert('Atenção', ErrorMessage || `${StatusCode}`)
    }
    else if (error.response.status === 401) {
      ErrorAlert(
        'Atenção',
        ErrorMessage || `${StatusCode} - Por algum motivo esta requisição não está autorizada. Faça login novamente e tente de novo.`
      )
    } else if (error.response.status === 404) {
      ErrorAlert('Atenção', ErrorMessage || `${StatusCode}`)
    } else if (error.response.status === 500) {
      ErrorAlert('Ops !', ErrorMessage || `${StatusCode}`)
    } else if (error.response.status === 0) {
      ErrorAlert(
        'Atenção',
        ErrorMessage || `${StatusCode} - Tentativa de conexão com a retaguarda falhou. Verifique a conexão com a internet.`

      )
    } else {
      ErrorAlert('Atenção', ErrorMessage
        || `Erro ${StatusCode} - Tente novamente mais tarde.`)
    }

    return Promise.reject(error);

  }
)