import styled from "styled-components";

export const NoItemsContainer = styled.div`
.no-category-container{
      background:${({ theme }) => theme.colors.dark.main};
      padding: 30px;
      border-radius: 0.7rem;
      display: block;
      text-align: center;
      > p{
        font-size: 20px;
      }
    }
`

