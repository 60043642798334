import { RequestItem } from '../../../../../types/integrator'

export type IntegratorCardType = typeof integratorCardData[0]

const integratorCardData = [
  {
    title: 'Pedido Pendente',
    dbkey: 'QuantidadePedidoPendente',
    defaultActive: true,
    condition: (request: RequestItem) => request.EhPedidoPendente
  },
  {
    title: 'Aguardando Aprov Auto a mais de 1 minuto',
    dbkey: 'QuantidadeAguardandoAprovacao',
    defaultActive: false,
    condition: (request: RequestItem) => request.EhAguardandoAprovacao
  },
  // {
  //   title: 'Ifoods não Aprovados Automaticamente',
  //   dbkey: 'QuantidadeIFoodNaoAprovados',
  //   defaultActive: false,
  //   condition: (request: RequestItem) => request.EhIFoodNaoAprovados
  // },
  // {
  //   title: 'Ifoods com erros no processamento',
  //   dbkey: 'QuantidadeIFoodComErros',
  //   defaultActive: false,
  //   condition: (request: RequestItem) => request.EhIFoodComErros
  // },
  // {
  //   title: 'ZAP com erros no processamento',
  //   dbkey: 'QuantidadePedzapComErros',
  //   defaultActive: false,
  //   condition: (request: RequestItem) => request.EhPedZapComErros
  // }
]

export default integratorCardData