import styled from "styled-components";

export const CloneModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.dark.background};
  border-radius: 1.2rem;
  
  width: 90%;
  max-width: 40rem;
  
  padding: 2rem 1.4rem;

  p, span, h2, button {
    color: ${({ theme }) => theme.colors.dark.text}
  }

  .heading{
    display: flex;
    justify-content: center;

    position: relative;

    margin: 0 0 1rem;

    button {
      position: absolute;
      top: 50%;
      right: 1rem;

      transform: translateY(-50%);
    }

    h2 {
      font-size: 1.8rem;
    }
  }

  .form-group {
    margin: 0 0 2rem;
  }

  .actions-group{
    display: flex;
    justify-content: space-evenly;

    button{
      background: ${({ theme }) => theme.colors.dark.text};
      border-radius: 1rem;
      color: ${({ theme }) => theme.colors.dark.background};

      padding: .4rem 1rem;
    }
  }
`