/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { rowHeaderItem } from '.'

import formatBRL from '../../utils/formatBRL'
import formatDate from '../../utils/formatDate'
import PEDIFOOD from '../../assets/images/svg/ifood-43.svg'
import Agile from '../../assets/images/logo/icone-agile-branco.png'
import Zap from '../../assets/images/svg/whats.svg'
import formatDateandHour from '../../utils/dateandhour'

type TableRowProps = {
  item: any
  index: number
  rowHeaders: rowHeaderItem[]
  noIndex: boolean
  noCanal: boolean
  getInfo: (value: string) => void
  infoProperty: string
  currentModalInfoCode: number
  personalizationAllowed?: boolean
}

function TableRow({
  item,
  index,
  rowHeaders,
  noIndex,
  noCanal,
  getInfo,
  infoProperty,
  currentModalInfoCode,
  personalizationAllowed
}: TableRowProps) {
  function handleTableRowClick(value: string) {
    getInfo(value)
  }

  const active = currentModalInfoCode === Number(item[infoProperty])
  const hasUser = item['Usuario'] != ''

  return (
    <tr
      className={
        active
          ? 'active'
          : undefined || (hasUser && personalizationAllowed)
          ? 'hasUser'
          : undefined
      }
      onDoubleClick={() => handleTableRowClick(item[infoProperty])}
    >
      <>
        {!noCanal
          ? rowHeaders.map(rowheader => {
              let value = item[rowheader.value]

              if (rowheader.format === 'img') {
                if (value === 'PEDPENDAPROVACAO') {
                  value = <img height={20} src={Agile} alt="svg" />
                } else if (value === 'PEDCRITICADO') {
                  value = <img height={20} src={Agile} alt="svg" />
                } else if (value === 'IFOODERRO') {
                  value = <img height={20} src={PEDIFOOD} alt="svg" />
                } else if (value === 'PEDIFOOD') {
                  value = <img height={20} src={PEDIFOOD} alt="svg" />
                } else if (value === 'PEDZAPERRO') {
                  value = <img height={20} src={Zap} alt="svg" />
                }
                return <td key={rowheader.value}>{value || ''}</td>
              }
            })
          : null}
        {!noIndex && <td>{index}</td>}
        {rowHeaders.map(rowHeader => {
          let value

          if (item[rowHeader.value]) {
            value = item[rowHeader.value]
          } else if (
            rowHeader.alternateValue &&
            item[rowHeader.alternateValue]
          ) {
            value = item[rowHeader.alternateValue]
          } else if (rowHeader.alternateStringValue) {
            value = rowHeader.alternateStringValue
          } else {
          }

          if (rowHeader.format === 'whats') {
            if ((value != null || '') && rowHeader.sub) {
              let sub = item[rowHeader.sub]

              value = (
                <a
                  target={'_blank'}
                  href={`https://api.whatsapp.com/send?phone=55${`${sub}${value}`}`}
                  rel="noreferrer"
                >
                  <img height={25} width={25} src={Zap} alt="whats" />
                </a>
              )
            } else {
              value = (
                <img
                  height={25}
                  width={25}
                  src={Zap}
                  alt="whats"
                  style={{ opacity: 0.5, cursor: 'not-allowed' }}
                  title="Telefone do cliente não disponivel"
                />
              )
            }
          }

          if (rowHeader.format === 'tel') {
            if (value != null && rowHeader.sub) {
              let sub = item[rowHeader.sub]
              return (value = (
                <td width={120}>
                  ({sub})
                  {`${
                    value.toString().substring(0, 4) +
                    '-' +
                    value.toString().substring(4, 9)
                  }`}
                </td>
              ))
            } else {
              return <td>Não Informado</td>
            }
          }

          if (rowHeader.format === 'date') {
            value = formatDate(value)
          }

          if (rowHeader.format === 'date&hour') {
            value = formatDateandHour(value)
          }

          if (rowHeader.format === 'currency') {
            value = formatBRL(value)
          }

          if (rowHeader.format === 'percentage') {
            value = `${value}%`
          }

          if (rowHeader.format === 'nf') {
            value = `${value === 'S' ? 'Sim' : 'Não'}`
          }
          if (rowHeader.format != 'img') {
            return <td key={rowHeader.value}>{value || ''}</td>
          }
        })}
      </>
    </tr>
  )
}

export default TableRow
