import { Route, Routes as RouterRoutes } from 'react-router-dom'
import Auth from '../pages/Auth'
import Indicators from '../pages/Indicators'
import Finances from '../pages/Finances'
import Home from '../pages/Home'
import PageWrapper from '../pages/PageWrapper'
import Integrator from '../pages/Integrator'
import { default as SearchPage } from '../pages/Search'
import Status from '../pages/Status'
import IfoodIntegrator from '../pages/IfoodIntegrator'
import ExpeditionMap from '../pages/ExpeditionMap'
import ShippedOrders from '../pages/ShippedOrders'
import MenuRegistration from '../pages/MenuRegistration/index'
import StoreList from '../pages/StoreList/storeList'
import MenuComplements from '../pages/MenuComplements'
import StorageProtectionLayer from './StorageProtectionLayer'
import AuthProtectionLayer from './AuthProtectionLayer'

function Routes() {
  return (
    <RouterRoutes>
      <Route path="/" element={<AuthProtectionLayer />}>
        <Route path="/" element={<StorageProtectionLayer />}>
          <Route path="/" element={<PageWrapper />}>
            <Route path="/" element={<Home />} />
            <Route path="/indicadores" element={<Indicators />} />
            <Route path="/financeiro" element={<Finances />} />
            <Route path="/integrador" element={<Integrator />} />
            <Route path="/consulta" element={<SearchPage />} />
            <Route path="/status" element={<Status />} />
            <Route path="/integracaoIfood" element={<IfoodIntegrator />} />
            <Route path="/mapaExpedicao" element={<ExpeditionMap />} />
            <Route path="/pedidosExpedidos" element={<ShippedOrders />} />
            <Route path="/cardapio" element={<MenuRegistration />} />
            <Route path="/lojas" element={<StoreList />} />
            <Route path="/complementos" element={<MenuComplements />} />
          </Route>
        </Route>
      </Route>
      <Route path="/auth" element={<Auth />} />
    </RouterRoutes>
  )
}

export default Routes
