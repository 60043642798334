/* eslint-disable react-hooks/exhaustive-deps */

import { AlertCircle, ListOrdered, Search } from 'lucide-react'

import PageCard from '../../components/PageCard/index'
import { StoreListContainer } from './styles'
import { useEffect, useState } from 'react'
import StoreItem from './components/storeItem/storeItem'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import axios from 'axios'
import NoItems from '../../components/NoItems/index'

function StoreList() {
  const { userToken, selectedEnvironment, licenseStorage, licenseStoreCode } =
    useSelector((state: RootState) => state.auth)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stores, setStores] = useState<any[]>([])
  const [filteredStores, setFilteredStores] = useState<any[]>()

  const typesOfFilter = [
    { id: 20, title: 'Ordenar A-Z', orderBy: 'AZ' },
    { id: 30, title: 'Ordenar Z-A', orderBy: 'ZA' }
  ]

  const getActiveFilter = (arg1: any, arg2: any): any => {
    if (activeFilter === 'AZ') {
      return arg1?.Nome > arg2?.Nome ? 1 : -1
    }
    if (activeFilter === 'ZA') {
      return arg1?.Nome < arg2?.Nome ? 1 : -1
    }
  }

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [activeFilter, setActiveFilter] = useState<string>('AZ')

  const [keywordSearch, setKeywordSearch] = useState('')

  useEffect(() => {
    getStoreList()
  }, [])

  useEffect(() => {
    setFilteredStores(
      stores
        .sort((a: any, b: any) => getActiveFilter(a, b))
        .filter(
          (e: any) =>
            e?.Nome.toLowerCase().includes(keywordSearch) ||
            e?.Sigla.toLowerCase().includes(keywordSearch) ||
            e?.CodigoExterno.toString().includes(keywordSearch)
        )
    )
  }, [keywordSearch, activeFilter, stores])

  function getStoreList() {
    const env = process.env.REACT_APP_USER_STORES

    if (!env || !selectedEnvironment.Url || !userToken) return
    ;(async () => {
      setIsLoading(true)
      try {
        const response = await axios({
          method: 'GET',
          url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${licenseStorage}`,
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })

        setIsLoading(false)
        setStores(response.data)
      } catch {
        setIsLoading(false)
      }
    })()
  }

  return (
    <PageCard title={'Listagem de Lojas'}>
      <StoreListContainer>
        <div className="StoreFilter">
          <input
            type="text"
            className="form-control"
            placeholder="Busque Pelo Nome ou Código Externo"
            onChange={e => setKeywordSearch(e.target.value)}
          />
          <button onClick={() => getStoreList()}>
            <Search color="#f3f4f8" />
          </button>
          <div className="dropdownCardapio">
            <button
              className="order-button"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <ListOrdered color="#f3f4f8" />
              {filterOpen && (
                <div className="dropdown-content">
                  {typesOfFilter.map(type => (
                    <button
                      key={type.id}
                      onClick={() => setActiveFilter(type.orderBy)}
                      title={'Filtrar itens'}
                    >
                      {activeFilter === type.orderBy ? (
                        <AlertCircle color="red" />
                      ) : (
                        ''
                      )}
                      {type.title}
                    </button>
                  ))}
                </div>
              )}
            </button>
          </div>
        </div>
        {
          filteredStores && filteredStores.length > 0
            ? <>
                <div className="content">
                  <table>
                    <tbody>
                      {filteredStores && filteredStores.length > 0
                        ? filteredStores
                        .map((obj: any) => (
                          <StoreItem
                            storeData={obj}
                            key={obj?.Id}
                            updateAPI={getStoreList}
                          />
                        )) : <NoItems title={'Nenhuma Loja Encontrada'} mt={'1rem'} />}
                    </tbody>
                  </table>
                </div>
              </>
            : <NoItems title={'Nenhuma Loja Encontrada'} mt={'1rem'} />
        }
        {isLoading && <ScaleLoader />}
      </StoreListContainer>
    </PageCard>
  )
}

export default StoreList
