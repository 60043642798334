import { ChangeEvent } from 'react'
import SubModal from '../../../components/Order/components/SubOrderModal'
import { Reason } from '../../../types/integrator'
import agile from '../../../assets/images/logo/icone-agile-branco.png'
import ifood from '../../../assets/images/svg/ifood-43.svg'
type RejectModalProps = {
  handleRejectOrder: () => void
  close: () => void
  rejectReasons: Reason[]
  setRejectReason: (reason: Reason) => void
  requestCode: string
  ifoodIcon: boolean
}

function RejectModal({
  handleRejectOrder,
  close,
  rejectReasons,
  setRejectReason,
  requestCode,
  ifoodIcon
}: RejectModalProps) {
  function handleSelectChange(e: ChangeEvent<HTMLSelectElement>) {
    const selectedRejectReason = rejectReasons.find(
      rejectReason => rejectReason.Descricao === e.target.value
    )

    if (!selectedRejectReason) return

    setRejectReason(selectedRejectReason)
  }

  return (
    <SubModal
      title={`Rejeitar Pedido Nº ${requestCode}`}
      heading="Informe o motivo de rejeição do pedido"
      confirm={handleRejectOrder}
      close={close}
      svg={ifoodIcon ? ifood : agile}
    >
      <select className="form-control" onChange={handleSelectChange}>
        {rejectReasons.map((reason: Reason) => (
          <option value={reason.Descricao} key={reason.Descricao}>
            {reason.Descricao}
          </option>
        ))}
      </select>
    </SubModal>
  )
}

export default RejectModal
