import axios from 'axios'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import SubModal from '../../../components/Order/components/SubOrderModal'
import ScaleLoader from '../../../components/ScaleLoader/ScaleLoader'
import { RootState } from '../../../store/store'
import whats from '../../../assets/images/svg/whats.svg'
import { City, Neighbourhood, Uf } from '../../../types/dne'
import { dneInstance } from '../../../utils/dneInstance'

import { cepMask, numberMask } from '../../../utils/masks'
import useFormError from '../../../hooks/useFormError'
import { validateEmptyField } from '../../../utils/fieldValidations'
import FormField from '../../../components/FormField'
import SearchSelector from '../../../components/SearchSelector'
import { RequestItem } from '../../../types/integrator'
import {
  SuccessAlert,
  ErrorAlert
} from '../../../components/SweetAlert/SweetAlert'

type PedzapModalProps = {
  close: () => void
  value: string
  currentItem: RequestItem
  update: () => void
}

function PedZapModal({ close, value, currentItem, update }: PedzapModalProps) {
  const { licenseStoreCode, selectedEnvironment, userToken, licenseStorage } =
    useSelector((state: RootState) => state.auth)

  const [receiptTxt, setReceiptTxt] = useState<string>('')
  const [nullDataModal, setNullDataModal] = useState<boolean>(false)

  const [isError, setIsError] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [uf, setUf] = useState<string>('')
  const [selectedUf, setSelectedUf] = useState<Uf>()
  const [ufList, setUfList] = useState<Uf[]>([])
  const [ufsLoading, setUfsLoading] = useState<boolean>(false)

  const [citySearch, setCitySearch] = useState<string>('')
  const [selectedCity, setSelectedCity] = useState<City>()
  const [cityList, setCityList] = useState<City[]>([])
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false)

  const [neighbourhoodSearch, setNeighbourhoodSearch] = useState<string>('')
  const [selectedNeighbourhood, setSelectedNeighbourhood] =
    useState<Neighbourhood>()
  const [neighbourhoodList, setNeighbourhoodList] = useState<Neighbourhood[]>(
    []
  )
  const [neighbourhoodsLoading, setNeighbourhoodsLoading] =
    useState<boolean>(false)

  const [street, setStreet] = useState<string>('')
  const [addressNumber, setAddressNumber] = useState<string>('')
  const [addressComplement, setAddressComplement] = useState<string>('')

  const [isCityModalOpen, setIsCityModalOpen] = useState<boolean>(false)
  const [isNeighbourhoodModalOpen, setIsNeighbourhoodModalOpen] =
    useState<boolean>(false)

  const [currentTab, setCurrentTab] = useState<'receipt' | 'form'>(
    receiptTxt.length < 1 ? 'receipt' : 'form'
  )

  const [cep, setCep] = useState<string>('')
  const [cepLoading, setCepLoading] = useState<boolean>(false)

  const [storeAttending, setStoreAttending] = useState<string>('')

  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] =
    useFormError()

  function validateFields(): boolean {
    const isUfFieldValid = validateEmptyField(
      uf,
      'uf',
      'O campo UF é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isCityFieldValid = validateEmptyField(
      selectedCity?.d || '',
      'city',
      'O campo Cidade é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isNeighbourhoodFieldValid = validateEmptyField(
      selectedNeighbourhood?.d || '',
      'neighbourhood',
      'O campo Bairro é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isStreetFieldValid = validateEmptyField(
      street,
      'street',
      'O campo Endereço é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isAddressNumberFieldValid = validateEmptyField(
      addressNumber,
      'addressNumber',
      'O campo Número é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    return (
      isUfFieldValid &&
      isCityFieldValid &&
      isNeighbourhoodFieldValid &&
      isStreetFieldValid &&
      isAddressNumberFieldValid
    )
  }

  const gatherStoreAttending = useCallback(async () => {
    const env = process.env.REACT_APP_STORE_ATTENDS

    if (!env || !userToken || !selectedEnvironment?.Url || !licenseStoreCode)
      return

    setIsLoading(true)

    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${selectedNeighbourhood?.c}/${licenseStorage}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      setStoreAttending(response?.data?.Nome || '')
    } catch (err: any) {
      const message =
        err?.response?.data?.Messages?.[0] ||
        'Não foi possível encontrar lojas para atender essa localidade.'

      setIsLoading(false)
      setStoreAttending(message)
    }
  }, [
    licenseStoreCode,
    selectedEnvironment?.Url,
    userToken,
    licenseStorage,
    selectedNeighbourhood?.c
  ])

  async function handleApprovePedzapOrder() {
    if (validateFields()) {
      const env = process.env.REACT_APP_APPROVE_WHATSAPP

      if (!env || !value || !licenseStoreCode || !selectedEnvironment?.Url)
        return

      const approveData = {
        Id: 0,
        PedZapID: value,
        CEP: cep,
        UF: selectedUf?.d,
        Numero: addressNumber,
        Complemento: addressComplement,
        Log: street,
        Bai_Codigo: selectedNeighbourhood?.c,
        Bai_Desc: selectedNeighbourhood?.d,
        Cid_Codigo: selectedCity?.c,
        Cid_Desc: selectedCity?.d,
        ResumoPedido: null
      }

      setIsLoading(true)

      try {
        await axios({
          method: 'PUT',
          url: `${selectedEnvironment?.Url}/${env}/${licenseStoreCode}/${value}`,
          data: approveData,
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })

        // setMessage('Dados atualizados com sucesso!')
        SuccessAlert('Sucesso !', 'Dados atualizados com sucesso!')
        setIsLoading(false)
        close()
        update()
      } catch (err: any) {
        const message =
          err?.response?.data?.Messages?.[0] ||
          'Não foi possível atualizar os dados desse pedido'

        setIsError(true)
        // setMessage(message)
        ErrorAlert('Atenção', message)
      }
    }
  }

  async function handleRejectPedzapOrder() {
    const env = process.env.REACT_APP_REJECT_WHATSAPP

    if (!env || !value || !licenseStoreCode || !selectedEnvironment?.Url) return

    setIsLoading(true)

    try {
      await axios({
        method: 'POST',
        url: `${selectedEnvironment?.Url}/${env}/${licenseStoreCode}/${value}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      // setMessage('Pedido rejeitado com sucesso.')
      SuccessAlert('Sucesso !', 'Pedido rejeitado com sucesso')
      close()
      update()
    } catch (err: any) {
      setIsLoading(false)
      setIsError(true)
      // setMessage(
      //   'Não foi possível rejeitar o pedido, tente novamente mais tarde.'
      // )
      ErrorAlert(
        'Atenção',
        'Não foi possível rejeitar o pedido, tente novamente mais tarde.'
      )
    }
  }

  const handleGatherPedzapInfo = useCallback(async () => {
    const env = process.env.REACT_APP_TXT_IFOOD

    if (!env || !licenseStoreCode || !selectedEnvironment?.Url) return

    try {
      setIsLoading(true)

      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${value}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      if (response.data.length < 1) {
        throw new Error('No Data')
      }

      setIsLoading(false)
      setReceiptTxt(response.data)
      setNullDataModal(false)
    } catch (err: any) {
      setNullDataModal(true)
      setIsLoading(false)
      setIsError(false)
      // setMessage('Não foi possível obter as informações deste pedido')

      setCurrentTab('form')
    }
  }, [licenseStoreCode, selectedEnvironment?.Url, userToken, value])

  const handleGatherUfs = useCallback(async () => {
    const env = process.env.REACT_APP_GET_UFS

    if (!env) return

    setUfsLoading(true)

    const response = await dneInstance({
      url: env
    })

    setUfList(response.data)
    setUfsLoading(false)
  }, [])

  const handleGatherCities = useCallback(async () => {
    const env = process.env.REACT_APP_GET_CITIES

    if (!env) return

    setCitiesLoading(true)

    const response = await dneInstance({
      url: env,
      params: {
        $top: 20,
        $filter: `(UFE_SG eq '${selectedUf?.d}') and substringof('${citySearch}',LOC_NO)`
      }
    })

    setCityList(response.data)
    setCitiesLoading(false)
  }, [citySearch, selectedUf])

  const handleGatherNeighbourhoods = useCallback(async () => {
    const env = process.env.REACT_APP_GET_NEIGHBOURHOODS

    if (!env) return

    setNeighbourhoodsLoading(true)

    const response = await dneInstance({
      url: env,
      params: {
        $top: 20,
        $filter: `(UFE_SG eq '${selectedUf?.d}') and (LOC_NO eq '${selectedCity?.d}') and substringof('${neighbourhoodSearch}',BAI_NO)`
      }
    })

    setNeighbourhoodList(response.data)
    setNeighbourhoodsLoading(false)
  }, [selectedUf, selectedCity, neighbourhoodSearch])

  const handleGatherCEPInformation = useCallback(async () => {
    const env = process.env.REACT_APP_GET_CEP

    if (!env) return

    setCepLoading(true)

    const response = await dneInstance({
      url: env,
      params: {
        cep: numberMask(cep)
      }
    })

    setStreet(response?.data?.log?.d || '')

    handleChangeSelectedUf(response?.data?.uf)

    setCitySearch(response?.data?.cid?.d)
    setSelectedCity(response?.data?.cid)

    setNeighbourhoodSearch(response?.data?.bai?.d || '')
    setSelectedNeighbourhood(response?.data?.bai || {})
    setCepLoading(false)
  }, [cep])

  function handleChangeTab(tab: 'receipt' | 'form') {
    setCurrentTab(tab)
  }

  function handleChangeCep(value: string) {
    const parsedValue = cepMask(value)

    setCep(parsedValue)
  }

  function handleChangeSelectedUf(value: string) {
    setSelectedUf({
      d: value
    })
    setUf(value)

    setCitySearch('')
    setSelectedCity({} as City)

    setNeighbourhoodSearch('')
    setSelectedNeighbourhood({} as Neighbourhood)
  }

  function handleChangeSelectedCity(selectedCity: City) {
    const choosenCity = cityList.find(city => city.d === selectedCity.d)

    if (!choosenCity) return

    setCitySearch(choosenCity.d)
    setSelectedCity(choosenCity)
    setNeighbourhoodSearch('')
    setSelectedNeighbourhood({} as Neighbourhood)
  }

  function handleChangeSelectedNeighbourhood(
    selectedNeighbourhood: Neighbourhood
  ) {
    const choosenNeighbourhood = neighbourhoodList.find(
      neighbourhood => neighbourhood.d === selectedNeighbourhood.d
    )

    if (!choosenNeighbourhood) return

    setSelectedNeighbourhood(choosenNeighbourhood)
  }

  function handleChangeAddressNumber(value: string) {
    const parsedValue = numberMask(value) || ''

    setAddressNumber(parsedValue)
  }

  function cleanForm() {
    setCitySearch('')
    setNeighbourhoodSearch('')
    setUf('')

    setSelectedCity({} as City)
    setSelectedNeighbourhood({} as Neighbourhood)
    setSelectedUf({} as Uf)
  }

  useEffect(() => {
    handleGatherPedzapInfo()
  }, [handleGatherPedzapInfo])

  useEffect(() => {
    if (currentTab !== 'form' || ufList.length > 0) return

    handleGatherUfs()
  }, [currentTab, ufList, handleGatherUfs])

  useEffect(() => {
    if (!selectedUf?.d) return

    if (citySearch.length < 3) {
      setCityList([])
      return
    }

    const dataTimeout = setTimeout(handleGatherCities, 500)

    return () => clearTimeout(dataTimeout)
  }, [selectedUf, citySearch, handleGatherCities])

  useEffect(() => {
    if (!selectedUf?.d || !selectedCity?.c) return

    const dataTimeout = setTimeout(handleGatherNeighbourhoods, 500)

    return () => clearTimeout(dataTimeout)
  }, [
    selectedUf,
    selectedCity,
    neighbourhoodSearch,
    handleGatherNeighbourhoods
  ])

  useEffect(() => {
    if (cep.length !== 9) {
      cleanForm()
      return
    }

    handleGatherCEPInformation()
  }, [cep, handleGatherCEPInformation])

  useEffect(() => {
    if (!selectedNeighbourhood?.c) {
      setStoreAttending('')
      return
    }

    gatherStoreAttending()
  }, [selectedNeighbourhood?.c, gatherStoreAttending])

  const somethingLoading =
    isLoading ||
    ufsLoading ||
    citiesLoading ||
    neighbourhoodsLoading ||
    cepLoading

  const cepFieldProps = useMemo(
    () => ({
      label: 'CEP',
      value: cep,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        handleChangeCep(e.target.value),
      error: getErrorByFieldname('cep')?.message
    }),
    [cep, getErrorByFieldname]
  )

  return (
    <>
      {!isError && (
        <SubModal
          title="Ajuste dados pedido Pedzap"
          svg={whats}
          confirm={handleApprovePedzapOrder}
          close={close}
          noActions
          big
        >
          <div className="tab-switch">
            <button
              className={currentTab === 'receipt' ? 'tab active' : 'tab'}
              type="button"
              onClick={() => handleChangeTab('receipt')}
              disabled={nullDataModal}
              title={
                nullDataModal ? 'Nenhum dado na comanda para ser mostrado' : ''
              }
            >
              Comanda
            </button>
            <button
              className={currentTab === 'form' ? 'tab active' : 'tab'}
              type="button"
              onClick={() => handleChangeTab('form')}
            >
              Endereço
            </button>
          </div>
          <div className="tab-wrapper">
            {currentTab === 'receipt' ? (
              <div className="message-container">
                <pre>{receiptTxt}</pre>
              </div>
            ) : (
              <>
                <div className="form-group">
                  <FormField info={cepFieldProps} />
                  <div className="row">
                    <div>
                      <label>UF</label>
                      <select
                        className="form-control"
                        onChange={e => handleChangeSelectedUf(e.target.value)}
                        value={uf}
                      >
                        <option value=""></option>
                        {ufList &&
                          ufList.length > 0 &&
                          ufList.map(uf => (
                            <option key={uf.d} value={uf.d}>
                              {uf.d}
                            </option>
                          ))}
                      </select>
                      <div className="error">
                        <span>{getErrorByFieldname('uf')?.message}</span>
                      </div>
                    </div>
                    <div className="plugin-select">
                      <label>Cidade</label>

                      <button
                        type="button"
                        className="form-button"
                        onClick={() => setIsCityModalOpen(true)}
                        disabled={!Boolean(selectedUf?.d)}
                      >
                        {selectedCity?.d
                          ? selectedCity.d
                          : 'Selecione a cidade'}
                      </button>
                      <div className="error">
                        <span>{getErrorByFieldname('city')?.message}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label>Bairro</label>
                    <button
                      type="button"
                      className="form-button"
                      onClick={() => setIsNeighbourhoodModalOpen(true)}
                      disabled={!Boolean(selectedCity?.d)}
                    >
                      {selectedNeighbourhood?.d
                        ? selectedNeighbourhood.d
                        : 'Selecione o bairro'}
                    </button>
                    <div className="error">
                      <span>
                        {getErrorByFieldname('neighbourhood')?.message}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      <label>Endereço</label>
                      <input
                        className="form-control"
                        id="endereco"
                        value={street}
                        onChange={e => setStreet(e.target.value)}
                      />
                      <div className="error">
                        <span>{getErrorByFieldname('street')?.message}</span>
                      </div>
                    </div>
                    <div>
                      <label>Nr</label>
                      <input
                        className="form-control"
                        id="nr"
                        value={addressNumber}
                        onChange={e =>
                          handleChangeAddressNumber(e.target.value)
                        }
                      />
                      <div className="error">
                        <span>
                          {getErrorByFieldname('addressNumber')?.message}
                        </span>
                      </div>
                    </div>
                    <div>
                      <label>Compl</label>
                      <input
                        className="form-control"
                        id="comp"
                        value={addressComplement}
                        onChange={e => setAddressComplement(e.target.value)}
                      />
                    </div>
                  </div>
                  {storeAttending && (
                    <div>
                      <label>Loja que vai atender</label>
                      <p>{storeAttending}</p>
                    </div>
                  )}
                </div>
                <div className="zapActions">
                  <button type="button" onClick={handleRejectPedzapOrder}>
                    Rejeitar Pedido
                  </button>

                  <button type="button" onClick={handleApprovePedzapOrder}>
                    Atualizar dados
                  </button>
                </div>
              </>
            )}
          </div>
        </SubModal>
      )}

      {/* {message && (isError || isSuccess) && (
        <OverlayNotification text={message} close={close} />
      )} */}

      {somethingLoading && <ScaleLoader />}

      {isCityModalOpen && (
        <SearchSelector
          data={cityList}
          action={handleChangeSelectedCity}
          close={() => setIsCityModalOpen(false)}
          searchValue={citySearch}
          changeSearchValue={(value: string) => setCitySearch(value)}
        />
      )}

      {isNeighbourhoodModalOpen && (
        <SearchSelector
          data={neighbourhoodList}
          action={handleChangeSelectedNeighbourhood}
          close={() => setIsNeighbourhoodModalOpen(false)}
          searchValue={neighbourhoodSearch}
          changeSearchValue={(value: string) => setNeighbourhoodSearch(value)}
        />
      )}
    </>
  )
}
export default PedZapModal
