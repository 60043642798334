import { useEffect, useState } from 'react'

type useDateIntervalReturn = [
  string,
  (value: string) => void,
  string,
  (value: string) => void
]

export default function useDateInterval(): useDateIntervalReturn {
  const [initialDateValue, setInitialDateValue] = useState<string>('')
  const [finalDateValue, setFinalDateValue] = useState<string>('')

  useEffect(() => {
    const date = new Date().toLocaleDateString()
    const splittedDate = date.split('/')

    const todaysDateValue = `${splittedDate[2]}-${splittedDate[1].padStart(2, '0')}-${splittedDate[0].padStart(2, '0')}`

    setInitialDateValue(todaysDateValue)
    setFinalDateValue(todaysDateValue)
  }, [])

  return [
    initialDateValue,
    setInitialDateValue,
    finalDateValue,
    setFinalDateValue
  ]
}