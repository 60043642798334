
import styled from 'styled-components';

const SearchSelectorContainer = styled.div`
 background: ${({ theme }) => theme.colors.dark.main};
 padding: 1rem;
  width: 30rem;
  height: 30rem;
  border-radius: 0.8rem;
  border: 1px solid #937DC2;
  overflow: hidden;

 .search-header{
  display: flex;
  justify-content: end;
  color: ${({ theme }) => theme.colors.dark.cardText};
  margin-bottom: 1rem;
  
  > p{
    font-size: 14px;
  }
 svg{
  color: ${({ theme }) => theme.colors.dark.cardText};
  }
 }

 .search-results{
  
  height: 100%;
  overflow: auto;
  
  > button{
    display: flex;
    
    width: 100%;
    color: ${({ theme }) => theme.colors.dark.cardText};
    margin-bottom: 0.5rem;
    &:hover{
      background: #A149FA;
    }
  }
  
 }
 .instructions{
  color: ${({ theme }) => theme.colors.dark.cardText};
 }
`

export default SearchSelectorContainer
