import axios from 'axios'

import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import OverlayNotification from '../../components/OverlayNotification'
import PageCard from '../../components/PageCard'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import Table, { rowHeaderItem } from '../../components/Table'
import { RootState } from '../../store/store'
import { IFoodStore } from '../../types/user'

function Status() {
  const { selectedEnvironment, userToken, licenseStoreCode } = useSelector(
    (state: RootState) => state.auth
  )

  const [stores, setStores] = useState<IFoodStore[]>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>()

  const handleGetStatusReport = useCallback(async () => {
    const env = process.env.REACT_APP_STORE_STATUS

    if (!env || !selectedEnvironment?.Url || !licenseStoreCode) return

    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

    setIsLoading(true)

    try {
      const response = await axios({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      setStores(response.data)
    } catch (err: any) {
      const message =
        err?.response?.data?.Messages?.[0] ||
        'Não foi possível obter os dados das lojas iFood.'

      setIsLoading(false)
      setError(message)
    }
  }, [licenseStoreCode, selectedEnvironment.Url, userToken])

  useEffect(() => {
    handleGetStatusReport()
  }, [handleGetStatusReport])

  const tableHeadItems: string[] = ['Codigo Loja', 'Loja', 'Status']

  const tableRowHeaders: rowHeaderItem[] = [
    {
      value: 'CodigoLoja'
    },
    {
      value: 'NomeLoja'
    },
    {
      value: 'Status'
    }
  ]

  return (
    <PageCard
      title="Status Lojas iFood"
      buttonAction={handleGetStatusReport}
      button
    >
      {stores && stores.length > 0 && (
        <>
          <Table
            items={stores}
            headItems={tableHeadItems}
            rowHeaders={tableRowHeaders}
            noIndex={true}
            noCanal={true}
          />
        </>
      )}

      {isLoading && <ScaleLoader />}

      {error && <OverlayNotification text={error} close={() => setError('')} />}
    </PageCard>
  )
}

export default Status
