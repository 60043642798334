import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    caret-color: transparent;
  }

  input {
    caret-color: initial;
  }

  :root {
    font-size: 62.5%;
  }

  .bold {
    font-weight: bold;
  }

  html, body {
    background: ${({ theme }) => theme.colors.dark.background};

    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;

    scroll-behavior: smooth;
  }

  .swal2-container {
  z-index: 100000;
}
  #root {
    width: 100vw;
    height: 100vh;
  }

  .app {
    height: 100%;
    width: 100%;
  }
  
  a {
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }
  
  button {
    background: none;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  p, span, h1, h2, h3, h4, h5 {
    cursor: default;
    /* color: ${({ theme }) => theme.colors.dark.text}; */
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.45;
    color: ${({ theme }) => theme.colors.dark.input};
    background-color: ${({ theme }) => theme.colors.dark.main};
    border-color: ${({ theme }) => theme.colors.button.main};
    border-radius: 0.357rem;
    margin-bottom: 1rem;

    &:focus{
      color: ${({ theme }) => theme.colors.dark.cardText};
      background-color: ${({ theme }) => theme.colors.dark.main};
    }
    
    &::placeholder { 
      color: ${({ theme }) => theme.colors.dark.input};
    } 

    &:disabled{
      opacity: 0.5;

      &:hover{
        cursor: not-allowed;
      }
    }
  }

  .card-box-shadow {
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
  }

  .thematic-scroll{
    ::-webkit-scrollbar {
      width: 0.5em;
      height: 1em;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.dark.main};
      border-radius: 100vw;
      margin-block: 0.5em;
    }

    ::-webkit-scrollbar-thumb {
      background: #7A86B6;   
      border-radius: 100vw;
    }
  }

  .dropdownCardapio {
    margin-left: 0.5rem;
  position: relative; 
  display: inline-block; 
 
  > .order-button{
    padding: 12px;
    border-radius: 0.8rem;
    margin-bottom: 0.5rem;
  }
} 
 
.dropbtn { 
  
  border-radius: 1rem;
  color:${({ theme }) => theme.colors.dark.cardText}; 
  margin-left: 1rem;
  border: none; 
  cursor: pointer;
  
  > .active1{
    background: #fff;
  }
} 
 
.dropdown-content {
  
  position: absolute;
  top: 18px;
  right: 5px;
  background-color: #645CAA;
  min-width: 160px; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  z-index: 1; 
  
 
} 
 
.dropdown-content button {
  color:${({ theme }) => theme.colors.dark.cardText}; 
  width:100%;
  padding: 12px 16px; 
  text-decoration: none; 
  display: flex; 
  
> svg{
  margin-right: 1.5rem;
}

  &:hover{
    background-color: #7A86B6;
  }
} 

.is-hidden{
  margin-right: -100%;
  transition: all .4s;
}

.showed-up{
  margin-right: 0;
  transition: all .3s;
}
`

