export function numberMask (text: string | undefined) {
  if (!text) return
  
  return text.replace(/\D/g, '')
}

export function cepMask (value: string | undefined) {
  if (!value) return ''

  return (
    value
      .replace(/[\D]/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})(\d)/, '$1')
  )
}