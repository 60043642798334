import { Activity, ClipboardList, Database, Home, LayoutDashboard, List, LogOut, Wallet, Locate, Map, Bike, Settings, FileCheck2, Building, EggFried } from 'lucide-react'

export const sidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: Home,
    id: 1,
    isSub: false,
  },
  {
    title: 'Dashboard',
    icon: LayoutDashboard,
    id: 2,
    isSub: false,
    subNav: [
      {
        title: 'Indicadores',
        path: '/indicadores',
        id: 21,
        icon: Activity,
        isSub: true,
      },
      {
        title: 'Financeiro',
        path: '/financeiro',
        id: 22,
        icon: Wallet,
        isSub: true,
      }
    ]
  },
  {
    title: 'Pedidos',
    icon: List,
    id: 3,
    isSub: false,
    subNav: [
      {
        title: 'Integrador',
        path: '/integrador',
        id: 31,
        icon: Database,
        isSub: true,
      }, {
        title: 'Consulta',
        path: '/consulta',
        id: 32,
        icon: ClipboardList,
        isSub: true,
      }
    ]
  },
  // {
  //   title: 'Ifood',
  //   icon: Utensils,
  //   id: 4,
  //   isSub: false,
  //   subNav: [
  //     {
  //       title: 'Status',
  //       path: '/status',
  //       icon: LayoutList,
  //       id: 41,
  //       isSub: true,
  //     },
  //     {
  //       title: 'Integração Ifood',
  //       path: '/integracaoifood',
  //       icon: Airplay,
  //       id: 42,
  //       isSub: true,
  //     },
  //   ]
  // },
  {
    title: 'Expedição',
    icon: Locate,
    id: 6,
    isSub: false,
    subNav: [
      {
        title: 'Mapa',
        path: '/mapaExpedicao',
        icon: Map,
        id: 61,
        isSub: true
      }, {
        title: 'Pedidos expedidos',
        path: '/pedidosExpedidos',
        icon: Bike,
        id: 62,
        isSub: true
      }
    ]
  }, {
    title: 'Configurações',
    icon: Settings,
    id: 5,
    isSub: false,
    subNav: [

      {
        title: 'Cardapio',
        path: '/cardapio',
        icon: FileCheck2,
        id: 51,
        isSub: true,
      },
      {
        title: 'Complementos',
        path: '/complementos',
        icon: EggFried,
        id: 52,
        isSub: true,
      },
      {
        title: 'Lojas',
        path: '/lojas',
        icon: Building,
        id: 53,
        isSub: true,
      },

    ]
  },
  {
    title: 'Sair',
    path: '/auth',
    icon: LogOut,
    id: 7,
    isSub: false,
  }
]

export type SidebarItem = typeof sidebarData[1]
