import ReactDOM from 'react-dom'
import Overlay from '../../../../components/Overlay'
import { SideModalContainer } from './styles'
import { X } from 'lucide-react'
import indisponivel from '../../../../assets/images/generic/indisponivel.png'
import Switch from 'react-switch'
import { useEffect, useState, useCallback } from 'react'

import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import ScaleLoader from '../../../../components/ScaleLoader/ScaleLoader'
import axios from 'axios'

import { ErrorAlert, SuccessAlert } from '../../../../components/SweetAlert/SweetAlert'

import TechnicalFile from '../tabs/technicalFile/technicalFile'
import Detail from '../tabs/Detail/detail'

import Ingredient from '../tabs/Ingredient/ingredient'
import { numberMask } from '../../../../utils/masks'
import Price from '../../../../components/Price'
import useFormError from '../../../../hooks/useFormError'
import { validateEmptyField } from '../../../../utils/fieldValidations'

const baseItemSpecification = {
  Chef: 'NAO',
  FichaTecnica: [],
  FotoUri: null,
  Ingredientes: [],
  TabelasPreco: [],
  ReceitaCurta: '',
  Oferta: 'NAO',
  Nome: '',
  SaidaImpressao: '',
  Ativo: true
}

type SideModalProps = {
  title: string
  close: () => void
  caption: string
  id?: number
  addCategory?: boolean
  editCategory?: boolean
  addItem?: boolean
  editItem?: boolean
  fullObj?: any[]
  sequence?: number
  middle?: boolean
  item: any
  handleGetCardapioItems: () => void
}

function SideModal({
  title,
  close,
  caption,
  addCategory,
  editCategory,
  addItem,
  fullObj,
  sequence,
  editItem,
  middle,
  item,
  handleGetCardapioItems
}: SideModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { selectedEnvironment, licenseStoreCode, userToken, storeList } =
    useSelector((state: RootState) => state.auth)

  // const [IdSelected, setIdSelected] = useState<string>(
  //   editItem && tabelas.length > 0 ? tabelas[0].id || '1' : null
  // )

  // itemImg ||
  const [, setApiImage] = useState<any>(indisponivel)

  const [image] = useState<File>()

  const [defaultTable, setDefaultTable] = useState<any>()

  //Alterador de abas
  const [selectedTab, setSelectedTab] = useState<
    'detail' | 'ingredient' | 'price' | 'technicalFile'
  >('detail')

  //States de categorias.

  const [categoryName, setCategoryName] = useState<string>(editCategory ? title || '' : '')
  const [categorySequence, setCategorySequence] = useState<number>(
    sequence || 0
  )
  const [middleToMiddle, setMiddleToMiddle] = useState<boolean>(middle || false)

  //Itens.

  //states de detail
  const [codItem] = useState(item?.Codigo)
  const [codCategoria, setCodCategoria] = useState<number>(
    item?.CodigoCategoria || 0
  )
  const [NomePrato, setNomePrato] = useState<string>(item?.Nome || '')
  const [saidaImpressao, setSaidaImpressao] = useState<string>(
    item?.SaidaImpressao || ''
  )
  const [oferta, setOferta] = useState<string>(item?.Oferta || 'NAO')
  const [sugestaoChef, setSugestaoChef] = useState<string>(item?.Chef || 'NAO')
  const [fotoProduto] = useState<string>(item?.FotoUri || '')
  const [descricaoProduto, setDescricaoProduto] = useState<string>(
    item?.ReceitaCurta || ''
  )
  const [ingredientes, setIngredientes1] = useState([])

  const [fichaTecnica, setFichaTecnica1] = useState([])

  const [selectedTableStoreList, setSelectedTableStoreList] = useState<any>([])
  const [isThereAnySelectedStores, setIsThereAnySelectedStores] = useState<boolean>(false)

  //state da API de especificacao
  const [itemSpecification, setItemSpecification] = useState<any>(
    baseItemSpecification
  )

  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] = useFormError()

  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setApiImage(reader.result as string)
      }

      reader.readAsDataURL(image)
    }
  }, [image])

  function handleChangeTab(
    tab: 'detail' | 'ingredient' | 'price' | 'technicalFile'
  ) {
    setSelectedTab(tab)
  }

  function handleToggleStore(storeCode: number, tableCode: number) {
    const selectedStore = storeList.find(
      (store: any) => store.CodigoExterno === storeCode
    )

    if (!selectedStore) return

    setItemSpecification((prevState: any) => ({
      ...prevState,
      TabelasPreco: prevState.TabelasPreco.map((priceTable: any) =>
        priceTable.Id === tableCode
          ? {
              ...priceTable,
              Lojas: priceTable?.Lojas ? priceTable.Lojas?.find(
                (store: any) => store.Codigo === storeCode
              )
                ? priceTable.Lojas?.filter(
                    (store: any) => store.Codigo !== storeCode
                  )
                : [
                    ...priceTable.Lojas,
                    {
                      Codigo: selectedStore.CodigoExterno,
                      Nome: selectedStore.Nome
                    }
                  ] : [{
                    Codigo: selectedStore.CodigoExterno,
                    Nome: selectedStore.Nome
                  }]
            }
          : priceTable
      )
    }))
  }

  const getDefaultTable = useCallback(async () => {
    const env = process.env.REACT_APP_CARDAPIO_PRICE_DEFAULT_TABLE

    if (!env || !selectedEnvironment.Url || !userToken || !licenseStoreCode)
      return

    const response = await axios({
      method: 'GET',
      url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    setDefaultTable(response.data)
  }, [licenseStoreCode, selectedEnvironment?.Url, userToken])

  const ItemTabs = [
    { id: 10, name: 'Detalhes', tab: 'detail', caption: 'Detalhes do Item' },
    {
      id: 20,
      name: 'Ingredientes',
      tab: 'ingredient',
      caption: 'Informe os ingredientes desse item'
    },
    {
      id: 30,
      name: 'Preço',
      tab: 'price',
      caption: 'Configuração de valores dos tamanhos para cada Meio de Venda'
    },
    {
      id: 40,
      name: 'Ficha Tecnica',
      tab: 'technicalFile',
      hidden: addItem,
      caption:
        'Grupos de complementos que compõem esse item. Selecione o tamanho para configurá-los.'
    }
  ]

  function handleAddNewTable(name: string, id: number) {
    if (!defaultTable || !name || !id) return

    setItemSpecification((prevState: any) => ({
      ...prevState,
      TabelasPreco: [
        ...prevState.TabelasPreco,
        {
          ...defaultTable,
          Nome: name,
          Id: id,
          Lojas: []
        }
      ]
    }))
  }

  function handleRemoveTable(id: number) {
    if (!id) return

    setItemSpecification((prevState: any) => ({
      ...prevState,
      TabelasPreco: prevState.TabelasPreco.filter(
        (table: any) => table?.Id !== id
      )
    }))
  }

  const getItemSpecifications = useCallback(async () => {
    const env = process.env.REACT_APP_CARDAPIO_ITEM_GET

    if (!env || !selectedEnvironment.Url || !userToken || !item?.Codigo) return

    setIsLoading(true)
    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${item.Codigo}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setItemSpecification(response.data)
      setIngredientes1(response.data.Ingredientes)

      setFichaTecnica1(response.data.FichaTecnica)

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
    }
  }, [item?.Codigo, licenseStoreCode, selectedEnvironment?.Url, userToken])

  useEffect(() => {
    if (selectedTableStoreList.length === 0) {
      setIsThereAnySelectedStores(false)
      return
    }

    setIsThereAnySelectedStores(true)
  }, [selectedTableStoreList])

  useEffect(() => {
    getItemSpecifications()
    getDefaultTable()
  }, [getDefaultTable, getItemSpecifications])

  function validateItemFields(): boolean {
    const isCodCategoriaValid = validateEmptyField(
      String(codCategoria),
      'codCategoria',
      'O campo Categoria é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isNomePratoValid = validateEmptyField(
      NomePrato,
      'nomePrato',
      'O campo Nome do Prato é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isSaidaImpressaoValid = validateEmptyField(
      saidaImpressao,
      'saidaImpressao',
      'O campo Saida de Impressão é obrigatório',
      setNewError, 
      cleanErrorsByFieldname
    )

    if (!isCodCategoriaValid || !isNomePratoValid  || !isSaidaImpressaoValid) {
      setSelectedTab('detail')
      return false
    }

    if (!isThereAnySelectedStores) {
      ErrorAlert('Erro', 'Você precisa selecionar pelo menos uma loja na tabela de preços')
      setSelectedTab('price')
      return false
    }

    return true
  }

  function validateCategoryFields(): boolean {
    const isCategoryNameValid = validateEmptyField(
      categoryName,
      'categoryName',
      'O campo Nome da Categoria é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isCategorySequenceValid = validateEmptyField(
      String(categorySequence),
      'categorySequence',
      'O campo Sequência é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    return (isCategoryNameValid && isCategorySequenceValid)
  }

  async function AddNewCategory() {
    if (!validateCategoryFields()) return

    const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_FAMILY

    if (!env || !selectedEnvironment.Url || !userToken) return

    const payload = {
      CodigoCategoria: 0,
      DescricaoCategoria: categoryName,
      Tipo: 'ITEM',
      Sequencia: categorySequence,
      PodeMeioMeio: middleToMiddle,
      Ativo: true
    }

    setIsLoading(true)
    try {
      await axios({
        method: 'POST',
        data: payload,
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      SuccessAlert('Sucesso', `Categoria ${categoryName} foi adicionada.`)
      close()
      handleGetCardapioItems()
    } catch (err: any) {
      setIsLoading(false)
    }
  }

  async function EditCategory() {
    if (!validateCategoryFields()) return

    const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_FAMILY

    if (!env || !selectedEnvironment.Url || !userToken || !item.CodigoCategoria)
      return

    const payload = {
      CodigoCategoria: item.CodigoCategoria,
      DescricaoCategoria: categoryName,
      Tipo: 'ITEM',
      Sequencia: categorySequence,
      PodeMeioMeio: middleToMiddle,
      Ativo: true
    }

    setIsLoading(true)
    try {
      await axios({
        method: 'PUT',
        data: payload,
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${item.CodigoCategoria}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      SuccessAlert(
        'Sucesso',
        `Categoria ${payload?.CodigoCategoria} - ${payload?.DescricaoCategoria} editada com sucesso.`
      )
      handleGetCardapioItems()
      close()
    } finally {
      setIsLoading(false)
    }
  }

  async function AddItem() {
    if (!validateItemFields()) return

    setIsLoading(true)
    const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_ITEM

    const payload = {
      Codigo: 0,
      Nome: NomePrato,
      ReceitaCurta: descricaoProduto,
      Ativo: true,
      CodigoCategoria: codCategoria,
      FotoUri: fotoProduto,
      Chef: sugestaoChef,
      Oferta: oferta,
      SaidaImpressao: saidaImpressao,
      Ingredientes: ingredientes || [],
      TabelasPreco: itemSpecification?.TabelasPreco || [],
      FichaTecnica: fichaTecnica || []
    }

    try {
      await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      SuccessAlert(
        'Sucesso',
        `Item ${NomePrato} adicionado a categoria ${codCategoria}.`
      )
      close()
      handleGetCardapioItems()
    } finally {
      setIsLoading(false)
    }
  }

  async function EditItem() {
    if (!validateItemFields()) return

    setIsLoading(true)
    const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_ITEM

    if (!env || !selectedEnvironment.Url || !userToken || !item?.Codigo) return

    const payload = {
      Codigo: codItem,
      Nome: NomePrato,
      ReceitaCurta: descricaoProduto,
      Ativo: true,
      CodigoCategoria: codCategoria,
      FotoUri: fotoProduto,
      Chef: sugestaoChef,
      Oferta: oferta,
      SaidaImpressao: saidaImpressao,
      Ingredientes: ingredientes || [],
      TabelasPreco: itemSpecification?.TabelasPreco || [],
      FichaTecnica: fichaTecnica || []
    }

    try {
      await axios({
        method: 'PUT',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${item.Codigo}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      SuccessAlert('Sucesso', `O Item ${NomePrato} foi editado.`)

      handleGetCardapioItems()
      close()
    } finally {
      setIsLoading(false)
    }
  }

  // function handleChangeIngredientes(obj: any) {
  //   setItemSpecification((prevState: any) => ({
  //     ...prevState,
  //     Ingredientes: prevState.Ingredientes
  //   }))
  // }

  function ReplaceIngredients(obj: any) {
    setIngredientes1((prevState: any) =>
      prevState.map((item: any) => (item.Codigo === obj.Codigo ? obj : item))
    )
  }

  function handleChangeTablePrice(
    value: string,
    sizeId: number,
    meanOfSaleName: string,
    tableId: number
  ) {
    setItemSpecification((prevState: any) => ({
      ...prevState,
      TabelasPreco: prevState.TabelasPreco.map((priceTable: any) =>
        priceTable.Id === tableId
          ? {
              ...priceTable,
              MeiosVenda: priceTable.MeiosVenda.map((meanOfSale: any) =>
                meanOfSale.Nome === meanOfSaleName
                  ? {
                      ...meanOfSale,
                      Tamanhos: meanOfSale.Tamanhos.map((size: any) =>
                        size.Id === sizeId
                          ? {
                              ...size,
                              Preco: Number(numberMask(value)) / 100
                            }
                          : size
                      )
                    }
                  : meanOfSale
              )
            }
          : priceTable
      )
    }))
  }

  function replaceFicha(obj: any, codTamanho: number, isEditOutros: boolean) {
    if (isEditOutros) {
      setFichaTecnica1((prev: any) =>
        prev?.map((ficha: any) =>
          ficha?.CodigoTamanho === codTamanho
            ? {
                ...ficha,
                OutrosComplementos: ficha.OutrosComplementos?.map(
                  (outros: any) => (outros.Codigo === obj.Codigo ? obj : outros)
                )
              }
            : ficha
        )
      )
    } else {
      setFichaTecnica1((prev: any) =>
        prev.map((ficha: any) =>
          ficha?.CodigoTamanho === codTamanho
            ? {
                ...ficha,
                OutrosComplementos: [...(ficha?.OutrosComplementos || []), obj]
              }
            : ficha
        )
      )
    }
  }

  function deleteFicha(obj: any, codTamanho: number) {
    if (!obj) return

    setFichaTecnica1((prev: any) =>
      prev.map((ficha: any) =>
        ficha?.CodigoTamanho === codTamanho
          ? {
              ...ficha,
              OutrosComplementos: ficha?.OutrosComplementos?.filter(
                (w: any) => w.Codigo !== obj.Codigo
              )
            }
          : ficha
      )
    )
  }

  function cloneSizes(origem: any, destino: any) {
    setFichaTecnica1((prev: any) =>
      prev?.map((_t: any) =>
        _t.CodigoTamanho === destino.CodigoTamanho
          ? {
              ...origem,
              CodigoTamanho: destino?.CodigoTamanho,
              NomeTamanho: destino?.NomeTamanho
            }
          : _t
      )
    )

    SuccessAlert(
      'Sucesso',
      `Tamanho Origem ${origem?.NomeTamanho} foi clonado para o tamanho ${destino?.NomeTamanho}.`
    )
  }

  function handleChangeMassa(data: any, massaId: number, listMassa: any[]) {
    if (!data || !massaId || !listMassa) return

    const massaSelected = listMassa.find(massa => massa.Codigo === massaId)

    setFichaTecnica1((prev: any) =>
      prev?.map((_m: any) =>
        _m.CodigoTamanho === data.CodigoTamanho
          ? {
              ...data,
              FamiliaTipoMassa: {
                CodigoFamilia: massaSelected?.Codigo,
                DescricaoFamilia: massaSelected?.Nome
              }
            }
          : _m
      )
    )
  }

  function handleChangeBorda(data: any, bordaId: number, listaBordas: any[]) {
    if (!data || !bordaId || !listaBordas) return

    const bordaSelected = listaBordas.find(borda => borda.Codigo === bordaId)

    setFichaTecnica1((prev: any) =>
      prev?.map((_b: any) =>
        _b.CodigoTamanho === data.CodigoTamanho
          ? {
              ...data,
              FamiliaBordas: {
                CodigoFamilia: bordaSelected.Codigo,
                DescricaoFamilia: bordaSelected.Nome
              }
            }
          : _b
      )
    )
  }

  function handleChangeAdicional(
    data: any,
    AdicionalId: number,
    listAdicional: any[]
  ) {
    if (!data || !AdicionalId || !listAdicional) return

    const adicionalSelected = listAdicional.find(
      adicional => adicional.Codigo === AdicionalId
    )

    setFichaTecnica1((prev: any) =>
      prev?.map((_a: any) =>
        _a.CodigoTamanho === data.CodigoTamanho
          ? {
              ...data,
              FamiliaAdicionais: {
                CodigoFamilia: adicionalSelected.Codigo,
                DescricaoFamilia: adicionalSelected.Nome
              }
            }
          : _a
      )
    )
  }

  const SideModal = document.getElementById('sidemodal')
  if (!SideModal) return null

  return ReactDOM.createPortal(
    <Overlay>
      <SideModalContainer className="thematic-scroll">
        <div className="sidebar-header">
          <h1>
            { (item && !title) ? (item.Codigo || item.CodigoCategoria) + ' - ' : ''}
            { title ? title : item.Nome || item.DescricaoCategoria }
          </h1>
          <button onClick={close}>
            <X color="#f3f4f8" size={30} />
          </button>
          <br />
        </div>
        {(addItem || editItem) && (
          <>
            <div className="addItem-tabs">
              {ItemTabs.map(tab => !tab.hidden && (
                <button
                  key={tab.id}
                  className={selectedTab === tab.tab ? 'activeTab' : ''}
                  onClick={() => handleChangeTab(tab.tab as any)}
                >
                  <li>{tab.name}</li>
                </button>
              ))}
            </div>
            <hr />
          </>
        )}

        <div className="sidebar-caption">
          <>
            {addCategory && <p>{caption}</p>}
            {addItem
              ? ItemTabs?.find(item => item.tab === selectedTab)?.caption
              : ''}
          </>
        </div>

        {addCategory && (
          <div className="addCategory-Container">
            <div>
              <label>Nome da Categoria</label>
              <input
                type="text"
                className="form-control"
                placeholder="Exemplo: Carros Chefe ou Promoção"
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
              />
              <p className="error">{getErrorByFieldname('categoryName')?.message}</p>
            </div>
            <div>
              <label> Sequência</label>
              <input
                min={0}
                type="number"
                className="form-control"
                value={categorySequence}
                onChange={e => setCategorySequence(parseInt(e.target.value))}
              />
              <p className="error">{getErrorByFieldname('categorySequence')?.message}</p>
            </div>
            <div className="switch">
              <label htmlFor="Promoção">Meio a Meio?</label>
              <Switch
                onChange={e => setMiddleToMiddle(e)}
                checked={middleToMiddle}
                offColor="#dc0000"
              />
            </div>
          </div>
        )}
        {/* aba Detail */}
        {(addItem || editItem) && selectedTab === 'detail' && (
          <Detail
            item={item}
            getError={getErrorByFieldname}
            addItem={addItem || false}
            fullObj={fullObj}
            codCategoria={codCategoria}
            setCodCategoria={setCodCategoria}
            NomePrato={NomePrato}
            setNomePrato={setNomePrato}
            saidaImpressao={saidaImpressao}
            setSaidaImpressao={setSaidaImpressao}
            oferta={oferta}
            setOferta={setOferta}
            sugestaoChef={sugestaoChef}
            setSugestaoChef={setSugestaoChef}
            descricaoProduto={descricaoProduto}
            setDescricaoProduto={setDescricaoProduto}
          />
        )}

        {/* Aba Ingredientes */}

        {(addItem || editItem) && selectedTab === 'ingredient' && (
          <Ingredient
            Ingredientes={ingredientes}
            setIngredientes1={setIngredientes1}
            ReplaceObj={ReplaceIngredients}
          />
        )}

        {/* Aba preço */}

        {(addItem || editItem) && selectedTab === 'price' && (
          <Price
            tables={itemSpecification}
            handleAddNewTable={handleAddNewTable}
            handleRemoveTable={handleRemoveTable}
            handleToggleStore={handleToggleStore}
            changeTablePrice={handleChangeTablePrice}
            isAddAction={addItem || false}
            setSelectedTableStoreList={setSelectedTableStoreList}
          />
        )}

        {/* Aba Ficha Tecnica */}
        {(addItem || editItem) && selectedTab === 'technicalFile' && (
          <TechnicalFile
            FichaTecnica={fichaTecnica}
            setFichaTecnica1={setFichaTecnica1}
            replaceFicha={replaceFicha}
            deleteFicha={deleteFicha}
            cloneSizesFunction={cloneSizes}
            handleChangeBorda={handleChangeBorda}
            handleChangeMassa={handleChangeMassa}
            handleChangeAdicional={handleChangeAdicional}
          />
        )}

        <div className="save-container">
          <button onClick={close}>Cancelar</button>

          {addCategory && !editCategory && (
            <button onClick={AddNewCategory}>Salvar</button>
          )}

          {addCategory && editCategory && (
            <button onClick={EditCategory}>Salvar Edição</button>
          )}

          {editItem && <button onClick={EditItem}>Salvar Item</button>}
          {addItem && <button onClick={AddItem}>Salvar Novo Item</button>}
        </div>
      </SideModalContainer>
      {isLoading && <ScaleLoader />}
    </Overlay>,
    SideModal
  )
}

export default SideModal
