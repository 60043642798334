import { ChevronsDown } from 'lucide-react'
import { useMemo, useState } from 'react'
import Select from 'react-select'
import {
  ErrorAlert,
  SuccessAlert
} from '../../../../../../components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/store'

type IngredientSelectProps = {
  ingredientList: any
  ingredientes: any
  // ingredientModalOpen: () => void
  setIngredientes: (value: any) => void
}
function IngredientSelect({
  ingredientList,
  ingredientes,
  // ingredientModalOpen,
  setIngredientes
}: IngredientSelectProps) {
  const { theme } = useSelector((state: RootState) => state.theme)

  const [ingredientToggled, setIngredientToggled] = useState<any>()

  function customTheme(theme: any) {
    return {
      ...theme,
      colors: {
        ...theme.colors
      }
    }
  }
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: theme === 'light' ? '#607EAA' : '#283046',
      borderColor: '#fff',
      textColor: '#fff'
    }),
    option: (styles: any, { isDisabled }: any): any => ({
      ...styles,
      backgroundColor: theme === 'dark' ? '#283046' : '#607EAA',
      color: '#fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      '&:hover': {
        ...styles,
        textColor: theme === 'light' ? '#000' : '#fff',
        color: '#000'
      }
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: '#fff'
    }),
    menu: (base: any) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: (base: any) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: '#fff'
    })
  }

  const selectOptions = useMemo(
    () =>
      ingredientList?.map((ingredient: any) => ({
        value: ingredient?.Codigo,
        label: ingredient?.Nome || ingredient?.Codigo
      })),
    [ingredientList]
  )

  const handleChangeIngredient = (event: any) => {
    if (event === null) {
      setIngredientToggled('')
    } else {
      const IngredienteObjSelected = ingredientList.find(
        (ingrediente: any) => ingrediente.Codigo === event.value
      )

      setIngredientToggled(IngredienteObjSelected)
    }
  }

  function IngredientListPush() {
    if (
      ingredientToggled === undefined ||
      Object.keys(ingredientToggled).length === 0
    ) {
      return ErrorAlert(
        'Atenção',
        'Você precisa Selecionar um item para adicionar a lista '
      )
    } else if (
      ingredientes?.some(
        (ingrediente: any) => ingrediente.Codigo === ingredientToggled.Codigo
      )
    ) {
      ErrorAlert(
        'Atenção',
        ` A listagem já contém o Item ${ingredientToggled.Nome}`
      )
    } else {
      setIngredientes((prevState: any) => [...prevState, ingredientToggled])
    }
  }

  return (
    <div className="ingredient-select">
      <Select
        className="form-control"
        options={selectOptions}
        onChange={handleChangeIngredient}
        placeholder="Selecione um ingrediente..."
        noOptionsMessage={() => 'Nenhum Ingrediente Encontrado'}
        theme={customTheme}
        // inputValue={''}
        // value={ingredientToggled}
        styles={customStyles}
        autoFocus
        isClearable
      />

      <button onClick={IngredientListPush}>
        <ChevronsDown color="#f3f4f8" />
      </button>

      {/* <button onClick={ingredientModalOpen}>
        <Plus color="#f3f4f8" />
      </button> */}
    </div>
  )
}

export default IngredientSelect
