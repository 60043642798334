import { useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import ScaleLoader from '../../../../components/ScaleLoader/ScaleLoader'
import GraphicCard from './components/GraphicCard'
import { GraphicsContainer } from './styles'

type GraphicsProps = {
  initialDateValue: string
  finalDateValue: string
  storeCode: number
  storeLabel: string
}

export function Graphics({
  initialDateValue,
  finalDateValue,
  storeCode,
  storeLabel
}: GraphicsProps) {
  const [loadingCounter, setLoadingCounter] = useState<number>(0)

  const graphicsObject = [
    {
      title: 'Ticket Médio por Canal',
      el: Bar,
      type: 2,
      valueProperty: 'VALOR_TICKET_MEDIO',
      labelProperty: 'CANAL'
    },
    {
      title: 'Vendas por Canal',
      el: Bar,
      type: 4,
      valueProperty: 'TOTAL',
      labelProperty: 'CANAL'
    },
    {
      title: 'Ticket Médio por Meio de Venda',
      el: Bar,
      type: 3,
      valueProperty: 'VALOR_TICKET_MEDIO',
      labelProperty: 'MEIO_VENDA'
    },

    {
      title: 'Vendas por Meio de Venda',
      el: Bar,
      type: 5,
      valueProperty: 'TOTAL',
      labelProperty: 'MEIO_VENDA'
    },
    {
      title: 'Ticket Médio',
      el: Line,
      type: 1,
      valueProperty: 'VALOR_TICKET_MEDIO',
      labelProperty: ''
    }
  ]

  return (
    <GraphicsContainer>
      {graphicsObject.map(graphicObject => (
        <GraphicCard
          key={graphicObject.type}
          title={graphicObject.title}
          Element={graphicObject.el}
          type={graphicObject.type}
          initialDateValue={initialDateValue}
          finalDateValue={finalDateValue}
          plusLoadingCounter={() =>
            setLoadingCounter(prevState => prevState + 1)
          }
          minusLoadingCounter={() =>
            setLoadingCounter(prevState => prevState - 1)
          }
          valueProperty={graphicObject.valueProperty}
          labelProperty={graphicObject.labelProperty}
          storeCode={storeCode}
          storeLabel={storeLabel}
        />
      ))}
      {loadingCounter > 0 && <ScaleLoader />}
    </GraphicsContainer>
  )
}
