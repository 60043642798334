import DateSelector from '../../components/DateSelector'
import PageCard from '../../components/PageCard'
import Table, { rowHeaderItem } from '../../components/Table'
import { SearchContainer } from '../Search/styles'
import { Search as SearchIcon } from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useCallback, useState } from 'react'
import useDateInterval from '../../hooks/useDateInterval'
import axios from 'axios'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import { IfoodIntegratorContainer } from './styles'
import Order from '../../components/Order'
import ifoodIntegratorData from './Filters/data/IfoodIntegratorData'

const tableHeadItems: string[] = [
  'ID',
  'Data Pedido',
  'Loja',
  'Pedido Ifood',
  'Cliente',
  'Ped Dia',
  'Ped Tractio',
  'Meio Venda',
  'Log',
  'Status',
  'Ocorrência',
  'Usuário',
  'Data/Hora'
]

const tableRowHeaders: rowHeaderItem[] = [
  { value: 'ID' },
  { value: 'DataHoraPedido', format: 'date&hour' },
  { value: 'Loja' },
  { value: 'PedidoIfood' },
  { value: 'Cliente' },
  { value: 'PedidoDiaTractio' },
  { value: 'PedidoSeqTractio' },
  { value: 'MeioVenda' },
  { value: 'guidLog' },
  { value: 'StatusAprovacao' },
  { value: 'DesStatusAprovacao' },
  { value: 'Usuario' },
  { value: 'DataHoraIteracao', format: 'date&hour' }
]

function IfoodIntegrator() {
  const [modalInfo, setModalInfo] = useState<string>('')
  const [currentModalInfoCode, setCurrentModalInfoCode] = useState<number>(0)
  const [pedidoIfood, setPedidoIfood] = useState<string>('')
  const [dropdownFilter, setdropdownFilter] = useState<string>('TODOS')
  const [costumerName, setCostumerName] = useState<string>('')
  const { selectedEnvironment, userToken, licenseStoreCode } = useSelector(
    (state: RootState) => state.auth
  )

  const [
    initialDateValue,
    setInitialDateValue,
    finalDateValue,
    setFinalDateValue
  ] = useDateInterval()
  const [data, setData] = useState<[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataFetch, setDataFetch] = useState<boolean>(false)
  const handleSearch = useCallback(async () => {
    setIsLoading(true)
    const env = process.env.REACT_APP_QUERY_IFOOD_INTEGRATOR

    if (!userToken || !env || !selectedEnvironment.Url || !licenseStoreCode)
      return

    try {
      const searchData = {
        DataInicial: initialDateValue,
        DataFinal: finalDateValue,
        Tipo: dropdownFilter,
        NomeCliente: costumerName,
        CodigoPedidoIfood: pedidoIfood
      }

      const response = await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        data: searchData,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setData(response.data)
      setIsLoading(false)
      setDataFetch(true)
    } catch (err: any) {
      setData([])
      setIsLoading(false)
    }
  }, [
    userToken,
    licenseStoreCode,
    selectedEnvironment.Url,
    finalDateValue,
    initialDateValue,
    dropdownFilter,
    costumerName,
    pedidoIfood
  ])

  async function gatherModalInfo(value: string) {
    const env = process.env.REACT_APP_QUERY_IFOOD_INTEGRATOR_TXT

    if (!env || !userToken || !selectedEnvironment.Url) return

    setCurrentModalInfoCode(Number(value))
    setIsLoading(true)

    const response = await axios({
      method: 'GET',
      url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${value}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    setIsLoading(false)

    setModalInfo(response.data)
  }

  function handleOpenModal(value: string) {
    gatherModalInfo(value)
  }

  function handleCloseModal() {
    setModalInfo('')
    setCurrentModalInfoCode(0)
  }
  return (
    <PageCard title="Status Integração Ifood">
      <IfoodIntegratorContainer>
        <DateSelector
          initialDateValue={initialDateValue}
          setInitialDateValue={setInitialDateValue}
          finalDateValue={finalDateValue}
          setFinalDateValue={setFinalDateValue}
        >
          <>
            <button
              type="button"
              className="search-button"
              onClick={handleSearch}
            >
              <SearchIcon color="#f3f4f8" />
            </button>
          </>
        </DateSelector>
        <div className="query-container">
          <div>
            <label>Tipo de Consulta</label>
            <select
              className="form-control"
              onChange={e => setdropdownFilter(e.target.value)}
            >
              <>
                {ifoodIntegratorData[0]?.filters?.map(value => (
                  <option key={value.smug} value={value.smug}>
                    {value.title}
                  </option>
                ))}
              </>
            </select>
          </div>
          <div>
            <label>Cliente</label>
            <input
              type="text"
              className="form-control"
              onChange={e => setCostumerName(e.target.value)}
            />
          </div>
          <div>
            <label>Pedido Ifood</label>
            <input
              type="text"
              className="form-control"
              onChange={e => setPedidoIfood(e.target.value)}
            />
          </div>
        </div>

        <SearchContainer>
          {data.length > 0 && (
            <div className="table-wrapper">
              <Table
                items={data}
                headItems={tableHeadItems}
                rowHeaders={tableRowHeaders}
                noIndex={true}
                noCanal={true}
                infoProperty="ID"
                action={handleOpenModal}
                currentModalInfoCode={currentModalInfoCode}
                personalizationAllowed
              />
            </div>
          )}
          {data.length === 0 && dataFetch === true ? (
            <div className="warning">
              <p>Nada encontrado no periodo especificado</p>
            </div>
          ) : null}
        </SearchContainer>
        {/* {message && isError && (
          <OverlayNotification text={message} close={close} />
        )} */}
        {isLoading && <ScaleLoader />}
      </IfoodIntegratorContainer>

      {modalInfo && (
        <Order ifoodIcon close={handleCloseModal} actionButtons>
          {modalInfo}
        </Order>
      )}
    </PageCard>
  )
}

export default IfoodIntegrator
