import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SubModal from '../../../components/Order/components/SubOrderModal'
import OverlayNotification from '../../../components/OverlayNotification'
import ScaleLoader from '../../../components/ScaleLoader/ScaleLoader'
import { RootState } from '../../../store/store'
import ifood from '../../../assets/images/svg/ifood-43.svg'
import ReactJsonPrint from 'react-json-print'
import {
  ErrorAlert,
  SuccessAlert
} from '../../../components/SweetAlert/SweetAlert'

type IFoodModalProps = {
  close: () => void
  value: string
  update: () => void
}

function IFoodModal({ close, value, update }: IFoodModalProps) {
  const { licenseStoreCode, selectedEnvironment, userToken } = useSelector(
    (state: RootState) => state.auth
  )
  const [message, setMessage] = useState<any>({})

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleGatherIfoodInfo = useCallback(async () => {
    const env = process.env.REACT_APP_STORE_REQUEST_IFOOD

    if (!env || !licenseStoreCode || !selectedEnvironment?.Url) return

    try {
      setIsLoading(true)

      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${value}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      setMessage(response.data)
    } catch (err: any) {
      setIsLoading(false)
      setIsError(true)
      setMessage('Não foi possível obter os dados desse pedido no momento.')
    }
  }, [licenseStoreCode, selectedEnvironment?.Url, userToken, value])

  async function handleApproveIfoodOrder() {
    const env = process.env.REACT_APP_APPROVE_ORDER_IFOOD

    if (!env || !licenseStoreCode || !selectedEnvironment?.Url) return

    try {
      setIsLoading(true)

      await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${value}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      setIsSuccess(true)
      // setMessage('Pedido aprovado com sucesso')

      SuccessAlert('Sucesso', 'Pedido aprovado com sucesso')
      //Setados manualmente//
      handleCloseModal()
      update()
    } catch (err: any) {
      const message =
        err?.response?.data?.Messages?.[0] ||
        'Não foi possível confirmar o pedido'

      setIsLoading(false)
      setIsError(true)
      ErrorAlert('Atenção', message)
      // setMessage(message)
    }
  }

  function handleCloseModal() {
    if (isSuccess) {
      update()
    }

    close()
  }

  useEffect(() => {
    handleGatherIfoodInfo()
  }, [handleGatherIfoodInfo])

  return (
    <>
      {message && !isError && !isSuccess && (
        <SubModal
          title="Visualização do pedido"
          confirm={handleApproveIfoodOrder}
          close={close}
          svg={ifood}
          ApprovebuttonTitle={'Aprovar'}
        >
          <div className="json-display-container">
            <ReactJsonPrint expanded dataObject={message} />
          </div>
        </SubModal>
      )}

      {message && (isError || isSuccess) && (
        <OverlayNotification text={message} close={handleCloseModal} />
      )}

      {isLoading && <ScaleLoader />}
    </>
  )
}

export default IFoodModal
