import styled from "styled-components";

export const ProfileModalContainer = styled.div`
width: 60%;
background: ${({ theme }) => theme.colors.dark.main};
height: 100%;
z-index: 9000;
position: absolute;
right: 0;
overflow: auto;
.tabs{
  display: flex;
  padding: 20px;
  list-style: none;
  gap: 1rem;
  width: 100%;
  justify-content: space-evenly;

  > button{
    color: ${({ theme }) => theme.colors.dark.cardText};
    font-size: 16px;
    margin-left: auto;
  }

  > div{
    margin-left: auto;
  }

  > .activeTab{
    border-bottom:2px solid #6F38C5;
  }
}
.container {
  position: relative;
  width: 100%;
  height: 315px;
  margin: auto;

 
  > .add-coverphoto{
   padding: 8px;
   position: absolute;
   color:  ${({ theme }) => theme.colors.dark.cardText};
   background-color: #333;
   right: 0;
   margin-top: 1rem;
   margin-right: 1rem;
   border-radius: 0.8rem;
   display: flex;
   justify-content: center;
   align-items: center;
   > input{ display:none};
   > label{
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
   }
   > svg{
    margin-right: 1rem;
   }


  }
  
  > .profile-photo-container{
   
    transition: 0.5s;
    > .logo{
      
    display: flex;
    position: absolute;
    bottom: -100px;
    width: 200px;
    height: 200px;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    border: 1px solid #333;
    object-fit: contain;
    background-size: 95%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100px;
  }

  &:hover{
    transition: 0.5s;
    opacity: 0.6;
    > .add-photo{
      display: flex;
      
    }
  }

  > .add-photo{
    color: #fff;
    width: 130px;
    
    left:0;
    right:0; 
    margin-left:auto;
    margin-right:auto;
    position: absolute;
    background-color: #333;
    border-radius: 0.8rem;
    padding: 10px;
    display: none;

    > label{
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;
   }
    > input {
      display: none;
    }
  }
  }
 

  .cover-photo{
    > img{
      width: 100%;
      height: 315px;   
    }

 
  }
}

.form-container{
  display: block;
  flex-direction: column;
  margin-top: 10rem;
  padding: 20px;
  color:  ${({ theme }) => theme.colors.dark.cardText};
  
  > .formrow{
    gap: 2rem;
    display: flex;
   flex-direction: row;
     >div{
      input[type=time]{
          font-size: 14px;

          &::-webkit-calendar-picker-indicator{
            filter: invert(100%)
          }
        }
     }  
     #Codigo{
      width: 30%;
     }
     #Sigla{
      width: 30%;
     }
     #UF{
      width: 40%;
     }
     #Numero{
      width: 20%;
     }
     #Telefone{
      width:40%;
     }
     #Bairro{
      width:50%;
     }
   > .switch{
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 1rem;
   }
   > div{
    width: 100%;
    > label{
      font-size: 12px;
    }
   }
  }


}
.adicional-info-container{
    padding: 20px;
    color:  ${({ theme }) => theme.colors.dark.cardText};
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    > div{
      display: block;
      width: 100%;
     
      > label{
        font-size: 12px;
      }
    }
  }
@media(max-width: 900px) {
  width: 100%;
}

.workingDays-container{
  margin-top: 5rem;
  margin-bottom: 8rem;
  width: 100%;
  display: flex;
  padding: 20px;
  max-height: 100vh;
 
  > table{
    width: 100%;
    border-collapse: collapse;
    color:  ${({ theme }) => theme.colors.dark.cardText};
    
    >thead{
      > tr{
        width: 100%;
        display: flex;
        align-items: center;
        background-color: #6F38C5;
        > td{
          text-align: center;
          padding: 10px;
          font-size: 12px;
          border: 1px solid #fff;
        }
        #day{
          width: 20%;
          
        }
        #store{
          width: 40%;
        }
        #app{
          width: 40%;
        }
        
      }
    }

    >tbody{
      
      > tr{
        width: 100%;
        display: flex;
        align-items: center;

        #day{
          width: 20%;

        
         
        }
        #store{
          width: 40%;
          
        }
        #app{
          width: 40%;
        }
        > td{
          width: 100%;
          text-align: center;
          padding: 5px;
          font-size: 12px;
          border: 1px solid #fff;
          height: 80px;
          justify-content: center;

          > .day-checkbox{
            height: 100%;
            display: flex;
            
            justify-content: center;
          }
          > div{
            gap: 1rem;
            width: 100%;
            align-items: center;
            display: flex;
            > div{
              display: block;
              width: 100%;
              > input[type=time]{
              font-size: 14px;
              width: 100%;

          &::-webkit-calendar-picker-indicator{
            filter: invert(100%)
          }
          }
            }
          
          }
        }
      }
    }
  }
 
}

.save-container{
    
    flex-direction: row;
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    width: 100%;
    background: #383f54;
    height: 80px;
    justify-content: end;

    > button{
      margin-top: 1.5rem;
      color: #fff;
      margin-right: 1rem;
      border: 1px solid #fff;
      padding: 16px 24px;
      height: 48px;
      font-size: 18px;
      border-radius: 1rem;
     
      &:first-child{
        background: transparent;
        &:hover{
          opacity: 0.7;
        }
      }

      &:last-child{
        background: #EB1D36;
        &:hover{
          opacity: 0.7;
        }
      }

    }
  }
`