import { ChevronUp } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  clearAuth,
  disconnect,
  reset
} from '../../../../../store/features/auth/authSlice'
import { AppDispatch } from '../../../../../store/store'
import { SidebarItem as SidebarItemType } from '../data/sidebarData'
import { SidebarListItem } from '../styles'

type SidebarItemProps = {
  item: SidebarItemType
  closeSidebar: () => void
}

function SidebarItem({ item, closeSidebar }: SidebarItemProps) {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState<boolean>(false)
  const [isSubNavItemActive, setIsSubNavItemActive] = useState<boolean>(false)

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()

  function handleItemClick() {
    if (item.title === 'Sair') {
      dispatch(disconnect())
      dispatch(reset())
      dispatch(clearAuth())
      closeSidebar()
      navigate('/auth')
      return
    }

    if (item.path) {
      navigate(item.path)
      closeSidebar()
      return
    }

    if (item.subNav && item.subNav.length > 0 && !isSubNavItemActive) {
      setDropdownMenuOpen(prevState => !prevState)
      return
    }
  }

  useEffect(() => {
    const path = location.pathname

    const activeSubNav = item.subNav?.find((item: any) => item.path === path)

    if (activeSubNav) {
      setIsSubNavItemActive(true)
      setDropdownMenuOpen(true)
    } else {
      setIsSubNavItemActive(false)
      setDropdownMenuOpen(false)
    }
  }, [location, item.subNav])

  const dropdownMenuSize = item.subNav?.reduce((prev: any) => prev + 6.8, 0)

  return (
    <>
      <SidebarListItem
        className={`
      ${location.pathname === item.path ? 'active card-box-shadow' : undefined}
      ${dropdownMenuOpen ? 'dropdown-menu-open' : undefined}
       ${item?.isSub ? 'isSub' : undefined}`}
        dropdownMenuSize={dropdownMenuSize || 0}
      >
        <button type="button" onClick={handleItemClick}>
          <div>
            <item.icon />
            <p>{item.title}</p>
          </div>
          {item?.subNav && item.subNav.length > 0 && <ChevronUp />}
        </button>
        <ul className="dropdown">
          {item.subNav?.map(item => (
            <SidebarItem
              key={item.id}
              item={item}
              closeSidebar={closeSidebar}
            />
          ))}
        </ul>
      </SidebarListItem>
    </>
  )
}
export default SidebarItem
