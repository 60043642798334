import styled from 'styled-components'

export const PageCardContainer = styled.main`
  background: ${({ theme }) => theme.colors.primary.normal};
  border-radius: 1.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
  width: 100%;
  overflow: auto;
  color: ${({ theme }) => theme.colors.dark.text};
  border: 1px solid ${({ theme }) => theme.colors.card.border}; 
  svg{
    color: ${({ theme }) => theme.colors.dark.text};
  }

  header {
    position: relative;
    margin-bottom: .4rem;

    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2rem;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 1.6rem;
  }

  h2{
    text-align: center;
  }
`