import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  theme: 'dark' as 'dark' | 'light'
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      const newTheme = state.theme === 'dark' ? 'light' : 'dark'

      state.theme = newTheme
      localStorage.setItem('preferredTheme', newTheme)
    },

    setDefinedTheme: (state, action: PayloadAction<'dark' | 'light'>) => {
      state.theme = action.payload
    }
  }
})

export default themeSlice.reducer
export const { toggleTheme, setDefinedTheme } = themeSlice.actions