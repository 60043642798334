import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  position: relative;

  margin-top: 2rem;

  .extra-tr{
    padding: 20px;
    border-radius: 0.7rem;
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
    
    > p{
      box-shadow: 0px 1px 10px #000;
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      background-color: #6F38C5;
      font-size: 16px;
      text-align: center;
      
    }
    }
  table {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    background: ${({ theme }) => theme.colors.dark.main};
    color: #f3f4f8;
    width: 100%;
    text-align: center;
    border: 0;
    border-collapse: collapse;

    th, td{
      text-align: center;
      padding: 0.5rem;
      border: 1px solid;
    }

    thead tr {
      cursor: default;

      &:hover {
        background-color: initial;
      }
      
    }

    thead{
      > .extra-tr{
      
        font-size: 14px;
        width: 100%;
        background: #6F38C5;
        padding: 10px;

        &:hover{
          background-color: #6F38C5;
        }
      }
    }
    
    
    .totalValues{
      background-color: #325288;
      height: 40px;
    }
 
    tr {
      cursor: pointer;

      &.active {
        background-color: #554994;
      }

      &:hover {
        background-color: #554994;  
      }
    }

    
    .hasUser{
      background: #BD4B4B
    }
    
  }
`