export type Filter = typeof filtersData[0]

const filtersData = [
  {
    label: 'Tipo',
    id: 3,
    type: 'select',
    area: "tipo",
    filters: [
      {
        title: 'Todos',
        smug: 'TODOS'
      }, {
        title: 'Aprovados automático',
        smug: 'AUTOMATICO'
      }, {
        title: 'Não aprovados automático',
        smug: 'MANUAL'
      }
    ]
  },
  {
    label: 'Cliente',
    id: 2,
    type: 'text',
    area: "cliente",
    filters: []
  },

  {
    label: 'Status',
    id: 1,
    type: 'select',
    area: 'status',
    filters: [
      {
        title: 'Todos',
        smug: 'TODOS'
      }, {
        title: 'Rejeitados',
        smug: 'REJEITADOS'
      }, {
        title: 'Aprovados',
        smug: 'APROVADOS'
      }, {
        title: 'Aguardando aprovação',
        smug: 'AGUARDANDO'
      }
    ]
  }, {
    label: 'Pagamento',
    id: 4,
    type: 'select',
    area: 'pagamento',
    filters: [
      {
        title: 'Todos',
        smug: 'TODOS'
      }, {
        title: 'Online',
        smug: 'ONLINE'
      }, {
        title: 'Offline',
        smug: 'OFFLINE'
      }
    ]
  },
  {
    label: 'Origem',
    id: 5,
    type: 'checkbox',
    area: 'origem',
    filters: [
      {
        title: 'Agile',
        smug: 'agile'
      }, {
        title: 'Cardápio digital',
        smug: 'cardapio'
      },
      // {
      //   title: 'Ecommerce',
      //   smug: 'ecommerce',
      // }, {
      //   title: 'APP venda',
      //   smug: 'app'
      // }, {
      //   title: 'iFood',
      //   smug: 'ifood'
      // },
      /*
       {
        title: 'PedZap',
        smug: 'pedzap'
      }, 
    */

    ]
  }
]

export default filtersData