/* eslint-disable react-hooks/exhaustive-deps */
import PageCard from '../../components/PageCard'
import { IntegratorContainer } from './styles'
import { IntegratorCard } from './components/integratorCard'
import Table, { rowHeaderItem } from '../../components/Table'
import integratorCardData, {
  IntegratorCardType
} from './components/integratorCard/data/integratorCardData'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import OverlayNotification from '../../components/OverlayNotification'
import { Reason, RequestItem } from '../../types/integrator'
import getFilteredRequests from './components/utils/getFilteredRequests'
import ApproveModal from './components/ApproveModal'
import RejectModal from './components/RejectModal'
import Switch from 'react-switch'

import notifySound from '../../assets/audio/notify_1.wav'
import Order from '../../components/Order'
import IFoodModal from './components/IFoodModal'
import PedZapModal from './components/PedZapModal'
import {
  ErrorAlert,
  SuccessAlert
} from '../../components/SweetAlert/SweetAlert'
import { FilterIntegrator } from './components/FilterContainer/filter'
import NoItems from '../../components/NoItems'

var song = new Audio(notifySound)

function Integrator() {
  const { selectedEnvironment, userToken, licenseStoreCode } = useSelector(
    (state: RootState) => state.auth
  )

  const [integratorRequest, setIntegratorRequest] = useState<any>()
  const [activeFilters, setActiveFilters] = useState<string[]>([
    integratorCardData[0].dbkey
  ])

  const [activeCode, setActiveCode] = useState<string>('')

  const [checked, setChecked] = useState<boolean>(true)

  const [isApproveModalInfoOpen, setIsApproveModalInfoOpen] =
    useState<boolean>(false)
  const [isRejectModalInfoOpen, setIsRejectModalInfoOpen] =
    useState<boolean>(false)

  const [approveResponse, setApproveResponse] = useState<string>('')
  const [rejectResponse, setRejectResponse] = useState<any>('')

  const [currentTime, setCurrentTime] = useState<string>('')

  const [rejectReasons, setRejectReasons] = useState<Reason[]>([])
  const [selectedRejectReason, setSelectedRejectReason] = useState<Reason>()
  const [filteredData, setFilteredData] = useState<RequestItem[]>([])

  const [actionSuccessful, setActionSuccessful] = useState<boolean>(false)

  const [modalInfo, setModalInfo] = useState<string>('')
  const [currentModalInfoCode, setCurrentModalInfoCode] = useState<number>(0)

  const [ifoodModal, setIfoodModal] = useState<string>('')
  const [pedzapModal, setPedzapModal] = useState<string>('')

  const [currentRequestItem, setCurrentRequestItem] = useState<RequestItem>()

  const firstRequest = useRef(true)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [orderFilterString, setOrderFilterString] = useState<string>('')

  const handleGetIntegratorReport = useCallback(
    async (callback?: () => void) => {
      const currentSeconds = String(new Date().getSeconds()).padStart(2, '0')
      const currentMinutes = String(new Date().getMinutes()).padStart(2, '0')
      const currentHour = String(new Date().getHours()).padStart(2, '0')

      const currentDate = `${new Date().toLocaleDateString()} às ${currentHour}:${currentMinutes}:${currentSeconds}`

      setCurrentTime(currentDate)

      const env = process.env.REACT_APP_PENDING_REQUEST

      if (!env || !userToken || !selectedEnvironment.Url) return

      setIsLoading(true)
      const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

      try {
        const response = await axios({
          method: 'GET',
          url,
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })

        setIntegratorRequest(response.data)
        setFilteredData(response.data?.Pedidos)

        setIsLoading(false)

        if (!firstRequest.current && response.data?.Pedidos?.length > 0) {
          song.play()
        }
      } catch (err: any) {
        setIsLoading(false)
      } finally {
        firstRequest.current = false
      }
    },
    []
  )

  async function getDeclineReasons() {
    setIsLoading(true)
    const env = process.env.REACT_APP_REASON

    if (!env || !selectedEnvironment) return

    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

    const response = await axios({
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    setRejectReasons(response.data)
    setIsLoading(false)
  }

  function toggleActiveFilters(filter: string) {
    const currentFilter = integratorCardData.find(card => card.dbkey === filter)

    if (!currentFilter) return

    setActiveFilters(prevState => {
      const filterExist = prevState.find(
        activeFilter => activeFilter === filter
      )

      if (filterExist) {
        return prevState.filter(activeFilter => activeFilter !== filter)
      } else {
        return [...prevState, filter]
      }
    })
  }

  useEffect(() => {
    const time = 30000
    const interval = setInterval(handleGetIntegratorReport, time)

    if (!checked) {
      clearInterval(interval)
      return
    }

    return () => clearInterval(interval)
  }, [checked])

  useEffect(() => {
    handleGetIntegratorReport()
  }, [handleGetIntegratorReport])

  useEffect(() => {
    if (activeFilters.length > 0 && integratorRequest?.Pedidos.length > 0) {
      const filteredValues = getFilteredRequests(
        integratorRequest.Pedidos,
        activeFilters
      )
      setFilteredData(filteredValues)
      handleApplyStringBasedFilter(orderFilterString)
      return
    }

    setFilteredData(integratorRequest?.Pedidos)
    handleApplyStringBasedFilter(orderFilterString)
  }, [activeFilters, integratorRequest?.Pedidos])

  useEffect(() => {
    if (
      orderFilterString.length === 0 &&
      integratorRequest?.Pedidos.length > 0
    ) {
      const filteredValues = getFilteredRequests(
        integratorRequest?.Pedidos,
        activeFilters
      )
      setFilteredData(filteredValues)
      return
    }

    handleApplyStringBasedFilter(orderFilterString)
  }, [orderFilterString])

  const tableHeadItems: string[] = [
    'Canal',
    'Pedido SEQ',
    'Sigla',
    'Nome Cliente',
    'Bairro',
    'Endereço',
    'N°',
    'Hora',
    'Valor Troco',
    'NF',
    'Valor',
    'Agendamento',
    'Meio Venda',
    'Espera (Min)',
    'Log',
    'Telefone',
    'Contatar Cliente'
  ]

  const tableRowHeaders: rowHeaderItem[] = [
    { value: 'VisualizacaoAtiva', format: 'img' },
    { value: 'siglaEmpresa' },

    {
      value: 'NomeCliente'
    },
    {
      value: 'Bairro'
    },
    {
      value: 'Endereco'
    },
    {
      value: 'NumeroEndereco'
    },
    {
      value: 'Hora'
    },
    {
      value: 'ValorTroco',
      format: 'currency'
    },
    {
      value: 'DesejaNFP',
      format: 'nf'
    },
    {
      value: 'ValorFechamento',
      format: 'currency'
    },
    {
      value: 'DH_Agendamento',
      format: 'date'
    },
    {
      value: 'MeioVendaDesc'
    },
    {
      value: 'TempoEspera'
    },
    {
      value: 'LogAprovadorAuto'
    },
    { sub: 'DDD', value: 'TELEFONE', format: 'tel' },
    { sub: `DDD`, value: 'TELEFONE', format: 'whats' }
  ]

  function handleApplyStringBasedFilter(stringFilter: string) {
    if (!integratorRequest || integratorRequest?.Pedidos.length === 0) return

    setFilteredData(
      getFilteredRequests(integratorRequest.Pedidos, activeFilters).filter(
        (reqItem: RequestItem) =>
          Object.values(reqItem).some(val =>
            String(val).toLocaleLowerCase().includes(stringFilter)
          )
      )
    )
  }

  function getItemType(value: string) {
    if (integratorRequest?.Pedidos?.length < 1) return

    const currentItem: RequestItem = integratorRequest?.Pedidos.find(
      (request: RequestItem) => request.PedidoSEQ === Number(value)
    )

    if (!currentItem) return

    setCurrentRequestItem(currentItem)

    if (currentItem.EhIFoodComErros) return 'ifood'
    if (currentItem.EhPedZapComErros) return 'pedzap'

    return 'normal'
  }

  function handleOpenModal(value: string) {
    const itemType = getItemType(value)

    switch (itemType) {
      case 'normal':
        gatherModalInfo(value)
        break

      case 'ifood':
        setIfoodModal(value)
        break

      case 'pedzap':
        setPedzapModal(value)
        break

      default:
        return
    }
  }

  async function gatherModalInfo(value: string) {
    const env = process.env.REACT_APP_ORDER_RECEIPT

    if (!env || !userToken || !selectedEnvironment.Url) return

    setCurrentModalInfoCode(Number(value))
    setIsLoading(true)

    const response = await axios({
      method: 'GET',
      url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${value}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    setIsLoading(false)

    setModalInfo(response.data)
  }

  function handleCloseModal() {
    setModalInfo('')
    setCurrentModalInfoCode(0)
  }

  function handleOpenApproveModalInfo(itemCode: string) {
    setIsApproveModalInfoOpen(true)
    setActiveCode(itemCode)
  }

  function handleOpenRejectModalInfo(itemCode: string) {
    setIsRejectModalInfoOpen(true)
    setActiveCode(itemCode)

    if (rejectReasons.length === 0) {
      getDeclineReasons()
    }
  }

  async function handleApproveOrder(timestamp: string) {
    const env = process.env.REACT_APP_APPROVE_ORDER

    if (!env || !selectedEnvironment.Url || !activeCode || !userToken) return

    setIsLoading(true)

    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${activeCode}`

    const approveData = {
      TempoEstimado: timestamp
    }

    try {
      const response = await axios({
        method: 'POST',
        url,
        data: approveData,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      const message = response.data?.Mensagem || 'Pedido aprovado com successo.'

      setIsLoading(false)
      // setApproveResponse(message)
      SuccessAlert('Sucesso !', message, handleGetIntegratorReport)
      handleCloseApproveResponseModal()
      setActionSuccessful(true)
    } catch (err: any) {
      const message =
        err?.response?.data?.Messages?.[0] ||
        'Não foi possível aprovar o pedido.'

      setIsLoading(false)
      // setApproveResponse(message)
      ErrorAlert('Atenção !', message)
    }
  }

  async function handleRejectOrder() {
    const env = process.env.REACT_APP_REJECT_ORDER

    if (
      !env ||
      !selectedEnvironment.Url ||
      !activeCode ||
      !userToken ||
      !selectedRejectReason?.Descricao
    )
      return

    setIsLoading(true)

    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${activeCode}`

    const rejectData = {
      MotivoRejeicao: selectedRejectReason.Descricao
    }

    try {
      await axios({
        method: 'POST',
        url,
        data: rejectData,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setIsLoading(false)
      SuccessAlert('Sucesso !', 'Pedido rejeitado com sucesso.')
      handleCloseRejectResponseModal()
      // setRejectResponse('Pedido rejeitado com sucesso.')
      setActionSuccessful(true)
      //Função adicionada para atualizar os dados após mudança
      handleGetIntegratorReport()
    } catch (err: any) {
      const message =
        err?.response?.data?.Messages?.[0] ||
        'Não foi possível rejeitar o pedido.'

      setIsLoading(false)
      // setRejectResponse(message)
      ErrorAlert('Atenção !', message)
    }
  }

  function handleCloseApproveResponseModal() {
    setApproveResponse('')
    setModalInfo('')
    setIsApproveModalInfoOpen(false)

    if (actionSuccessful) {
      handleGetIntegratorReport()
      setActionSuccessful(false)
    }
  }

  function handleCloseRejectResponseModal() {
    setRejectResponse('')
    setModalInfo('')
    setIsRejectModalInfoOpen(false)

    if (actionSuccessful) {
      handleGetIntegratorReport()
      setActionSuccessful(false)
    }
  }

  return (
    <PageCard
      title="Integrador Web"
      button
      buttonAction={handleGetIntegratorReport}
    >
      <IntegratorContainer>
        <div className="cards-wrapper">
          {integratorCardData.map((card: IntegratorCardType) => (
            <IntegratorCard
              toggleFilter={toggleActiveFilters}
              title={card.title}
              key={card.dbkey}
              actionKey={card.dbkey}
              counter={integratorRequest?.[card.dbkey]}
              active={card.defaultActive}
            />
          ))}
        </div>
        <FilterIntegrator
          orderKey={orderFilterString}
          setOrderKey={setOrderFilterString}
        />

        {filteredData && filteredData.length > 0 ? (
          <>
            <Table
              items={filteredData}
              headItems={tableHeadItems}
              rowHeaders={tableRowHeaders}
              action={handleOpenModal}
              infoProperty="PedidoSEQ"
              currentModalInfoCode={currentModalInfoCode}
              noIndex={false}
              noCanal={false}
            />
            <div className="activation-container">
              {checked === true ? (
                <p>
                  Ultima verificação: {currentTime} -{' '}
                  {integratorRequest?.Pedidos?.length > 0
                    ? 'Existem pedidos pendentes'
                    : 'Não existem pedidos pendentes'}{' '}
                  - Qtde Recebidos: {integratorRequest?.Pedidos?.length || 0}
                </p>
              ) : (
                <p>Atualização automática desativada</p>
              )}

              <div>
                <Switch
                  onChange={setChecked}
                  checked={checked}
                  offColor="#dc0000"
                />
                <label id="integracao">Atualização automática</label>
              </div>
            </div>
          </>
        ) : (
          <NoItems title={'Nenhum Pedido Pendente para exibir.'} mt={'1rem'} />
        )}
      </IntegratorContainer>

      {isApproveModalInfoOpen && (
        <ApproveModal
          handleApproveOrder={(timestamp: string) =>
            handleApproveOrder(timestamp)
          }
          close={() => setIsApproveModalInfoOpen(false)}
          requestCode={activeCode}
          ifoodIcon={
            !!filteredData.find(item => item.PedidoSEQ === currentModalInfoCode)
              ?.EhIFoodNaoAprovados
          }
        />
      )}

      {isRejectModalInfoOpen && (
        <RejectModal
          handleRejectOrder={handleRejectOrder}
          close={() => setIsRejectModalInfoOpen(false)}
          rejectReasons={rejectReasons}
          setRejectReason={(reason: Reason) => setSelectedRejectReason(reason)}
          requestCode={activeCode}
          ifoodIcon={
            !!filteredData.find(item => item.PedidoSEQ === currentModalInfoCode)
              ?.EhIFoodNaoAprovados
          }
        />
      )}

      {isLoading && <ScaleLoader />}

      {modalInfo && (
        <Order
          ifoodIcon={
            !!filteredData.find(item => item.PedidoSEQ === currentModalInfoCode)
              ?.EhIFoodNaoAprovados
          }
          close={handleCloseModal}
          reject={() => handleOpenRejectModalInfo(String(currentModalInfoCode))}
          approve={() =>
            handleOpenApproveModalInfo(String(currentModalInfoCode))
          }
          actionButtons
        >
          {modalInfo}
        </Order>
      )}

      {/* {error && <OverlayNotification text={error} close={() => setError('')} />} */}

      {approveResponse && (
        <OverlayNotification
          text={approveResponse}
          close={handleCloseApproveResponseModal}
        />
      )}

      {rejectResponse && (
        <OverlayNotification
          text={rejectResponse}
          close={handleCloseRejectResponseModal}
        />
      )}

      {ifoodModal && (
        <IFoodModal
          close={() => setIfoodModal('')}
          value={ifoodModal}
          update={handleGetIntegratorReport}
        />
      )}

      {pedzapModal && currentRequestItem && (
        <PedZapModal
          update={handleGetIntegratorReport}
          close={() => setPedzapModal('')}
          value={pedzapModal}
          currentItem={currentRequestItem}
        />
      )}
    </PageCard>
  )
}

export default Integrator
