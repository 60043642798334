function validateEmptyField (
  value: string,
  fieldName: string,
  errorTextMessage: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value?.length === 0) {
    createErrorMethod(fieldName, errorTextMessage)
    return false
  }

  cleanErrorsByFieldname(fieldName)
  return true
}

export {
  validateEmptyField
}
