import styled from "styled-components";

export const ComplementsModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.dark.main};
  color: ${({ theme }) => theme.colors.dark.cardText};
  width: 60%;
  height: 100%;
  z-index: 9000;
  position: absolute;
  right: 0;
  overflow: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  .sidebar-header{
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
    > h1{
      margin-left: 2rem;
      font-size: 32px;
    }
    > button{
      margin-right: 3rem;
      margin-left: auto;
    }
  }

  .addItem-tabs{
    margin-top: 1rem;
    display: flex;
    list-style: none;
    gap: 1rem;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 0.5rem;

    > button {
      color: ${({ theme }) => theme.colors.dark.cardText};
    }

    > .activeTab{
      border-bottom:2px solid #6F38C5;
    }
  }

  /* .sidebar-caption{
    width: 100%;
    font-size: 16px;
    display: flex;
    margin-left: 2rem;
    margin-top: 2rem;
  } */

  div {
    label, span {
      font-size: 1.6rem;
      margin-bottom: .6rem;

      display: block;
    }
  }

  .addCategory-Container{
    margin-top: 3rem;
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    padding: 20px;

    .error {
      color: #ff0000;
      font-size: 1.3rem;
    }
   
    >.switch{
      text-align: center;
    }

    > div{
     width: 100%;
    }
  }

  .error {
    color: #ff0000;
  }

  .select-complements-group {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: -2rem;
  
    padding: 20px;

    .select-field {
      flex: 1;
    }

    .border-option {
      display: flex;
      flex-direction: column;

      margin-left: 4rem;
    }
  }

  .content-wrapper {
    flex: 1;
  }




  .save-container{
    display: flex;
    gap: 1rem;
    width: 100%;
    background: #383f54;
    height: 80px;
    justify-content: flex-end;
    

    > button{
      margin-top: 1.5rem;
      color: #fff;
      margin-right: 1rem;
      border: 1px solid #fff;
      padding: 16px 24px;
      height: 48px;
      font-size: 18px;
      border-radius: 1rem;
     
      &:first-child{
        background: transparent;
        &:hover{
          opacity: 0.7;
        }
      }

      &:last-child{
        background: #EB1D36;
        &:hover{
          opacity: 0.7;
        }
      }

    }
  }


  .container-price{
    display: flex;
    gap: 2rem;
    .meioVenda-container{
      width: 60%;
    padding: 20px;
    
    .table-select-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
      align-content:center;
      > select{
        width: 100%;
      }

      > div{
        button{
          border-radius: 0.7rem;
          margin-bottom: 1rem;
          padding: 10px;
          
          &:first-child{
            background: #cf303c;

          }
         

            &:hover {
          transform: translateY(-2px);}

            &:active {
          box-shadow: none;
          transform: translateY(0);
        
    }
        }
        
      }
    }
    > .meioVenda-cards{
    
    padding: 20px;
    border-radius: 0.7rem;
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;

    
    > p{
      box-shadow: 0px 1px 10px #000;
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      background-color: #6F38C5;
      font-size: 16px;
      text-align: center;
      
    }
    >table{
      background: ${({ theme }) => theme.colors.dark.main};
      border-collapse: collapse;
      width: 100%;
      > thead{
        >tr{
          height: 3rem;
          > td{
            width: 50%;
            border: 1px solid #fff;
          }
        }
      }
      > tbody{
        > tr{
        
          > td{
            width: 50%;
            border: 1px solid #fff;
           
            > .cash-input{
              display: flex;
              color: #000;
              > .price-input{
              background-color: #F9F9F9;
              font-size: 12px;
              width: 40%;
              
              text-align: center;
              margin: auto auto;
            }
            }
            
          }
        }
      }
    }
    
  }
  }
  .lojas-container{
    width: 40%;
    max-height: 630px;
    background: ${({ theme }) => theme.colors.dark.main};
    margin-right: 2rem;
    margin-top: 2rem;
    border-radius: 0.7rem;
    border:1px solid #937DC2;
    margin-bottom: 5rem;
    > .lojas-container-header{
      > p{
        
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      background-color: #6F38C5;
      font-size: 16px;
      text-align: center;
      }
    }

    > .lojas-container-body{
      display: flex;
      flex-direction: column;
      max-height: 600px;
      overflow: auto;
      
      .active{
        background: #7367F0;
      }
      > button{
        margin-top: 1rem;
       
        color:#fff;
        width: 100%;
        text-align: center;
        padding: 3px;
        
        &:hover{
          background: #7367F0;
        }
      }
    }
  }
  }


  
  @media(max-width: 800px){
    width: 100%;
    height: 100%;
  }

  @media(min-width: 1390px){
    width: 50%;
    height: 100%;
  }
  @media(min-width: 1690px){
    width: 40%;
    height: 100%;
  }


  
`