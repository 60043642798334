import axios from 'axios'

async function getExpeditionOrders(
  storeCode: number,
  url: string,
  token: string
) {
  const env = process.env.REACT_APP_EXPEDITION_ORDER_MAP

  const response = await axios({
    method: 'GET',
    url: `${url}/${env}/${storeCode}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

export const expeditionService = {
  getExpeditionOrders
}