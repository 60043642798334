import styled from "styled-components";

export const AuthContainer = styled.div`
  .split {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 2rem;
  }

  .card{ 
    background-color: ${({ theme }) => theme.colors.dark.main};
    width: 100%;
  }

  .input-field-name{
    color: #d0d2d6;
    font-size: 1.4rem;
    line-height: 1.8rem;
    display: inline-block;
    font-family: "Montserrat", Helvetica, Arial, serif;

    margin-bottom: .4rem;
  }

  .label-theme-dark{
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.dark.cardText};
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .global-option{
    font: 1.2rem/3 sans-serif;
  }

  .form-button {
    background: ${({ theme }) => theme.colors.button.main};
    display: block;
    width: 100%;
    border: 0;
    cursor: pointer;
    padding: 1rem;
    border-radius: .8rem;
    color: ${({ theme }) => theme.colors.dark.text};

    &[disabled]{
      opacity: 0.5;
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.button.hover};
      transform: scale(1.1);
      transition: all 0.5s ease-in-out;
    }
  }

  .agile-logo{
    height: 5rem;
    margin-left: 2rem;
  }

  .form-container{
    display: block;
    align-self: center;
    margin-left:auto;
    margin-right: auto;
  }

  .agile-logo-form{
    display: flex;
    height: 10rem;
    align-self: center;
    margin-left:auto;
    margin-right: auto;
    margin-top: 5rem;
  }
  
  .left {
    width: 60%;
    left: 0;
    background-color: #4B15B5;
  }

  .right {
    width: 40%;
    right: 0;
    background-color: ${({ theme }) => theme.colors.dark.main};

    .centered {
      width: 80%;
      max-width: 330px;
    }

    .version-controller{
      display: flex;
      font-size: 12px;
      color: #fff;
      justify-content: center;
      margin-top: 1rem;
    }
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 50rem;
      border-radius: 50%;
    }
  }

  @media (max-width:992px) {
    .agile-logo-form{
      display: flex;
      height: 8rem;
      align-self: center;
      margin: 10rem auto 0;
    }

    .left {
      display:none;
    }

    .right {
      width: 100%;
      right: 0;
      background-color: ${({ theme }) => theme.colors.dark.main};
    }
  }   
`
