import NoItems from '../../../../../components/NoItems'
import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import axios from 'axios'
import IngredientSelect from './ingredientSelect/ingredientSelect'
import IngredientItem from './ingredientItem/ingredientItem'

type ingredientProps = {
  Ingredientes: any
  setIngredientes1: (value: any) => void
  ReplaceObj: (value: any) => void
}

function Ingredient({
  Ingredientes,
  setIngredientes1,
  ReplaceObj
}: ingredientProps) {
  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )

  const [ingredientList, setIngredientList] = useState([])

  const getIngredientList = useCallback(async () => {
    const env = process.env.REACT_APP_CARDAPIO_INGREDIENT_LIST_GET
    if (!env || !selectedEnvironment.Url || !userToken) return

    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setIngredientList(response.data)
    } catch (e) {
    }
  }, [licenseStoreCode, userToken, selectedEnvironment?.Url])

  useEffect(() => {
    getIngredientList()
  }, [getIngredientList])

  return (
    <div className="container-ingredient">
      <IngredientSelect
        ingredientList={ingredientList}
        ingredientes={Ingredientes}
        setIngredientes={(value: any) => setIngredientes1(value)}
        // ingredientModalOpen={() => setIsAddIngredientModalOpen(true)}
      />

      <div className="ingredient-cards">
        {Ingredientes?.length > 0 ? (
          <>
            <p>Lista de Ingredientes</p>
            <div className="ingredient-table thematic-scroll">
              <table>
                <thead>
                  <tr>
                    <td id="name">Ingredientes</td>
                    <td id="seq">Sequência</td>
                    <td id="required">Obrigatório</td>
                    <td id="action">Ações</td>
                  </tr>
                </thead>
                <tbody>
                  {Ingredientes?.sort((a: any, b: any) => {
                    return a?.Sequencia > b?.Sequencia ? 1 : -1
                  })?.map((ingredient: any) => (
                    <IngredientItem
                      key={ingredient.Codigo}
                      ingredient={ingredient}
                      setIngredientes={setIngredientes1}
                      replaceFunction={ReplaceObj}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="error-ingredient">
            <NoItems title={'Nenhum Ingrediente cadastrado'} />
          </div>
        )}
      </div>

      {/* {isAddIngredientModalOpen && (
        <AddIngredientModal
          itemIngredientList={ingredientes}
          close={() => setIsAddIngredientModalOpen(false)}
          setIngredientes={setIngredientes}
        />
      )} */}

      {/* {isEditIngredientModalOpen && (
        <AddIngredientModal
          itemIngredientList={ingredientes}
          close={() => setIsEditIngredientModalOpen(false)}
          ingredientData={selectedIngredient}
          isEdit
          setIngredientes={setIngredientes}
        />
      )} */}
    </div>
  )
}

export default Ingredient
