/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from 'react'
import { useState, useEffect } from 'react'
import Switch from 'react-switch'
type HorariosDeFuncionamentoProps = {
  day: any
  array: DayOfTheWeek[]
  replaceFunction: (obj: any) => void
}

type DayOfTheWeek = {
  Dia: number
  Abertura: string
  Fechamento: string
  AberturaAgendamento: string
  FechamentoAgendamento: string
  Checked: boolean
}
function getWeekDay(day: number) {
  switch (day) {
    case 1:
      return <p>Domingo</p>
    case 2:
      return <p>2° Feira</p>
    case 3:
      return <p>3° Feira</p>
    case 4:
      return <p>4° feira</p>
    case 5:
      return <p>5° Feira</p>
    case 6:
      return <p>6° Feira</p>
    case 7:
      return <p>Sabado</p>
    default:
      break
  }
}

function HorDeFuncItem({
  day,
  replaceFunction
}: HorariosDeFuncionamentoProps) {
  const [Dia] = useState<number>(day.Dia || 0)
  const [Abertura, setAbertura] = useState<string>(day.Abertura || '00:00')
  const [Fechamento, setFechamento] = useState<string>(
    day.Fechamento || '00:00'
  )
  const [AberturaAgendamento, setAberturaAgendamento] = useState<string>(
    day.AberturaAgendamento || '00:00'
  )
  const [FechamentoAgendamento, setFechamentoAgendamento] = useState<string>(
    day.FechamentoAgendamento || '00:00'
  )
  const [Checked, setChecked] = useState<boolean>(day.Checked || false)

  const weekDayData = useMemo(
    () => ({
      Dia,
      Abertura,
      Fechamento,
      AberturaAgendamento,
      FechamentoAgendamento,
      Checked
    }),
    [
      Dia,
      Abertura,
      Fechamento,
      AberturaAgendamento,
      FechamentoAgendamento,
      Checked
    ]
  )

  useEffect(() => {
    replaceFunction(weekDayData)
  }, [weekDayData])

  return (
    <>
      <tr key={day.Dia}>
        <td id="day">
          <div className="day-checkbox">
            <div>
              {getWeekDay(day.Dia)}
              <Switch
                checked={Checked}
                onChange={setChecked}
                offColor="#dc0000"
              />
            </div>
          </div>
        </td>
        <td id="store">
          <div>
            <div>
              <label
                htmlFor=""
                style={
                  !Checked
                    ? { textDecoration: 'line-through', opacity: '0.5' }
                    : {}
                }
              >
                Abertura
              </label>
              <input
                type="time"
                className="form-control"
                id="time"
                value={Abertura}
                onChange={e => setAbertura(e.target.value)}
                disabled={!Checked}
              />
            </div>
            <div>
              <label
                htmlFor=""
                style={
                  !Checked
                    ? { textDecoration: 'line-through', opacity: '0.5' }
                    : {}
                }
              >
                Fechamento
              </label>
              <input
                type="time"
                className="form-control"
                id="time"
                value={Fechamento}
                onChange={e => setFechamento(e.target.value)}
                disabled={!Checked}
              />
            </div>
          </div>
        </td>
        <td id="app">
          <div>
            <div>
              <label
                htmlFor=""
                style={
                  !Checked
                    ? { textDecoration: 'line-through', opacity: '0.5' }
                    : {}
                }
              >
                Abertura
              </label>
              <input
                type="time"
                className="form-control"
                id="time"
                value={AberturaAgendamento}
                onChange={e => setAberturaAgendamento(e.target.value)}
                disabled={!Checked}
              />
            </div>
            <div>
              <label
                htmlFor=""
                style={
                  !Checked
                    ? { textDecoration: 'line-through', opacity: '0.5' }
                    : {}
                }
              >
                Fechamento
              </label>
              <input
                type="time"
                className="form-control"
                id="time"
                value={FechamentoAgendamento}
                onChange={e => setFechamentoAgendamento(e.target.value)}
                disabled={!Checked}
              />
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

export default HorDeFuncItem
