import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { RootState } from '../store/store'

function AuthProtectionLayer() {
  const { userData } = useSelector((state: RootState) => state.auth)

  if (userData?.access_token) {
    return <Outlet />
  } else {
    return <Navigate to="/auth" />
  }
}
export default AuthProtectionLayer