import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../store/store'

type DarkFilmContainerProps = {
  active: boolean
}

const DarkFilmContainer = styled.div<DarkFilmContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background: ${({ active }) => active ? `#00000088` : 'none'};
  z-index: ${({ active }) => active ? 500 : -1};
  transition: background .24s ease;
`

function DarkFilm() {
  const { pageDarkFilm } = useSelector((state: RootState) => state.generic)

  return (
    <DarkFilmContainer active={pageDarkFilm}></DarkFilmContainer>
  )
}

export default DarkFilm