/* eslint-disable react-hooks/exhaustive-deps */

import { Camera, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Overlay from '../../../../components/Overlay'
import { ProfileModalContainer } from './styles'
import Switch from 'react-switch'
import defaultCover from '../../../../assets/images/logo/defaultcover.jpg'
import defaultLogo from '../../../../assets/images/logo/defaultlogo.png'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import ScaleLoader from '../../../../components/ScaleLoader/ScaleLoader'
import axios from 'axios'
import {
  ErrorAlert,
  SuccessAlert
} from '../../../../components/SweetAlert/SweetAlert'
import HorDeFuncItem from './HorDeFuncionamento/horDeFunc'

type ProfileProps = {
  close: () => void
  obj: any
  updateAPI: () => void
}

function ProfileModal({ obj, close, updateAPI }: ProfileProps) {
  const { userToken, selectedEnvironment } = useSelector(
    (state: RootState) => state.auth
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const tabData = [
    { id: 10, title: 'Perfil', tab: 'profile' },
    { id: 20, title: 'Horários de Funcionamento', tab: 'workingSchedule' }
  ]

  const [PedidoMinimo, setPedidoMinimo] = useState<number>(
    obj.PedidoMinimo || 0
  )
  const [TempoEstimadoRetirada, setTempoEstimadoRetirada] = useState<string>(
    obj.TempoEstimadoRetirada || '00:00'
  )
  const [TempoEstimadoEntrega, setTempoEstimadoEntrega] = useState<string>(
    obj.TempoEstimadoEntrega || '00:00'
  )
  const [PossuiRetirada, setPossuiRetirada] = useState<boolean>(
    obj.PossuiRetirada || false
  )
  const [PossuiEntrega, setPossuiEntrega] = useState<boolean>(
    obj.PossuiEntrega || false
  )
  const [PossuiDrive, setPossuiDrive] = useState<boolean>(
    obj.PossuiDrive || false
  )
  const [HorariosFuncionamento, setHorariosFuncionamento] = useState(
    obj.HorariosFuncionamento || []
  )
  const formObj = [
    {
      id: 1,
      children: [
        { id: 11, title: 'Codigo', value: obj.CodigoExterno, type: 'text' },
        { id: 12, title: 'Sigla', value: obj.Sigla, type: 'text' },
        { id: 13, title: 'CNPJ', value: obj.CNPJ, type: 'text' }
      ]
    },
    {
      id: 2,
      children: [
        { id: 21, title: 'Razão Social', value: obj.RazaoSocial, type: 'text' }
      ]
    },
    {
      id: 3,
      children: [
        { id: 31, title: 'Nome Fantasia', value: obj.Nome, type: 'text' }
      ]
    },
    {
      id: 4,
      children: [
        { id: 41, title: 'CEP', value: obj.Endereco.Cep, type: 'text' },
        { id: 42, title: 'UF', value: obj.Endereco.Uf, type: 'text' },
        { id: 43, title: 'Cidade', value: obj.Endereco.Cid.d, type: 'text' }
      ]
    },
    {
      id: 5,
      children: [
        { id: 51, title: 'Endereço', value: obj.Endereco.Log, type: 'text' },
        { id: 52, title: 'Numero', value: obj.Endereco.Numero, type: 'text' }
      ]
    },
    {
      id: 6,
      children: [
        {
          id: 61,
          title: 'Complemento',
          value: obj.Endereco.Complemento,
          type: 'text'
        },
        { id: 62, title: 'Bairro', value: obj.Endereco.Bai.d, type: 'text' }
      ]
    },
    {
      id: 7,
      children: [
        {
          id: 71,
          title: 'Telefone',
          value: obj.Telefones.map((tel: any) => tel.Numero),
          type: 'tel'
        },
        { id: 72, title: 'Email', value: obj.Email, type: 'email' }
      ]
    }
  ]
  // const workingDays = [
  //   { id: 1, title: '2º feira', checked: true },
  //   { id: 2, title: '3º feira', checked: true },
  //   { id: 3, title: '4º feira', checked: false },
  //   { id: 4, title: '5º feira', checked: false },
  //   { id: 5, title: '6º feira', checked: true },
  //   { id: 6, title: 'Sábado', checked: false },
  //   { id: 7, title: 'Domingo', checked: true }
  // ]

  const [coverPhoto, setCoverPhoto] = useState(obj.FotoUri || defaultCover)
  const [profilePhoto, setProfilePhoto] = useState(obj.LogoUri || defaultLogo)
  const [activeTab, setActiveTab] = useState<string>('profile')
  const ProfileModal = document.getElementById('profile-modal')
  const [coverImage, setCoverImage] = useState<File>()
  const [, setBase64CoverImage] = useState<string>('')
  const [profileImage, setProfileImage] = useState<File>()
  const [, setBase64ProfileImage] = useState<string>('')
  const [storeHexColor, setStoreHexColor] = useState<string>('')

  useEffect(() => {
    //Pegar cor hexaDecimal da loja//
    if (obj.Parametros) {
      return setStoreHexColor(
        obj.Parametros.find(
          (param: any) => param.Chave === 'MV_COLOR_BACKGROUND'
        ).Valor
      )
    } else {
      return setStoreHexColor('#fff')
    }
  }, [obj])

  useEffect(() => {
    if (coverImage) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setCoverPhoto(reader.result as string)
        setBase64CoverImage(reader.result as string)
        handleImageBase64(reader.result as string, 'loja')
      }

      reader.readAsDataURL(coverImage)
    }
  }, [coverImage])

  useEffect(() => {
    if (profileImage) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfilePhoto(reader.result as string)
        setBase64ProfileImage(reader.result as string)
        handleImageBase64(reader.result as string, 'logo')
      }

      reader.readAsDataURL(profileImage)
    }
  }, [profileImage])

  // useEffect(() => {
  //   handleImageBase64(base64ProfileImage, false)
  // }, [base64ProfileImage])

  // useEffect(() => {
  //   if (base64CoverImage) {
  //     handleImageBase64(base64CoverImage, true)
  //   }
  // }, [base64CoverImage])

  const handleImageBase64 = async (base64: string, type: string) => {
    const env = process.env.REACT_APP_ORGANIZATION_IMAGE
    if (base64 === '') {
      ErrorAlert('Ops', 'Falha ao selecionar a imagem, tente novamente !')
      return
    }

    try {
      setIsLoading(true)
      const payload = {
        Type: type,
        ContentType: 'image/png',
        Base64: base64
      }

      const response = await axios({
        method: 'POST',
        data: payload,
        url: `${selectedEnvironment.Url}/${env}/${obj.CodigoExterno}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      if (response.status === 200) {
        updateAPI()
      }
    } catch (err: any) {
      ErrorAlert('Erro', err)
    }

    setIsLoading(false)
  }

  function EditStoreInfo() {
    const env = process.env.REACT_APP_STORE_UPDATE

    if (!env || !selectedEnvironment.Url || !userToken) return
    ;(async () => {
      setIsLoading(true)

      try {
        const StoreData = obj

        const payload = {
          ...obj,
          PedidoMinimo,
          TempoEstimadoRetirada,
          TempoEstimadoEntrega,
          PossuiRetirada,
          PossuiEntrega,
          PossuiDrive,
          HorariosFuncionamento
        }

        await axios({
          method: 'PUT',
          data: payload,
          url: `${selectedEnvironment.Url}/${env}/${StoreData.CodigoExterno}/${StoreData.Storage}`,
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })

        SuccessAlert(
          'Sucesso',
          `Modificações na empresa ${StoreData.Sigla} ${StoreData.Nome} foram salvas com sucesso`
        )
        updateAPI()
      } catch (err: any) {
        ErrorAlert('Atenção', err.message)
      }

      setIsLoading(false)
    })()
  }

  function replaceObj(obj: any) {
    setHorariosFuncionamento((prevState: any) =>
      prevState.map((item: any) => (item.Dia === obj.Dia ? obj : item))
    )
  }

  if (!ProfileModal) return null
  return ReactDOM.createPortal(
    <Overlay>
      <ProfileModalContainer className="thematic-scroll">
        <div className="tabs">
          {tabData.map(tab => (
            <button
              key={tab.id}
              className={activeTab === tab?.tab ? 'activeTab' : ''}
              onClick={() => setActiveTab(tab?.tab)}
            >
              <li>{tab.title}</li>
            </button>
          ))}
          <div>
            <button className="closeModal" onClick={close}>
              <X color="#f3f4f8" />
            </button>
          </div>
        </div>
        <hr />
        {activeTab === 'profile' && (
          <>
            <div className="container">
              <button className="add-coverphoto">
                <label htmlFor="cover-photo">
                  <Camera color="#f3f4f8" />
                  Adicionar Capa
                </label>
                <input
                  type="file"
                  id="cover-photo"
                  accept="image/jpg"
                  onChange={(event: any) => {
                    const file = event?.target?.files[0]
                    if (file && file.type.substr(0, 5) === 'image') {
                      setCoverImage(file)
                    } else {
                      setCoverImage(undefined)
                    }
                  }}
                />
              </button>

              <div className="cover-photo">
                <img src={coverPhoto} alt="" />
              </div>
              <div className="profile-photo-container">
                <img
                  className="logo"
                  src={profilePhoto}
                  alt=""
                  style={{ backgroundColor: `#${storeHexColor}` }}
                />

                <button className="add-photo">
                  <label htmlFor="photo">
                    <Camera />
                    Editar Logo
                  </label>
                  <input
                    type="file"
                    name=""
                    id="photo"
                    accept="image/png"
                    onChange={(event: any) => {
                      const file = event?.target?.files[0]
                      if (file && file.type.substr(0, 5) === 'image') {
                        setProfileImage(file)
                      } else {
                        setProfileImage(undefined)
                      }
                    }}
                  />
                </button>
              </div>
            </div>

            <div className="form-container">
              {formObj.map(row => (
                <div className="formrow" key={row.id}>
                  {row.children.map(input => (
                    <div key={input.id} id={input.title}>
                      <label htmlFor="codigo">{input.title}</label>
                      <input
                        type={input.type}
                        className="form-control"
                        value={input.value}
                      />
                    </div>
                  ))}
                </div>
              ))}
              <div className="formrow">
                <div>
                  <label htmlFor="PedMin">Pedido Minimo</label>
                  <input
                    type="number"
                    value={PedidoMinimo}
                    className="form-control"
                    onChange={e => setPedidoMinimo(e.target.valueAsNumber)}
                  />
                  <label>
                    Menor valor de um pedido, sem contar a taxa de entrega
                  </label>
                </div>

                <div>
                  <label htmlFor="PedMin">Tempo Estimado de Retirada</label>
                  <input
                    type="time"
                    value={TempoEstimadoRetirada}
                    className="form-control"
                    onChange={e => setTempoEstimadoRetirada(e.target.value)}
                  />
                </div>

                <div>
                  <label htmlFor="PedMin">Tempo Estimado de Entrega</label>
                  <input
                    type="time"
                    value={TempoEstimadoEntrega}
                    className="form-control"
                    onChange={e => setTempoEstimadoEntrega(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <div className="formrow">
                <div className="switch">
                  <label htmlFor="E-mail">Possui Retirada?</label>

                  <Switch
                    onChange={setPossuiRetirada}
                    checked={PossuiRetirada}
                    offColor="#dc0000"
                  />
                </div>
                <div className="switch">
                  <label htmlFor="E-mail">Possui Entrega?</label>
                  <Switch
                    onChange={setPossuiEntrega}
                    checked={PossuiEntrega}
                    offColor="#dc0000"
                  />
                </div>
                <div className="switch">
                  <label htmlFor="E-mail">Possui Drive Thru?</label>
                  <Switch
                    onChange={setPossuiDrive}
                    checked={PossuiDrive}
                    offColor="#dc0000"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {activeTab === 'workingSchedule' && (
          <div className="workingDays-container">
            <table>
              <thead>
                <tr>
                  <td id="day">Dia Semana</td>
                  <td id="store">Loja</td>
                  <td id="app">Agendamento</td>
                </tr>
              </thead>
              <tbody>
                {HorariosFuncionamento.map((day: any) => (
                  <HorDeFuncItem
                    //day => cada item do array
                    replaceFunction={replaceObj}
                    day={day}
                    key={day.Dia}
                    //array => array inteiro
                    array={HorariosFuncionamento}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="save-container">
          <button onClick={close}>Cancelar</button>
          <button onClick={() => EditStoreInfo()}>Salvar</button>
        </div>
        {isLoading && <ScaleLoader />}
      </ProfileModalContainer>
    </Overlay>,
    ProfileModal
  )
}

export default ProfileModal
