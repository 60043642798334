import styled from 'styled-components';

export const PageWrapperContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  
  overflow: auto;

  .page_content {
    width: 100%;
    height: 100vh;

    overflow: auto;

    padding: 2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`