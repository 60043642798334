import { RequestItem } from '../../../../types/integrator';
import integratorCardData from '../integratorCard/data/integratorCardData';

export default function getFilteredRequests(
  requests: RequestItem[],
  filters: string[]
) {
  let filteredRequests: RequestItem[] = []

  const activeFilters = integratorCardData.filter((el) => filters.find((filter) => filter === el.dbkey))

  if (activeFilters.length === 0) {
    return requests
  }

  activeFilters.forEach((activeFilter) => {
    filteredRequests = [
      ...filteredRequests,
      ...requests.filter((request) => activeFilter.condition(request))
    ]
  })

  return filteredRequests
}