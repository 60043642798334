import styled from 'styled-components';

export const FinancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;

  button, a {
    transition: transform .1s ease-in;

    &:hover {
      transform: scale(1.15);
    }
  }

  .message {
    margin-top: 2rem;
    font-size: 1.6rem;
  }
`
