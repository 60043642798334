import { Pencil, Trash } from 'lucide-react'
import Swal from 'sweetalert2'

type ComplementsItemProps = {
  obj: any
  editAction: () => void
  deleteFicha: (obj: any, codTamanho: number) => void
  sizeSelected: any
}

function ComplementsItem({
  obj,
  editAction,
  deleteFicha,
  sizeSelected
}: ComplementsItemProps) {
  function DeleteComplement(obj: any) {
    Swal.fire({
      title: `Deseja Excluir o Complemento: ${obj.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.isConfirmed) {
        deleteFicha(obj, sizeSelected?.CodigoTamanho)
        // Swal.fire({
        //   title: `Complemento ${obj.Nome} Excluido com Sucesso`,
        //   icon: 'success',
        //   background: '#283046',
        //   color: '#f3f4f8'
        // })
      }
    })
  }

  return (
    <tr>
      <td>
        {obj.Codigo} - {obj.Nome}
      </td>
      <td>{obj.QtdeMinima}</td>
      <td>{obj.QtdeMaxima}</td>
      <td>
        <div className="crud-actions">
          <button className="edit-item" onClick={editAction}>
            <Pencil color="#f3f4f8" />
          </button>
          <button className="delete-item" onClick={() => DeleteComplement(obj)}>
            <Trash color="#f3f4f8" />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default ComplementsItem
