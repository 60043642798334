import styled from 'styled-components'

export const SearchContainer = styled.div`
.warning{
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  > p{
    font-size: 20px;
  }
}
.Filter-Collapse{
    margin-top: 1rem;
    display: flex;
    background: ${({ theme }) => theme.colors.dark.main};
    border-radius: 1rem;
    width: 100%;
    padding: .8rem 2rem;
    margin: 2rem 0;
    justify-content: space-between;
    margin-bottom: -1.5rem;
    align-items: center;
    
    > p{
      font-size: 14px;
      color: ${({ theme }) => theme.colors.dark.cardText};
    }
   
    > .chev-rotation{
      transition: 400ms;
      transform: rotate(180deg);
    }
    > .chev-rotation-active{
      transition: 400ms;
      transform: rotate(0deg);
    }
  }

  .Table-Container{
    margin-top: 3rem;
    height: 52rem;
  }

  .Table-Container-active{
    margin-top: 2rem;
    height: 35rem;
  }
  
  
`

export const FiltersContainer = styled.div`

  background: ${({ theme }) => theme.colors.dark.main};
  color: ${({ theme }) => theme.colors.dark.cardText};
  border-radius: 1rem;
  width: 100%;
  padding: .8rem 2rem;
  margin: 2rem 0;
  display: grid;
  gap: 2rem;
  grid-template-areas: "tipo   cliente   origem"
                      "pagamento   status   origem";
                      
  .tipo{
    grid-area: tipo;
  }
 .pagamento{
  grid-area: pagamento;
 }
  .cliente{
    grid-area: cliente;
  }
 .status{
  grid-area: status;
 }
  .origem{
  grid-area: origem;
  align-self: center;
  justify-self: center;
  
  }
 
  .filter-group {
 
    div {
      label{  
        display: flex;
        font-size: 12px;
        
        input[type='checkbox'] {
          width: 30px;
          height: 15px;
          margin-bottom: 1rem;
          accent-color: #7367F0;
          transition-duration: 0.5s;
          cursor: pointer;
          &:hover {
            transform: scale(1.2);
          }
        }

        span{
          flex: 1;
          margin-left: .5rem;
        }
      }

      &.checkbox-field {
        display: flex;
        flex-wrap: wrap;

        label {
          width: 50%;

          span {
            flex: 1;
          }
        }
      }
    }

    h3{
      justify-content: center;
      display: flex;
      margin-bottom: 1rem;
    }
  }

  @media(max-width: 768px) {
    grid-template-areas: "tipo"   "cliente"   
                      "pagamento"   "status"
                      "origem"   "origem";

                     
    .filter-group {
      width: 100%;
      
    }

    select{
      width: 100%;
    }
  }

  @media (max-width: 450px) {
    grid-template-areas: "tipo"   "cliente"   
                      "pagamento"   "status"
                      'origem';

      .origem{
      align-self: center;
      justify-self: center;
                      
    }
    .filter-group {
      
      div {
        &.checkbox-field {
          label {
            width: 100%;
          }
        }
      }
    }
  }

 
  
`
