import React from 'react'
import { Filter } from './data/filtersData'

type FilterGroupProps = {
  filter: Filter
  handleToggleCheckboxFilter: (filter: string) => void
  handleToggleSelectFilter: (filter: string, options: string[]) => void
  handleToggleUser: (user: string) => void
  currentUser: string
  activeFilters: string[]
}

function FilterGroup({
  filter,
  handleToggleCheckboxFilter,
  handleToggleSelectFilter,
  handleToggleUser,
  currentUser,
  activeFilters
}: FilterGroupProps) {
  return (
    <>
      <div className={` ${filter.area} filter-group`}>
        <h3>{filter.label}</h3>
        {filter.type === 'select' ? (
          <div>
            <select
              name={`${filter.type}${filter.id}`}
              className={`form-control `}
              onChange={e =>
                handleToggleSelectFilter(
                  e.target.value,
                  filter.filters.map(filter => filter.smug)
                )
              }
            >
              {filter.filters.map(filter => (
                <option key={filter.smug} value={filter.smug}>
                  {filter.title}
                </option>
              ))}
            </select>
          </div>
        ) : filter.type === 'checkbox' ? (
          <div>
            {filter.filters?.map(currFilter => (
              <label key={currFilter.smug}>
                <input
                  name={`${filter.type}${filter.id}`}
                  type={filter.type}
                  value={currFilter.smug}
                  onChange={e => handleToggleCheckboxFilter(e.target.value)}
                  checked={
                    !!activeFilters.find(filter => filter === currFilter.smug)
                  }
                />
                <span>{currFilter.title}</span>
              </label>
            ))}
          </div>
        ) : (
          <div>
            <label>
              <input
                className="form-control"
                value={currentUser}
                type={filter.type}
                onChange={e => handleToggleUser(e.target.value)}
              />
            </label>
          </div>
        )}
      </div>
    </>
  )
}

export default FilterGroup
