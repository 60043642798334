import { AlertTriangle } from 'lucide-react'
import React from 'react'
import { NoItemsContainer } from './styles'

type NoItemsProps = {
  title: string
  mt?: string
}

function NoItems({ title, mt }: NoItemsProps) {
  return (
    <NoItemsContainer>
      <div className="no-category-container" style={{ marginTop: mt }}>
        <AlertTriangle size={50} color={'red'} />
        <p>{title}</p>
      </div>
    </NoItemsContainer>
  )
}

export default NoItems
