export default function getEnv(type: number): string | undefined {
  switch (type) {
    case 1:
      return process.env.REACT_APP_AVERAGE_TICKET
    case 2:
      return process.env.REACT_APP_AVERAGE_TICKET_PER_CHANNEL
    case 3:
      return process.env.REACT_APP_AVERAGE_TICKET_PER_SALE_METHOD
    case 4:
      return process.env.REACT_APP_SALES_PER_CHANNEL
    case 5:
      return process.env.REACT_APP_SALES_PER_SALE_METHOD
    default:
      return undefined
  }
}
