export const dark = {
  colors: {
    primary: {
      normal: '#4B15B5',
    },
    dark: {
      background: '#283046',
      main: '#283046',
      text: '#f3f4f8',
      input: '#6e6b7b',
      cardText: "#f3f4f8",
      json: '',
      default: '#283046',
    },
    card: {
      background: "#283046",
      text: "#f3f4f8",
      border: '#4B15B5',

    },
    button: {
      main: '#7367F0',
      hover: '#65b5f6'
    },
    menu: {
      active: '#283046',
    },
    title: '#FFFFFF'
  }
}