import axios from 'axios'

async function getLicenseToken(license: string) {
  const userName = process.env.REACT_APP_LICENSE_TOKEN_USERNAME
  const versaoApi = process.env.REACT_APP_LICENSE_TOKEN_VERSION
  const url = process.env.REACT_APP_LICENCIADOR

  if (!userName || !versaoApi) return

  const licenseRequestBody = {
    userName,
    password: license,
    versaoApi
  }

  const response = await axios({
    method: 'POST',
    url,
    data: licenseRequestBody
  })

  return response
}

async function connectUser(
  username: string,
  password: string,
  url: string,
  accessToken: string
) {
  const env = process.env.REACT_APP_LOGIN

  if (!env) return

  const connectUserBody = {
    UserName: username,
    Password: password
  }

  const response = await axios({
    method: 'POST',
    url: `${url}/${env}`,
    data: connectUserBody,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  return response
}

async function refreshUser(
  token: string,
  url: string
) {
  const env = process.env.REACT_APP_REFRESH

  if (!env) return

  const response = await axios({
    method: 'PUT',
    url: `${url}/${env}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

async function getStores(
  token: string,
  url: string,
  storeCode: any,
  storage: any
) {
  const env = process.env.REACT_APP_USER_STORES

  if (!env) return

  const response = await axios({
    method: 'GET',
    url: `${url}/${env}/${storeCode}/${storage}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

async function getFrota(
  token: string,
  url: string
) {
  const env = process.env.REACT_APP_HUSKY_FROTA

  if (!env) return

  const response = await axios({
    method: 'GET',
    url: `${url}/${env}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

export const authService = {
  getLicenseToken,
  connectUser,
  refreshUser,
  getStores,
  getFrota
}