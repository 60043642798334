import styled from 'styled-components'

export const CategoryCard = styled.div`
  .category-card {
    display: flex;
    width: 100%;
    background: ${({ theme }) => theme.colors.dark.main};
    padding: 30px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;

    > h3 {
      color: ${({ theme }) => theme.colors.dark.cardText};
    }
    .actions {
      display: flex;
      
      > .pause{
            width: 70px;
          color: ${({ theme }) => theme.colors.dark.cardText};
          padding: 1rem;
          height: 25px;
          background-color: #576f72;
          &:hover {
          opacity: 0.8;
        }
          }
      > .pause-active{
       background-color: #EB1D36;
       }
      > .actived{
        width: 70px;
          color: #fff;
          padding: 1rem;
          height: 25px;
          background-color: #576f72;
          &:hover {
          opacity: 0.8;
        }
      }
      > .actived-active{
       background-color: #7DCE13;
       }
      > .actions-svg {
        display: flex;

        > button {
          margin-left: 1rem;
        }

        .inactive {
          transition: all 0.3s;
          transform: rotate(0deg);
        }
        .active {
          transition: all 0.3s;
          transform: rotate(180deg);
        }
      }
    }
  }
  //Css Children//

  .children {
    width: 100%;
    background: ${({ theme }) => theme.colors.dark.main};
    height: auto;
    > table {
      display: table;
      margin-bottom: 2rem;
      margin-top: -1rem;
      color: ${({ theme }) => theme.colors.dark.cardText};
      border-collapse: collapse;
      width: 100%;
      text-align: center;
      //cabeçalho tabela//
      > thead {
        
        > .thead-children {
          font-size: 14px;
          > th {
            border: 1px solid #fff;
            &:first-child {
              width: 60%;
            }
            &:nth-child(2) {
              width: 15%;
            }
            &:last-child{
              width: 25%;
            }
          }
        }
      }
      //corpo tabela//
      > tbody {
        height: 60px;
        > tr {
          &:hover {
            background: #554994;
          }
          > .tbody-item {
            display: flex;
            > .img-container {
              padding: 5px;
              margin: auto 0 auto 0;
              margin-left: 1rem;
              transition: 500ms;
              > img {

                border-radius: 0.7rem;
                display: flex;
                width: 80px;
                height: 60px;

                &:hover{
                  transform: scale(1.2);
                  transition: 500ms;
                }
              }
            }
            > .item-text-container {
              margin-left: 1rem;
              padding: 12px;
              > h2,p {
                text-align: left;
              }
              >h2{
                display: flex;
                > div{
                  margin-left: 1rem;
                }
              }
            }
          }

          > td {
            border: 1px solid #fff;
           
    
            > .detail-td {
              display: flex;
              justify-content: center;
              width: 100%;
              > .pause{
                  margin-left: 1rem;
                  width: 70px;
                  color: ${({ theme }) => theme.colors.dark.cardText};
                  padding: 1rem;
                  height: 25px;
                  background-color: #576f72;
              }
              > .actived{
                  width: 70px;
                  color: #fff;
                  padding: 1rem;
                  height: 25px;
                  background-color: #576f72;
              }

              > .pause-active{
                background-color: #EB1D36;
                }

              > .actived-active{
                background-color: #7DCE13;
                }

              > button {
                &:nth-child(3) {
                  margin-left: 1rem;
                  margin-right: 1rem;
                  border-radius: 1rem;
                }
                
              }
            }
          }
        }
      }
    }
  }
  
  @media(max-width: 650px) {
    .children{
      
      >table{ 
        > thead{
          display: none;
        }
        > tbody{
          
          > tr {
            display: block;
            margin-bottom: 2rem;
            > td{
              width: 100%;
              
              &:nth-child(2){
                height: 100px;
               
              }
              &:last-child{
                height: 100px;
                width: 10%;
              }
            }
            > .tbody-item{
              width: 100%;
            }
            > .detail-td{
              width: 100%;
            }
           
          }
        }
      }
    }
  }
  
  @media(min-width:1600px){
   
    .children{
     > table{    
      >tbody{
        > tr{  
         
          > .tbody-item{
            width: 100%;
            > .item-text-container{
              width: 100%;
             
            }
          }
        }
      }
     }
    }
  }
`
