import { Copy, Edit2, MoreVertical, Trash2 } from 'lucide-react'
import { useState } from 'react'
import Switch from 'react-switch'
import ComplementsSideModal from '../../complementsModal/ComplementsModal'

type familyItemProps = {
  FamilyItemObj: any
  fatherType: string
  handleDuplicateComplementItem: (complementInfo: any) => void
  handleDeleteComplementItem: (complementInfo: any) => void
  handleChangeComplementItemStatus: (
    complementInfo: any,
    status: string
  ) => void
  handleGetComplementosItems: () => void
  complementos: any
}

function FamilyItem({
  FamilyItemObj,
  fatherType,
  handleDuplicateComplementItem,
  handleDeleteComplementItem,
  handleChangeComplementItemStatus,
  handleGetComplementosItems,
  complementos
}: familyItemProps) {
  const [itemActiveActions, setItemActiveActions] = useState<boolean>(false)
  const [editFamilySub, setEditFamilySub] = useState<boolean>(false)

  return (
    <>
      <tr key={FamilyItemObj.Codigo}>
        <td>{FamilyItemObj.Nome}</td>
        {FamilyItemObj.Tipo === 'Massas' ? (
          <td>
            <div>
              <Switch
                onChange={() => {}}
                checked={FamilyItemObj.acceptBorder}
                offColor="#dc0000"
              />
            </div>
          </td>
        ) : (
          ''
        )}
        <td>{FamilyItemObj.Tipo}</td>

        <td className="detail-td">
          <div className="detail-td">
            <button
              className={!FamilyItemObj.Ativo ? 'pause pause-active' : 'pause'}
              onClick={() =>
                handleChangeComplementItemStatus(FamilyItemObj, 'Pausar')
              }
              disabled={!FamilyItemObj.Ativo}
            >
              {!FamilyItemObj.Ativo ? 'Pausado' : 'Pausar'}
            </button>
            <button
              className={
                FamilyItemObj.Ativo ? 'actived actived-active' : 'actived'
              }
              onClick={() =>
                handleChangeComplementItemStatus(FamilyItemObj, 'Ativar')
              }
              disabled={FamilyItemObj.Ativo}
            >
              {FamilyItemObj.Ativo === true ? 'Ativado' : 'Ativar'}
            </button>
            <div className="dropdownCardapio">
              <button onClick={() => setItemActiveActions(!itemActiveActions)}>
                <MoreVertical color="#f3f4f8" />
                {itemActiveActions && (
                  <div className="dropdown-content">
                    <button onClick={() => setEditFamilySub(true)}>
                      <Edit2 />
                      <abbr>Editar Item</abbr>
                    </button>
                    <button
                      onClick={() => handleDeleteComplementItem(FamilyItemObj)}
                    >
                      <Trash2 />
                      <abbr>Excluir Item</abbr>
                    </button>
                    <button
                      onClick={() =>
                        handleDuplicateComplementItem(FamilyItemObj)
                      }
                    >
                      <Copy />
                      <abbr>Duplicar Item</abbr>
                    </button>
                  </div>
                )}
              </button>
            </div>
          </div>
        </td>
      </tr>
      {editFamilySub && (
        <ComplementsSideModal
          HeaderTitle={`${FamilyItemObj.Codigo} - ${FamilyItemObj.Nome}`}
          isEditItem
          close={() => setEditFamilySub(false)}
          FamilyObject={FamilyItemObj}
          handleGetComplementosItems={handleGetComplementosItems}
          complementos={complementos}
        />
      )}
    </>
  )
}

export default FamilyItem
