
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectRequest, EnvironmentProfile, LicenseRequest } from '../../../types/auth'
import { authService } from './authService'
import { ErrorAlert } from '../../../components/SweetAlert/SweetAlert';
import { Address } from '../../../types/address';
import { FleetInfo } from '../../../types/fleet';


type GetLicenseTokenProps = {
  license: string
}

type ConnectUserProps = {
  username: string,
  password: string,
  url: string,
  accessToken: string
}

type RefreshUserProps = {
  token: string,
  url: string
}

type GetUserStores = {
  url: string,
  token: string,
  cdemp: any,
  storage: any
}

const initialState = {
  accessToken: '',
  licenseStoreCode: 0,
  licenseStoreName: '',
  licenseStorage: '',
  frotaOnline: {} as FleetInfo,
  storeList: [] as any[],
  userData: {} as ConnectRequest,
  userToken: '',
  refreshToken: '',
  environments: [] as EnvironmentProfile[],
  selectedEnvironment: {} as EnvironmentProfile,
  storeLocation: {} as Address,
  isLoading: false,
  isError: false,
  errorType: '',
  isSuccess: false,
  message: ''
}

export const getLicenseToken = createAsyncThunk(
  'auth/get-license-token',
  async (args: GetLicenseTokenProps, thunkApi) => {

    try {
      const response = await authService.getLicenseToken(args.license)

      if (response?.status !== 200) {

        // throw new Error('Erro interno')
        return ErrorAlert('Ops...', 'Erro Interno')
      }

      return response.data
    } catch (err: any) {
      const message = err?.response?.data?.Messages?.[0] || 'Código de licença inválido'

      return thunkApi.rejectWithValue(message)
      // return ErrorAlert('Atenção', message)
    }
  }
)

export const connectUser = createAsyncThunk(
  'auth/connect-user',
  async (args: ConnectUserProps, thunkApi) => {
    try {
      const response = await authService.connectUser(
        args.username,
        args.password,
        args.url,
        args.accessToken
      )


      return response?.data
    } catch (err: any) {
      const message = err?.response?.data?.Messages?.[0] || String(err)

      // return thunkApi.rejectWithValue(message)
      return ErrorAlert('Atenção', message)
    }
  }
)

export const refreshUser = createAsyncThunk(
  'auth/refresh-user',
  async (args: RefreshUserProps, thunkApi) => {
    try {
      const response = await authService.refreshUser(
        args.token,
        args.url
      )

      if (response?.status !== 200) {

        localStorage.clear();
        return ErrorAlert('Atenção', 'Não foi possível conectar na sua conta')
      }

      return response.data
    } catch (err: any) {
      const message = err?.response?.data?.Messages?.[0] || ''

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const getStores = createAsyncThunk(
  'auth/get-stores',
  async (args: GetUserStores, thunkApi) => {

    try {
      const response = await authService.getStores(args.token, args.url, args.cdemp, args.storage)

      return response?.data
    } catch (err: any) {
      const message = err?.response?.data?.Messages?.[0] || 'Código de licença inválido'

      return thunkApi.rejectWithValue(message)
      // return ErrorAlert('Atenção', message)
    }
  }
)

export const getFrota = createAsyncThunk(
  'auth/get-frota',
  async (args: RefreshUserProps, thunkApi) => {
    try {
      const response = await authService.getFrota(args.token, args.url)

      return response?.data
    } catch (err: any) {
      const message = err?.response?.data?.Messages?.[0]

      return thunkApi.rejectWithValue(message)
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.errorType = ''
      state.message = ''
    },
    setEnvironment: (state, action: PayloadAction<EnvironmentProfile>) => {
      state.selectedEnvironment = action.payload
    },
    clearAuth: (state) => {
      state.accessToken = ''
      state.environments = []
      state.selectedEnvironment = {} as EnvironmentProfile
    },
    disconnect: (state) => {
      state.userData = {} as ConnectRequest
      state.userToken = ''
      state.storeList = []

      localStorage.removeItem('appAgileHUBUserInfo')
    },
    defineStoreInfo: (state, action: PayloadAction<Address>) => {
      state.storeLocation = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLicenseToken.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(getLicenseToken.fulfilled, (state, action: PayloadAction<LicenseRequest>) => {
      state.isLoading = false
      state.isSuccess = true
      state.accessToken = action.payload.access_token
      state.environments = action.payload.Perfis
      state.licenseStoreCode = action.payload.CodigoExterno
      state.licenseStoreName = action.payload.NomeEmpresa
      state.licenseStorage = action.payload.Storage

    })

    builder.addCase(getLicenseToken.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = String(action.payload)
      state.errorType = 'license'

      localStorage.removeItem('appAgileHUBLicenseInfo')
    })

    builder.addCase(getFrota.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(getFrota.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.frotaOnline = action.payload
    })

    builder.addCase(getFrota.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = String(action.payload)
    })

    builder.addCase(connectUser.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(connectUser.fulfilled, (state, action: PayloadAction<ConnectRequest>) => {
      state.isLoading = false
      state.isSuccess = true
      state.refreshToken = action.payload?.refresh_token
      state.userToken = action.payload?.access_token
      state.userData = action.payload
    })

    builder.addCase(connectUser.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = String(action.payload)
    })

    builder.addCase(refreshUser.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(refreshUser.fulfilled, (state, action: PayloadAction<ConnectRequest>) => {
      state.isLoading = false
      state.isSuccess = true
      state.refreshToken = action.payload?.refresh_token
      state.userToken = action.payload?.access_token

      state.userData = action.payload
    })

    builder.addCase(refreshUser.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = String(action.payload)

      localStorage.removeItem('appAgileHUBUserInfo')
    })

    builder.addCase(getStores.pending, (state) => {
      state.isLoading = true

    })

    builder.addCase(getStores.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.storeList = action.payload
    })

    builder.addCase(getStores.rejected, (state) => {
      state.isError = true
      state.isLoading = false
    })

  }



})

export const {
  reset,
  setEnvironment,
  clearAuth,
  disconnect,
  defineStoreInfo
} = authSlice.actions

export default authSlice.reducer
