/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PageCard from '../../components/PageCard'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import Table, { rowHeaderItem } from '../../components/Table'
import { RootState } from '../../store/store'
import { ShippedOrder } from '../../types/orders'
import Filters from './components/Filters'
import { ShippedOrdersContainer } from './styles'

const tableHeadItems: string[] = [
  'Código Pedido',
  'Data',
  'Saiu p/ entrega',
  'Valor Pedido',
  'Taxa',
  'Valor Total',
  'Cliente',
  'Endereço',
  'Bairro',
  'Entregador',
  'Logistica',
  'Telefone',
  'Contatar Cliente'
]

const tableRowHeaders: rowHeaderItem[] = [
  {
    value: 'CodigoSeq'
  },
  {
    value: 'DataHora',
    format: 'date&hour'
  },
  {
    value: 'DataHoraSaiuEntrega',
    format: 'date&hour'
  },  
  {
    value: 'ValorSubTotal',
    format: 'currency'
  },
  {
    value: 'ValorTaxa',
    format: 'currency'
  },
  {
    value: 'ValorTotal',
    format: 'currency'
  },
  {
    value: 'NomeCliente'
  },

  {
    value: 'EnderecoCliente'
  },
  {
    value: 'Bairro'
  },
  {
    value: 'strNomeEntregadorLogi',
    alternateValue: 'NomeEntregador'
  },
  {
    value: 'NomeLogistica',
    alternateStringValue: 'PROPRIA'
  },
  { sub: 'DDD', value: 'Telefone', format: 'tel' },
  { sub: `DDD`, value: 'Telefone', format: 'whats' }
]

function ShippedOrders() {
  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )

  const [selectedStoreCode, setSelectedStoreCode] =
    useState<number>(licenseStoreCode)

  const [shippedOrders, setShippedOrders] = useState<ShippedOrder[]>([])

  const [clientValue, setClientValue] = useState<string>('')
  const [requestCode, setRequestCode] = useState<string>('')

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleGetShippedOrders = useCallback(async () => {
    const env = process.env.REACT_APP_SHIPPED_ORDERS

    if (!env || !selectedEnvironment?.Url || !selectedStoreCode || !userToken)
      return

    setIsLoading(true)

    const shippedOrdersData = {
      CodigoPedido: requestCode || null,
      NomeCliente: clientValue || null
    }

    try {
      const response = await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${selectedStoreCode}`,
        data: shippedOrdersData,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setShippedOrders(response.data)
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
    }
  }, [
    selectedStoreCode,
    selectedEnvironment?.Url,
    userToken,
    clientValue,
    requestCode
  ])

  useEffect(() => {
    handleGetShippedOrders()
  }, [handleGetShippedOrders])

  return (
    <PageCard title="Pedidos Expedidos">
      <ShippedOrdersContainer>
        <Filters
          clientValue={clientValue}
          handleChangeClientValue={(value: string) => setClientValue(value)}
          requestCode={requestCode}
          handleChangeRequestCode={(value: string) => setRequestCode(value)}
          action={handleGetShippedOrders}
          getStore
          setStore={(storeCode: number) => setSelectedStoreCode(storeCode)}
          selectedStore={selectedStoreCode}
        />
        <div className="table-wrapper">
          {shippedOrders.length > 0 ? (
            <Table
              items={shippedOrders}
              headItems={tableHeadItems}
              rowHeaders={tableRowHeaders}
              noIndex
            />
          ) : (
            <p>Não existem pedidos expedidos.</p>
          )}
        </div>
      </ShippedOrdersContainer>
      {isLoading && <ScaleLoader />}
    </PageCard>
  )
}

export default ShippedOrders
