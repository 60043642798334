import { Plus } from 'lucide-react'
import { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import NoItems from './NoItems'
import { RootState } from '../store/store'
import formatBRL from '../utils/formatBRL'

type PriceProps = {
  tables: any
  handleAddNewTable: (name: string, id: number) => void
  handleRemoveTable: (id: number) => void
  handleToggleStore: (storeCode: number, tableCode: number) => void
  changeTablePrice: (
    value: string,
    size: any,
    meanOfSale: any,
    tableId: any
  ) => void,
  isAddAction: boolean,
  setSelectedTableStoreList: (param: any) => void
}

function Price({
  tables,
  handleAddNewTable,
  handleRemoveTable,
  handleToggleStore,
  changeTablePrice,
  isAddAction,
  setSelectedTableStoreList
}: PriceProps) {
  const { storeList } = useSelector((state: RootState) => state.auth)

  const [selectedTableId, setSelectedTableId] = useState<any>(
    (tables?.TabelasPreco?.length > 0 ? tables?.TabelasPreco[0]?.Id : 1) || 1
  )

  useEffect(() => {
    if (tables.TabelasPreco.length > 0 || !isAddAction) return

    const currentDate = new Date().toLocaleString()

    const tableName = `TABELA ${currentDate} - HUB`
    const newId = Math.floor(Math.random() * 1000000)

    handleAddNewTable(tableName, newId)
    setSelectedTableId(newId)
  }, [handleAddNewTable, tables.TabelasPreco.length, isAddAction])

  const selectedTable = useMemo(
    () =>
      tables?.TabelasPreco?.find((table: any) => table?.Id === selectedTableId),
    [selectedTableId, tables]
  )

  useEffect(() => {
    setSelectedTableStoreList(selectedTable?.Lojas || [])
  }, [selectedTable, setSelectedTableStoreList])

  function addTable() {
    Swal.fire({
      title: 'Deseja criar uma nova tabela?',
      background: '#283046',
      color: '#f3f4f8',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Adicionar',
      cancelButtonColor: '#CF303C',
      showLoaderOnConfirm: true,
      showCloseButton: true
    }).then((result) => {
        if (!result.isConfirmed) return

        const currentDate = new Date().toLocaleString()

        const tableName = `TABELA ${currentDate} - HUB`
        const newId = Math.floor(Math.random() * 1000000)

        handleAddNewTable(tableName, newId)
        setSelectedTableId(newId)
      }
    )
  }

  return (
    <div className="container-price">
      <div className="meioVenda-container">
        <div className="table-select-container">
          <select
            className="form-control"
            value={selectedTableId}
            onChange={(e: any) => setSelectedTableId(parseInt(e.target.value))}
          >
            {tables?.TabelasPreco?.map((tabela: any, index: any) => (
              <option key={tabela.Id || index} value={tabela.Id}>
                {tabela.Nome}
              </option>
            ))}
          </select>
          <div>
            <button onClick={addTable}>
              <Plus color="#fff" />
            </button>
          </div>
          <div>
            {/* <button
              onClick={() =>
                deleteTable(selectedTable?.Nome, selectedTable?.Id)
              }
            >
              <Minus color="#fff" />
            </button> */}
          </div>
        </div>
        {tables?.TabelasPreco?.length > 0 ? (
          <>
            {selectedTable?.MeiosVenda?.map((meio: any, index1: number) => (
              <>
                <div key={index1} className="meioVenda-cards">
                  <p>{meio.Nome}</p>

                  <table>
                    <thead>
                      <tr>
                        <td>Tamanho</td>
                        <td>Preço</td>
                      </tr>
                    </thead>
                    <tbody>
                      {meio?.Tamanhos?.map((tamanho: any, index: number) => (
                        <tr key={index}>
                          <td>{tamanho.Nome}</td>
                          <td>
                            <div className="cash-input">
                              <input
                                type="text"
                                className="price-input"
                                value={formatBRL(tamanho.Preco)}
                                onChange={e =>
                                  changeTablePrice(
                                    e.target.value,
                                    tamanho.Id,
                                    meio.Nome,
                                    selectedTableId
                                  )
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ))}
          </>
        ) : (
          <NoItems title={'Sem Meios de Vendas Cadastrados'} />
        )}
      </div>

      <div className="lojas-container">
        <div className="lojas-container-header">
          <p>Selecione a Loja</p>
        </div>
        <div className="lojas-container-body thematic-scroll">
          {storeList?.map((store: any) => (
            <>
              <button
                key={store.CodigoExterno}
                onClick={() =>
                  handleToggleStore(store.CodigoExterno, selectedTableId)
                }
                className={
                  selectedTable?.Lojas?.find(
                    (curr: any) => curr.Codigo === store.CodigoExterno
                  )
                    ? 'active'
                    : ''
                }
                disabled={tables?.TabelasPreco?.length < 1}
              >
                {store.CodigoExterno} {store.Sigla} - {store.Nome}
              </button>
            </>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Price
