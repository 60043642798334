import ReactDOM from 'react-dom'
import { ReactNode } from 'react'
import Overlay from '../../Overlay'
import { SubModalContainer } from '../styles'
import { X } from 'lucide-react'
type SubModalProps = {
  title: string
  heading?: string
  children: ReactNode
  confirm: () => void
  close: () => void
  svg?: any
  noActions?: any
  big?: boolean
  ApprovebuttonTitle?: string
}

function SubModal({
  title,
  heading,
  children,
  confirm,
  close,
  svg,
  noActions,
  big,
  ApprovebuttonTitle
}: SubModalProps) {
  const subOrderPortal = document.getElementById('sub-order-portal')

  if (!subOrderPortal) return null

  return ReactDOM.createPortal(
    <Overlay>
      <SubModalContainer big={big}>
        <div className="title-modal">
          <img src={svg} alt="svg" />

          <h3>{title} </h3>
          <button type="button" onClick={close}>
            <X />
          </button>
        </div>
        {heading && (
          <div className="heading">
            <h3>{heading}</h3>
          </div>
        )}
        <div className="content">{children}</div>
        {!noActions && (
          <div className="action">
            <button type="button" onClick={confirm}>
              {ApprovebuttonTitle || 'Confirmar'}
            </button>

            <button type="button" onClick={close}>
              Sair
            </button>
          </div>
        )}
      </SubModalContainer>
    </Overlay>,
    subOrderPortal
  )
}

export default SubModal
