import { X } from 'lucide-react'
import { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import Overlay from '../Overlay'
import { OrderContainer } from './styles'
import agile from '../../assets/images/logo/icone-agile-branco.png'
import iconIfood from '../../assets/images/svg/ifood-43.svg'

type OrderProps = {
  children: ReactNode
  close: () => void
  reject?: () => void
  approve?: () => void
  actionButtons?: boolean
  ifoodIcon?: boolean
}

function Order({
  children,
  close,
  reject,
  approve,
  actionButtons,
  ifoodIcon
}: OrderProps) {
  const orderPortal = document.getElementById('order-portal')

  if (!orderPortal) return null

  return ReactDOM.createPortal(
    <Overlay>
      <OrderContainer>
        <div className="heading">
          <img src={ifoodIcon ? iconIfood : agile} alt="agile" />
          <button type="button" onClick={close}>
            <X />
          </button>
        </div>
        <div className="content custom-scrollbar">
          <pre>{children}</pre>
        </div>

        {actionButtons && reject && approve && (
          <>
            <hr />
            <div className="action">
              <button type="button" onClick={approve}>
                Aprovar
              </button>
              <button type="button" onClick={reject}>
                Rejeitar
              </button>
            </div>
          </>
        )}
      </OrderContainer>
    </Overlay>,
    orderPortal
  )
}

export default Order
