import styled from "styled-components";

export const SideModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.dark.main};
  color: ${({ theme }) => theme.colors.dark.cardText};
  width: 60%;
  height: 100%;
  z-index: 9000;
  position: absolute;
  right: 0;
  overflow: auto;
  overflow-x: hidden;

  .sidebar-header{
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;
    > h1{
      margin-left: 2rem;
      font-size: 32px;
    }
    > button{
      margin-right: 3rem;
      margin-left: auto;
    }
  }
  .addItem-tabs{
    margin-top: 1rem;
    display: flex;
    list-style: none;
    gap: 1rem;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 0.5rem;

    > button {
      color: ${({ theme }) => theme.colors.dark.cardText};
    }

    > .activeTab{
      border-bottom:2px solid #6F38C5;
    }
  }
  .sidebar-caption{
    width: 100%;
    font-size: 16px;
    display: flex;
    margin-left: 2rem;
    margin-top: 2rem;
  }

  .addCategory-Container{
    margin-top: 3rem;
    display: flex;
    gap: 2rem;
    justify-content: space-around;
    padding: 20px;
    height: 57rem;
    margin-bottom: 13rem;

    .error {
      color: #ff0000;
      font-size: 1.3rem;
    }

    >.switch{
      text-align: center;
    }
    > div{
      >label{
        font-size: 16px;
      }
      &:first-child{
        width: 75%;
      }
      &:nth-child(2){
        width: 25%;
      }
    }
  }

  .save-container{
    display: flex;
    gap: 1rem;
    width: 100%;
    background: #383f54;
    height: 80px;
    justify-content: flex-end;
    

    > button{
      margin-top: 1.5rem;
      color: #fff;
      margin-right: 1rem;
      border: 1px solid #fff;
      padding: 16px 24px;
      height: 48px;
      font-size: 18px;
      border-radius: 1rem;
     
      &:first-child{
        background: transparent;
        &:hover{
          opacity: 0.7;
        }
      }

      &:last-child{
        background: #EB1D36;
        &:hover{
          opacity: 0.7;
        }
      }

    }
  }

  .container-price{
    display: flex;
    gap: 2rem;
    .meioVenda-container{
      width: 60%;
    padding: 20px;
    
    .table-select-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: center;
      align-content:center;
      > select{
        width: 100%;
      }

      > div{
        button{
          border-radius: 0.7rem;
          margin-bottom: 1rem;
          padding: 10px;
          
          &:first-child{
            background: #cf303c;

          }
         

            &:hover {
          transform: translateY(-2px);}

            &:active {
          box-shadow: none;
          transform: translateY(0);
        
    }
        }
        
      }
    }
    > .meioVenda-cards{
    
    padding: 20px;
    border-radius: 0.7rem;
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;

    
    > p{
      box-shadow: 0px 1px 10px #000;
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      background-color: #6F38C5;
      font-size: 16px;
      text-align: center;
      
    }
    >table{
      background: ${({ theme }) => theme.colors.dark.main};
      border-collapse: collapse;
      width: 100%;
      > thead{
        >tr{
          height: 3rem;
          > td{
            width: 50%;
            border: 1px solid #fff;
          }
        }
      }
      > tbody{
        > tr{
        
          > td{
            width: 50%;
            border: 1px solid #fff;
           
            > .cash-input{
              display: flex;
              color: #fff;
              justify-content: center;
              align-items: center;
              > .price-input{
                
                color: #000;
              background-color: #F9F9F9;
              font-size: 12px;
              width: 40%;
              
              text-align: center;
              
            }
            }
            
          }
        }
      }
    }
    
  }
  }
  .lojas-container{
    width: 40%;
    max-height: 630px;
    background: ${({ theme }) => theme.colors.dark.main};
    margin-right: 2rem;
    margin-top: 2rem;
    border-radius: 0.7rem;
    border:1px solid #937DC2;
    margin-bottom: 5rem;
    > .lojas-container-header{
      > p{
        
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      background-color: #6F38C5;
      font-size: 16px;
      text-align: center;
      }
    }

    > .lojas-container-body{
      display: flex;
      flex-direction: column;
      max-height: 600px;
      overflow: auto;
      
      .active{
        background: #7367F0;
      }
      > button{
        margin-top: 1rem;
       
        color:#fff;
        width: 100%;
        text-align: center;
        padding: 3px;
        
        &:hover{
          background: #7367F0;
        }
      }
    }
  }
  }
 
  
  .addItem-container{
    display: flex;
    padding: 20px;
    gap: 3rem;
    
    > div{
      font-size: 16px; 
    }

    > .addItem-form{
      width: 100%;

      .error {
        color: #ff0000;
        font-size: 1.3rem;

        margin: -.4rem 0 .4rem;
      }

      > .switch-container{
        display: flex;
        justify-content: space-around;
        
        >div{
          display: flex;
          flex-direction: column;
          align-items: center;

          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }

    >.addItem-img{
      width: 100%;

      > .crud-addimg{
        display: flex;
        > button{
          width: 50%;
        background-color: #EB1D36;
        color: #FFF;
        text-transform: uppercase;
        text-align: center;
        display: block;
        margin-top: 10px;
        cursor: pointer;

        &:disabled{
          opacity: 0.2;
          cursor: not-allowed;
        }
        }
        > label {
        width: 50%;
        background-color: #7367F0;
        text-align: center;
        display: block;
        margin-top: 10px;
        cursor: pointer;
}
        > input[type="file"] {
          display: none;
      }

      }
      >.img-container{
        
        border-radius: 0.6rem;
      height: 250px;
      border: 1px dashed #fff;
      display: flex;

      > img{
        width: 100%;
        height: 248px;
      }
      }
     
    }
    
  }

  > .addItem-description{
      padding: 20px;
      width: 100%;
      margin-bottom: 9rem;

      > label{
        font-size: 16px; 
      }

      textarea {
        caret-color: initial;
      }
    }
 
    .container-ingredient{
      padding: 20px;
      display: flex;
      flex-direction: column;
      
     
      > .ingredient-select{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;
/* 
        .form-control {
          padding: 0;
        } */

       
        
       > button{
          border-radius: 0.7rem;
          margin-bottom: 1rem;
          padding: 10px;
          background: #cf303c;
          
            &:hover {
          transform: translateY(-2px);}

            &:active {
          box-shadow: none;
          transform: translateY(0);
        
    }
        }
      }

      .ingredient-cards{
        padding: 20px;
    border-radius: 0.7rem;
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;

    .error-ingredient{
      margin-bottom: 37rem;
    }
    > p{
      box-shadow: 0px 1px 10px #000;
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      background-color: #6F38C5;
      font-size: 16px;
      text-align: center;
      
    }
    .ingredient-table{
      overflow: auto;
      height: 60rem;
      
      >table{
      background: ${({ theme }) => theme.colors.dark.main};
      border-collapse: collapse;
      width: 100%;
      
      > thead{
        >tr{
          > #name{
            width: 60%
          }
          > #seq{
            width: 10%
              }
          > #required{
            width: 10%
            }
            > #action{
              width: 10%
            }
          > td{
            padding: 10px;
            border: 1px solid #fff;
          }
        }
      }
      > tbody{
        > tr{
        
          > td{
             width: auto;
            border: 1px solid #fff;
            padding: 6px;

            > .sequence-container{
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              > input{
                width: 100%;
                padding: .2rem;
               border-radius: .8rem;
               text-align: center;
               &:active{
                border: 1px solid lightblue
               }
              }
            }
            > .ingredients-actions{
              display: flex;
              color: #000;
              justify-content: center;
              gap: 1rem;

              > button{
                padding: 6px;
                border-radius: 0.7rem;

                &:hover {
          transform: translateY(-2px);}

            &:active {
          box-shadow: none;
          transform: translateY(0);
        
    }

    &:disabled{
      opacity: 0.5;
      cursor: not-allowed;
    }
              }

              > .edit{
                background: #7367F0;
              }
              > .remove{
                background: #cf303c;
              }
            }
            
          }
        }
      }
    }
    }
    
      }
    }

    .container-technicalFile{
      margin-bottom: 10rem;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 1rem;

      > .noItems{
        margin-bottom: 28.5rem;
      }
      > .technical-crud-card{
        background: #4C6793;
        
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;

        > button{
          height: 40px;
          padding: 10px;
          border-radius: 0.8rem;
          color:${({ theme }) => theme.colors.dark.cardText};
          margin-bottom: 0.5rem;
          background-color: #EB1D36;

            &:hover {
            transform: translateY(-2px);
            }
            &:active {
            box-shadow: none;
            transform: translateY(0);
            }
        
        }
        
      }

      > .technical-crud-table{
        width: 100%;

        > table{ 
          width: 100%;
          display: table;
          border-collapse: collapse;
          text-align: center;
              > thead{
            >tr{
              height: 3rem;
              > td{
                width: 100%;
                border: 1px solid #fff;
                padding: 10px;
              }

              #name{
                width: 100%;
              }
            }
          }
          > tbody{
            > tr{
              height: 3rem;
              &:hover{
                background: #554994;
              }
              > td{
                width: 50%;
                border: 1px solid #fff;
                padding: 10px;

                > .crud-actions{
                  display: flex;
                  gap: 1rem;
                  > button{
                    height: 40px;
                    padding: 10px;
                    border-radius: 0.8rem;
                    color:${({ theme }) => theme.colors.dark.cardText};
                    margin-bottom: 0.5rem;
                    

                      &:hover {
                      transform: translateY(-2px);
                      }
                      &:active {
                      box-shadow: none;
                      transform: translateY(0);
                      }
                  }

                  > .edit-item{
                    background: #7367F0;
                  }

                  > .delete-item{
                    background: #EB1D36;
                  }
                }
              }
            }
          }
        }
      }
      > .technical-card{
        background: #4C6793;
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 1rem;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

        > div{
         width: 100%;
         > label{
          font-size: 16px;
         }
        }

        > .technical-clone-btn{
          margin-top: 1.5rem;
                    height: 40px;
                    padding: 10px;
                    border-radius: 0.8rem;
                    color:${({ theme }) => theme.colors.dark.cardText};
                    margin-bottom: 0.5rem;
                    background-color: #EB1D36;

                    &:hover {
                      transform: translateY(-2px);}
                    &:active {
                      box-shadow: none;
                      transform: translateY(0);
                  
                }
                &:disabled{
              opacity: 0.5;
              cursor: not-allowed;
            }
          
        }
    }
    }
@media(max-width: 800px){
  width: 100%;
  height: 100%;

  .save-container{
    margin-top: 1rem;
    width: 100%;  
  }
}

@media(min-width: 1390px){
  width: 50%;
  height: 100%;
}
@media(min-width: 1690px){
  width: 40%;
  height: 100%;
}
`