import { useCallback, useState } from 'react'
import { Search as SearchIcon, Sheet } from 'lucide-react'
import { CSVLink } from 'react-csv'

import DateSelector from '../../components/DateSelector'
import PageCard from '../../components/PageCard'
import useDateInterval from '../../hooks/useDateInterval'
import { SearchContainer } from './styles'
import Filters from './components/Filters'
import Table, { rowHeaderItem } from '../../components/Table'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import Order from '../../components/Order'
import { ChevronUp } from 'lucide-react'
import NoItems from '../../components/NoItems'

const tableHeadItems: string[] = [
  'Item',
  'Negócio',
  'Origem',
  'Data Pedido',
  'Pedido',
  'Cliente',

  'Valor',
  'Pedido Dia',
  'Ped Tractio',
  'Meio Venda',
  'Motivo Rejeição',
  'Status Aprovação Automatica',
  'Status Pedido',
  'Telefone',
  'Contatar Cliente'
]

const tableRowHeaders: rowHeaderItem[] = [
  {
    value: 'Negocio'
  },
  {
    value: 'Origem'
  },
  {
    value: 'DataPedido',
    format: 'date'
  },
  {
    value: 'PedidoWEB'
  },
  {
    value: 'NomeCliente'
  },

  {
    value: 'Valor',
    format: 'currency'
  },
  {
    value: 'PedidoDia'
  },
  {
    value: 'PedidoSEQ'
  },
  {
    value: 'MeioVenda'
  },
  {
    value: 'SituacaoAprovacao'
  },
  {
    value: 'AprovadoAutomaticamente'
  },
  {
    value: 'Operacao'
  },
  { sub: 'DDD', value: 'TELEFONE', format: 'tel' },
  { sub: `DDD`, value: 'TELEFONE', format: 'whats' }
]

function Search() {
  const { userToken, selectedEnvironment, licenseStoreCode } = useSelector(
    (state: RootState) => state.auth
  )

  const [selectedStoreCode, setSelectedStoreCode] =
    useState<number>(licenseStoreCode)

  const [data, setData] = useState<[]>([])

  const [activeFilters, setActiveFilters] = useState<string[]>([
    'ecommerce',
    'app',
    'ifood',
    'pedzap',
    'agile',
    'cardapio'
  ])
  const [activeUser, setActiveUser] = useState<string>('')

  const [modalInfo, setModalInfo] = useState<string>('')
  const [currentModalInfoCode, setCurrentModalInfoCode] = useState<number>(0)
  const [hideFilter, setHideFilter] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [
    initialDateValue,
    setInitialDateValue,
    finalDateValue,
    setFinalDateValue
  ] = useDateInterval()

  const handleSearch = useCallback(async () => {
    setIsLoading(true)
    const env = process.env.REACT_APP_RECEIVED_REQUEST

    if (!userToken || !env || !selectedEnvironment.Url || !licenseStoreCode)
      return

    try {
      // status
      const rejectedStatus = activeFilters.find(
        filter => filter === 'REJEITADOS'
      )
      const awaitingStatus = activeFilters.find(
        filter => filter === 'AGUARDANDO'
      )
      const approvedStatus = activeFilters.find(
        filter => filter === 'APROVADOS'
      )

      // tipo
      const automaticType = activeFilters.find(
        filter => filter === 'AUTOMATICO'
      )
      const manualType = activeFilters.find(filter => filter === 'MANUAL')

      // pagamento
      const onlinePayment = activeFilters.find(filter => filter === 'ONLINE')
      const offlinePayment = activeFilters.find(filter => filter === 'OFFLINE')

      const searchData = {
        NomeCliente: activeUser || null,
        DataInicial: initialDateValue,
        DataFinal: finalDateValue,
        Status: approvedStatus || awaitingStatus || rejectedStatus || 'TODOS',
        Tipo: automaticType || manualType || 'TODOS',
        Pagamento: onlinePayment || offlinePayment || 'TODOS',
        Origem: {
          // Ecommerce: !!activeFilters.find(filter => filter === 'ecommerce'),
          // AppVenda: !!activeFilters.find(filter => filter === 'app'),
          // iFood: !!activeFilters.find(filter => filter === 'ifood'),
          // PedZap: !!activeFilters.find(filter => filter === 'pedzap'),
          Ecommerce: true,
          AppVenda: true,
          iFood: true,
          PedZap: true,
          Agile: !!activeFilters.find(filter => filter === 'agile'),
          CardapioDigital: !!activeFilters.find(filter => filter === 'cardapio')
        }
      }

      const response = await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${selectedStoreCode}`,
        data: searchData,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setData(response.data)
      setIsLoading(false)
    } catch (err: any) {
      setData([])
      setIsLoading(false)
    }
  }, [
    activeUser,
    finalDateValue,
    initialDateValue,
    userToken,
    licenseStoreCode,
    selectedEnvironment.Url,
    activeFilters,
    selectedStoreCode
  ])

  async function gatherModalInfo(value: string) {
    const env = process.env.REACT_APP_ORDER_RECEIPT

    if (!env || !userToken || !selectedEnvironment.Url) return

    setCurrentModalInfoCode(Number(value))
    setIsLoading(true)

    const response = await axios({
      method: 'GET',
      url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${value}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    setIsLoading(false)

    setModalInfo(response.data)
  }

  function handleToggleUser(selectedUser: string) {
    setActiveUser(selectedUser)
  }

  function handleToggleCheckboxFilter(selectedFilter: string) {
    setActiveFilters(prevState => {
      const currentFilter = prevState.find(filter => filter === selectedFilter)

      if (currentFilter) {
        return prevState.filter(filter => filter !== selectedFilter)
      } else {
        return [...prevState, selectedFilter]
      }
    })
  }

  function handleToggleSelectFilter(selectedFilter: string, options: string[]) {
    setActiveFilters(prevState => {
      let newValue = prevState

      newValue = newValue.filter(filter => !options.includes(filter))

      return [...newValue, selectedFilter]
    })
  }

  function handleCloseModal() {
    setModalInfo('')
    setCurrentModalInfoCode(0)
  }

  return (
    <PageCard title="Consulta">
      <SearchContainer>
        <DateSelector
          initialDateValue={initialDateValue}
          setInitialDateValue={setInitialDateValue}
          finalDateValue={finalDateValue}
          setFinalDateValue={setFinalDateValue}
          setStore={(storeCode: number) => setSelectedStoreCode(storeCode)}
          selectedStore={selectedStoreCode}
        >
          <>
            {data.length > 0 && (
              <CSVLink
                className="search-button"
                data={data}
                //Mudar dado aqui//
                filename={`AgileRelatorio-${initialDateValue}-ate-${finalDateValue}.csv`}
              >
                <Sheet color="#f3f4f8" />
              </CSVLink>
            )}
            <button
              type="button"
              className="search-button"
              onClick={handleSearch}
            >
              <SearchIcon color="#f3f4f8" />
            </button>
          </>
        </DateSelector>
        <div className="Filter-Collapse">
          <p>
            {hideFilter === true
              ? 'Esconder Filtros de Pesquisa'
              : 'Mostrar Filtros de Pesquisa'}
          </p>
          <button
            onClick={() => setHideFilter(!hideFilter)}
            className={
              hideFilter === true ? 'chev-rotation' : 'chev-rotation-active'
            }
          >
            <ChevronUp color="#f3f4f8" />
          </button>
        </div>
        {hideFilter === true && (
          <Filters
            handleToggleCheckboxFilter={handleToggleCheckboxFilter}
            handleToggleSelectFilter={handleToggleSelectFilter}
            handleToggleUser={handleToggleUser}
            currentUser={activeUser}
            activeFilters={activeFilters}
          />
        )}

        {data.length > 0 ? (
          <div
            className={
              hideFilter === true ? 'Table-Container-active' : 'Table-Container'
            }
          >
            <Table
              items={data}
              headItems={tableHeadItems}
              rowHeaders={tableRowHeaders}
              noIndex={false}
              noCanal={true}
              infoProperty="PedidoWEB"
              action={gatherModalInfo}
              currentModalInfoCode={currentModalInfoCode}
            />
          </div>
        ) : (
          <NoItems title={'Nenhum Item para Exibir.'} mt={'2rem'} />
        )}
      </SearchContainer>

      {isLoading && <ScaleLoader />}

      {modalInfo && <Order close={handleCloseModal}>{modalInfo}</Order>}
    </PageCard>
  )
}

export default Search
