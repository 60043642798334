import { Search } from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { UserStore } from '../../../types/user'
import { FiltersContainer } from '../styles'

type FiltersProps = {
  clientValue: string
  handleChangeClientValue: (client: string) => void
  requestCode: string
  handleChangeRequestCode: (requestCode: string) => void
  action: () => void
  setStore?: (storeCode: number) => void
  getStore?: boolean
  selectedStore?: number
}

function Filters({
  clientValue,
  handleChangeClientValue,
  requestCode,
  handleChangeRequestCode,
  action,
  setStore,
  getStore,
  selectedStore
}: FiltersProps) {
  const { storeList } =
    useSelector((state: RootState) => state.auth)

  // const [stores, setStores] = useState<[]>()

  function handleStoreChange(storeCode: string) {
    if (!setStore) return

    setStore(Number(storeCode))
  }

  return (
    <FiltersContainer>
      <div className="left-fragment">
        <div className="filter-field">
          <label>
            <span>Nome do cliente</span>
            <input
              type="text"
              className="form-control"
              value={clientValue}
              onChange={e => handleChangeClientValue(e.target.value)}
            />
          </label>
        </div>
        <div className="filter-field">
          <label>
            <span>Código do pedido</span>
            <input
              type="text"
              className="form-control"
              value={requestCode}
              onChange={e => handleChangeRequestCode(e.target.value)}
            />
          </label>
        </div>
        {storeList && storeList.length > 0 && (
          <div className="filter-field">
            <span>Unidades</span>
            <select
              className="form-control"
              value={selectedStore}
              onChange={e => handleStoreChange(e.target.value)}
            >
              {storeList.map((store: UserStore) => (
                <option value={store.CodigoExterno} key={store.CodigoExterno}>
                  {store.CodigoExterno} - {store.Nome}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="right-fragment">
        <button type="button" onClick={action}>
          <Search />
        </button>
      </div>
    </FiltersContainer>
  )
}

export default Filters
