import filtersData from './data/filtersData'
import { FiltersContainer } from '../../styles'
import FilterGroup from './FilterGroup'

type FiltersProps = {
  handleToggleCheckboxFilter: (filter: string) => void
  handleToggleSelectFilter: (filter: string, options: string[]) => void
  handleToggleUser: (user: string) => void
  currentUser: string
  activeFilters: string[]
}

function Filters({
  handleToggleCheckboxFilter,
  handleToggleSelectFilter,
  handleToggleUser,
  currentUser,
  activeFilters
}: FiltersProps) {
  return (
    <FiltersContainer>
      {filtersData.map(filterData => (
        <FilterGroup
          key={filterData.id}
          filter={filterData}
          handleToggleCheckboxFilter={handleToggleCheckboxFilter}
          handleToggleSelectFilter={handleToggleSelectFilter}
          handleToggleUser={handleToggleUser}
          currentUser={currentUser}
          activeFilters={activeFilters}
        />
      ))}
    </FiltersContainer>
  )
}

export default Filters
