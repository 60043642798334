import styled from 'styled-components';

type SidebarListItemProps = {
  dropdownMenuSize: number
}

export const SidebarContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary.normal};
  border-right: 1px solid ${({ theme }) => theme.colors.card.border}; 

  height: 100vh;
  width: 250px;
  min-width: 250px;

  padding: 1.6rem;

  z-index: 1000;

  overflow-x: hidden;
  overflow-y: auto;

  .info-card-container{
    width: 100%;
    height: 100px;
    display: flex;
    color:${({ theme }) => theme.colors.dark.text};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1rem 0 1rem 0;
   
    
    >.environment-description{
      width: 100%;
      font-size: 10px;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
    > h3{
      font-weight: bold;
      font-size: 14px
    }
  }
  .status-store-card{
      color:${({ theme }) => theme.colors.dark.cardText};
      gap: 2rem;
      margin-bottom: 1.5rem;
      padding: 1rem;
      display: flex; 
      width: 100%;
      background-color: #90B77D;
      height: 7rem;
      border-radius: 0.375rem;
      align-items: center;
      
     .store-status{
      margin-left: 3.7rem;
      margin-top: 1rem;
     }
    }

    .status-store-offline{
      color:${({ theme }) => theme.colors.dark.cardText};
      gap: 2rem;
      margin-bottom: 1.5rem;
      padding: 1.3rem;
      display: flex; 
      width: 100%;
      background-color: #cf303c;
      height: 6.5rem;
      border-radius: 0.5rem;
      align-items: center;
      
     .store-status{
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
     }
    }
    .Open-Store{
      width: 100%;
      height: 40px;
      border-radius: 1.3rem;
      background-color: #cf303c;
      display: flex;
      justify-content: center;
      
      
      > button{
        display: flex;
        color:${({ theme }) => theme.colors.dark.cardText};
        width: 100%;
        
        > svg{
          
          display: none;
        }
        &:hover{
          opacity: 0.8;
          > svg {
            display: block;
          }
        }
      }

    }

    .Update-Menu{
      width: 100%;
      height: 40px;
      border-radius: 1.3rem;
      background-color: #7978FF;
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      > button{
        display: flex;
        color:${({ theme }) => theme.colors.dark.cardText};
        width: 100%;
        
        > svg{
          display: none;
        }
        &:hover{
          opacity: 0.8;
          > svg {
            display: block;
          }
        }
        &:disabled{
          opacity: 0.1;
        }
      }

      > .isSync{
        cursor: not-allowed;
        &:hover{
          
          > svg {
            display: none;
          }
        }
        &:disabled{
          opacity: 0.5;
        }
        &::after {
        content: "";
        width: 16px;
        height: 16px;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
} 
      }
    }

    @keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    button {
      color: ${({ theme }) => theme.colors.dark.text};
    }

    @media (min-width: 969px) {
      justify-content: center;

      button {
        display: none;
      }
    }
  }

  .logo-wrapper {
    width: 50%;

    img {
      width: 100%;
    }
  }

  @media (max-width: 968px) {
    position: absolute;
    top: 0;
    left: 0;

    margin-left: -100%;

    transition: margin-left .32s ease;

    &.open {
      margin-left: 0;
    }
  }
`

export const SidebarListItem = styled.li<SidebarListItemProps>`
  border-radius: .4rem;
  
  margin: 1.4rem 0;
  
  height: 4rem;

  overflow-y: hidden;
  transition: height .24s ease-in;

  .isSub{
    padding-left: 2rem;
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${({ theme }) => theme.colors.dark.text};

    padding: 0 .8rem;
    width: 100%;
    height: 4rem;

    transition: padding-left .1s ease-in;

    > div {
      display: flex;
      align-items: center;

      & ~ svg {
        transition: transform .24s;
      }

      p {
        font-size: 1.4rem;
        margin-left: 1rem;
      }
    }

    p {
      cursor: pointer;
    }

    &:hover {
      padding-left: 1.2rem;
    }
  }

  &.active {
    background: ${({ theme }) => theme.colors.menu.active};
  }

  &.dropdown-menu-open {
    height: ${({ dropdownMenuSize }) => `${dropdownMenuSize + 1.2}rem`};

    > button {
      > svg {
        transform: rotate(180deg);
      }
    }
  }
`

