import { X } from 'lucide-react'
import { useState, useEffect, useMemo, useCallback } from 'react'
import ReactDOM from 'react-dom'
import Overlay from '../../../../components/Overlay'
import Price from '../../../../components/Price'
import { ComplementsModalContainer } from './styles'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import ScaleLoader from '../../../../components/ScaleLoader/ScaleLoader'
import useFormError from '../../../../hooks/useFormError'
import {
  ErrorAlert,
  SuccessAlert
} from '../../../../components/SweetAlert/SweetAlert'
import { numberMask } from '../../../../utils/masks'
import { validateEmptyField } from '../../../../utils/fieldValidations'

type ComplementsSideModalProps = {
  HeaderTitle: string
  close: () => void
  isAddItem?: boolean
  isEditItem?: boolean
  FamilyObject?: any
  isAddGroup?: boolean
  isEditGroup?: boolean
  fatherCode?: number
  handleGetComplementosItems: () => void
  complementos: any
}

const ItemTabs = [
  { id: 10, name: 'Detalhes', tab: 'detail', caption: 'Detalhes do Item' },

  {
    id: 30,
    name: 'Preço',
    tab: 'price',
    caption: 'Configuração de valores dos tamanhos para cada Meio de Venda'
  }
]

const baseTables = {
  Ativo: true,
  Nome: '',
  TabelasPreco: [],
  Tipo: ''
}

function ComplementsSideModal({
  HeaderTitle,
  close,
  FamilyObject,
  isAddItem,
  isEditItem,
  isAddGroup,
  isEditGroup,
  fatherCode,
  handleGetComplementosItems,
  complementos
}: ComplementsSideModalProps) {
  const { userToken, selectedEnvironment, licenseStoreCode, storeList } =
    useSelector((state: RootState) => state.auth)

  const [selectedTab, setSelectedTab] = useState<
    'detail' | 'ingredient' | 'price' | 'complements'
  >('detail')

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [tables, setTables] = useState<any>(baseTables)

  const isEdit = useMemo(
    () => Boolean(isEditGroup || isEditItem),
    [isEditGroup, isEditItem]
  )

  const [defaultTable, setDefaultTable] = useState<any>()

  const [code] = useState<string>(
    isEdit ? FamilyObject?.Codigo || FamilyObject?.CodigoCategoria : ''
  )

  const [description, setDescription] = useState<string>(
    isEdit ? FamilyObject?.DescricaoCategoria || FamilyObject.Nome : ''
  )
  const [complementType, setComplementType] = useState<string>(
    isEdit ? FamilyObject?.Tipo : ''
  )
  const [complementTypes, setComplementsTypes] = useState([])

  const [categoryCode, setCategoryCode] = useState<any>(
    isEdit ? FamilyObject?.CodigoCategoria : fatherCode || 0
  )

  const [selectedTableStoreList, setSelectedTableStoreList] = useState<any>([])
  const [isThereAnySelectedStores, setIsThereAnySelectedStores] = useState<boolean>(false)

  const getComplementItemTypes = useCallback(async () => {
    const env = process.env.REACT_APP_CARDAPIO_TIPOCOMPLEMENTO_GET

    if (!env || !selectedEnvironment.Url || !userToken || !licenseStoreCode)
      return

    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setComplementsTypes(response.data)
    } finally {
      setIsLoading(false)
    }
  }, [licenseStoreCode, selectedEnvironment?.Url, userToken])

  useEffect(() => {
    if (selectedTableStoreList.length === 0) {
      setIsThereAnySelectedStores(false)
      return
    }

    setIsThereAnySelectedStores(true)
  }, [selectedTableStoreList])

  function handleChangeTablePrice(
    value: string,
    sizeId: number,
    meanOfSaleName: string,
    tableId: number
  ) {
    setTables((prevState: any) => ({
      ...prevState,
      TabelasPreco: prevState.TabelasPreco.map((priceTable: any) =>
        priceTable.Id === tableId
          ? {
              ...priceTable,
              MeiosVenda: priceTable.MeiosVenda.map((meanOfSale: any) =>
                meanOfSale.Nome === meanOfSaleName
                  ? {
                      ...meanOfSale,
                      Tamanhos: meanOfSale.Tamanhos.map((size: any) =>
                        size.Id === sizeId
                          ? {
                              ...size,
                              Preco: Number(numberMask(value)) / 100
                            }
                          : size
                      )
                    }
                  : meanOfSale
              )
            }
          : priceTable
      )
    }))
  }

  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] = useFormError()

  function handleChangeTab(
    tab: 'detail' | 'ingredient' | 'price' | 'complements'
  ) {
    setSelectedTab(tab)
  }

  function handleToggleStore(storeCode: number, tableCode: number) {
    const selectedStore = storeList.find(
      (store: any) => store.CodigoExterno === storeCode
    )

    if (!selectedStore) return

    setTables((prevState: any) => ({
      ...prevState,
      TabelasPreco: prevState.TabelasPreco.map((priceTable: any) =>
        priceTable.Id === tableCode
          ? {
              ...priceTable,
              Lojas: priceTable?.Lojas?.find(
                (store: any) => store.Codigo === storeCode
              )
                ? priceTable?.Lojas?.filter(
                    (store: any) => store.Codigo !== storeCode
                  )
                : [
                    ...priceTable?.Lojas,
                    {
                      Codigo: selectedStore.CodigoExterno,
                      Nome: selectedStore.Nome
                    }
                  ]
            }
          : priceTable
      )
    }))
  }

  function handleAddNewTable(name: string, id: number) {
    if (!defaultTable || !name || !id) return

    setTables((prevState: any) => ({
      ...prevState,
      TabelasPreco: [
        ...prevState.TabelasPreco,
        {
          ...defaultTable,
          Nome: name,
          Id: id,
          Lojas: []
        }
      ]
    }))
  }

  function handleRemoveTable(id: number) {
    if (!id) return

    setTables((prevState: any) => ({
      ...prevState,
      TabelasPreco: prevState.TabelasPreco.filter(
        (table: any) => table?.Id !== id
      )
    }))
  }

  const getDefaultTable = useCallback(async () => {
    const env = process.env.REACT_APP_CARDAPIO_PRICE_DEFAULT_TABLE

    if (!env || !selectedEnvironment.Url || !userToken || !licenseStoreCode)
      return

    const response = await axios({
      method: 'GET',
      url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    setDefaultTable(response.data)
  }, [licenseStoreCode, selectedEnvironment?.Url, userToken])

  // const validateFields = useCallback(() => {
  //   const isCodeFieldValid = validateEmptyField(
  //     code,
  //     'code',
  //     'O campo Código é obrigatório',
  //     setNewError,
  //     cleanErrorsByFieldname
  //   )

  //   const isDescriptionFieldValid = validateEmptyField(
  //     description,
  //     'description',
  //     'O campo Descrição é obrigatório',
  //     setNewError,
  //     cleanErrorsByFieldname
  //   )

  //   const isComplementTypeFieldValid = validateEmptyField(
  //     complementType,
  //     'complementType',
  //     'O campo Tipo de Complemento é obrigatório',
  //     setNewError,
  //     cleanErrorsByFieldname
  //   )

  //   return (
  //     isCodeFieldValid && isDescriptionFieldValid && isComplementTypeFieldValid
  //   )
  // }, [cleanErrorsByFieldname, setNewError, code, complementType, description])

  const validateItemFields = useCallback(() => {
    const isDescriptionValid = validateEmptyField(
      description,
      'description',
      'O campo Descrição é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isCategoryValid = validateEmptyField(
      String(categoryCode),
      'categoryCode',
      'O campo Categoria é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isComplementTypeValid = validateEmptyField(
      complementType,
      'complementType',
      'O campo Tipo de Complemento é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    if (!isDescriptionValid || !isCategoryValid || !isComplementTypeValid) {
      setSelectedTab('detail')
      return false
    }

    if (!isThereAnySelectedStores) {
      ErrorAlert('Erro', 'Você precisa selecionar pelo menos uma loja na tabela de preços')
      setSelectedTab('price')
      return false
    }

    return true
  }, [description, categoryCode, complementType, isThereAnySelectedStores, cleanErrorsByFieldname, setNewError])

  const validateFamilyFields = useCallback(() => { 
    const isDescriptionValid = validateEmptyField(
      description,
      'description',
      'O campo Descrição é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    return isDescriptionValid
  }, [description, setNewError, cleanErrorsByFieldname])

  //funciona//
  const handleEditComplementFamily = useCallback(async () => {
    if (!validateFamilyFields()) return

    const env = process.env.REACT_APP_COMPLEMENT_CHANGE_FAMILY

    if (!env || !selectedEnvironment.Url || !userToken || !licenseStoreCode)
      return

    setIsLoading(true)

    const reqBody = {
      CodigoCategoria: code,
      DescricaoCategoria: description,
      Tipo: complementType,
      Ativo: true
    }

    try {
      await axios({
        method: 'PUT',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${FamilyObject?.CodigoCategoria}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        data: reqBody
      })
      close()
      SuccessAlert(
        'Sucesso',
        `Familia de Complementos ${code} - ${description} editada com Sucesso.`
      )

      handleGetComplementosItems()
    } finally {
      setIsLoading(false)
    }
  }, [
    FamilyObject?.CodigoCategoria,
    close,
    code,
    complementType,
    description,
    handleGetComplementosItems,
    licenseStoreCode,
    selectedEnvironment.Url,
    userToken,
    validateFamilyFields
  ])
  //funciona//
  const handleAddNewComplementFamily = useCallback(async () => {
    if (!validateFamilyFields()) return

    const env = process.env.REACT_APP_COMPLEMENT_CHANGE_FAMILY

    if (!env || !selectedEnvironment.Url || !userToken || !licenseStoreCode)
      return

    setIsLoading(true)

    const reqBody = {
      CodigoCategoria: 0,
      DescricaoCategoria: description,

      Ativo: true
    }

    try {
      await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        data: reqBody
      })
      SuccessAlert(
        'Sucesso',
        `Familia de Complementos ${code} - ${description} adicionada com Sucesso.`
      )
      close()
      handleGetComplementosItems()
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }, [
    handleGetComplementosItems,
    close,
    code,
    description,
    licenseStoreCode,
    selectedEnvironment,
    validateFamilyFields,
    userToken
  ])

  const handleEditComplementItem = useCallback(async () => {
    if (!validateItemFields()) return

    const env = process.env.REACT_APP_COMPLEMENT_CHANGE_ITEM

    if (!env || !selectedEnvironment.Url || !userToken || !licenseStoreCode)
      return

    setIsLoading(true)

    const payload = {
      Ativo: FamilyObject?.Ativo,
      Codigo: code,
      CodigoCategoria: categoryCode,
      Nome: description,
      TabelasPreco: tables?.TabelasPreco,
      Tipo: complementType
    }
    try {
      await axios({
        method: 'PUT',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${code}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        data: payload
      })

      handleGetComplementosItems()
      close()

      SuccessAlert(
        'Sucesso',
        `Item complemento ${code} ${description} editado.`
      )
    } finally {
      setIsLoading(false)
    }
  }, [
    selectedEnvironment.Url,
    userToken,
    licenseStoreCode,
    categoryCode,
    FamilyObject?.Ativo,
    code,
    description,
    tables?.TabelasPreco,
    complementType,
    handleGetComplementosItems,
    validateItemFields,
    close
  ])

  const handleAddNewComplementItem = useCallback(async () => {
    if (!validateItemFields()) return

    const env = process.env.REACT_APP_COMPLEMENT_CHANGE_ITEM

    if (!env || !selectedEnvironment.Url || !userToken || !licenseStoreCode)
      return

    setIsLoading(true)

    const payload = {
      Ativo: true,
      Codigo: code,
      CodigoCategoria: fatherCode,
      Nome: description,
      TabelasPreco: tables?.TabelaPreco,
      Tipo: complementType
    }

    try {
      await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        data: payload
      })

      SuccessAlert(
        'Sucesso',
        `Novo item complemento ${description} adicionado.`
      )
      handleGetComplementosItems()
      close()
    } finally {
      setIsLoading(false)
    }
  }, [
    selectedEnvironment.Url,
    userToken,
    licenseStoreCode,
    code,
    tables?.TabelaPreco,
    fatherCode,
    description,
    complementType,
    handleGetComplementosItems,
    validateItemFields,
    close
  ])

  const getComplementItemTable = useCallback(async () => {
    const env = process.env.REACT_APP_COMPLEMENT_ITEM_GET

    if (!env || !selectedEnvironment.Url || !userToken || !FamilyObject?.Codigo)
      return

    setIsLoading(true)
    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${FamilyObject?.Codigo}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      setTables(response.data)
      setIsLoading(false)
    } catch (err: any) {
      ErrorAlert('Atenção', err.message)
      setIsLoading(false)
    }
  }, [
    FamilyObject?.Codigo,
    licenseStoreCode,
    selectedEnvironment?.Url,
    userToken
  ])

  useEffect(() => {
    if (!complementTypes || complementTypes.length === 0) return

    setComplementType(complementTypes[0])
  }, [complementTypes])

  useEffect(() => {
    getComplementItemTypes()
  }, [getComplementItemTypes])

  useEffect(() => {
    getComplementItemTable()
    getDefaultTable()
  }, [getComplementItemTable, getDefaultTable])

  const currentFunction = useMemo(
    () =>
      isEditGroup
        ? handleEditComplementFamily
        : isEditItem
        ? handleEditComplementItem
        : isAddGroup
        ? handleAddNewComplementFamily
        : handleAddNewComplementItem,
    [
      isEditGroup,
      isEditItem,
      isAddGroup,
      handleAddNewComplementFamily,
      handleAddNewComplementItem,
      handleEditComplementFamily,
      handleEditComplementItem
    ]
  )

  const ComplementsSideModal = document.getElementById('complements-side-modal')
  if (!ComplementsSideModal) return null

  return ReactDOM.createPortal(
    <Overlay>
      <ComplementsModalContainer className="thematic-scroll">
        <div className="sidebar-header">
          <h1>{HeaderTitle}</h1>
          <button onClick={close}>
            <X color="#f3f4f8" size={30} />
          </button>
          <br />
        </div>

        <div className="content-wrapper">
          {isEditItem || isAddItem ? (
            <div className="addItem-tabs">
              {ItemTabs.map(tab => (
                <button
                  key={tab.id}
                  className={selectedTab === tab.tab ? 'activeTab' : ''}
                  onClick={() => handleChangeTab(tab.tab as any)}
                >
                  <li>{tab.name}</li>
                </button>
              ))}
            </div>
          ) : (
            ''
          )}
          <hr />

          {selectedTab === 'detail' && (
            <div>
              <div className="addCategory-Container">
                <div>
                  <label> Descrição</label>
                  <input
                    type="text"
                    className="form-control"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                  <span className="error">
                    {getErrorByFieldname('description')?.message}
                  </span>
                </div>
              </div>
              {!isAddGroup && !isEditGroup && (
                <div className="select-complements-group">
                  <div className="select-field">
                    <div>
                      <label htmlFor="">Categoria</label>
                      <select
                        className="form-control"
                        value={categoryCode}
                        onChange={e => setCategoryCode(e.target.value)}
                      >
                        {complementos.map((complement: any) => (
                          <option
                            key={complement.CodigoCategoria}
                            value={complement.CodigoCategoria}
                          >
                            {complement.DescricaoCategoria}
                          </option>
                        ))}
                      </select>
                      <span className="error">
                        {getErrorByFieldname('categoryCode')?.message}
                      </span>
                    </div>
                  </div>
                  <div className="select-field">
                    <label htmlFor="select-complement">
                      Tipo de Complemento
                    </label>
                    <select
                      id="select-complement"
                      className="form-control"
                      value={complementType}
                      onChange={e => setComplementType(e.target.value)}
                    >
                      {complementTypes.map((complement: any) => (
                        <option key={complement} value={complement}>
                          {complement}
                        </option>
                      ))}
                    </select>
                    <span className="error">
                      {getErrorByFieldname('complementType')?.message}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedTab === 'price' && (
            <Price
              tables={tables}
              handleAddNewTable={handleAddNewTable}
              handleRemoveTable={handleRemoveTable}
              handleToggleStore={handleToggleStore}
              changeTablePrice={handleChangeTablePrice}
              isAddAction={isAddItem || false}
              setSelectedTableStoreList={setSelectedTableStoreList}
            />
          )}
        </div>

        <div className="save-container">
          <button onClick={close}>Cancelar</button>
          <button onClick={currentFunction}>Salvar</button>
        </div>
      </ComplementsModalContainer>
      {isLoading && <ScaleLoader />}
    </Overlay>,
    ComplementsSideModal
  )
}

export default ComplementsSideModal
