import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { UserStore } from '../../types/user'
import FrotaCard from './FrotaCard'
import { DateSelectorContainer } from './styles'

type DateSelectorProps = {
  initialDateValue?: string
  setInitialDateValue?: (value: string) => void
  finalDateValue?: string
  setFinalDateValue?: (value: string) => void
  children?: ReactNode
  setStore?: (storeCode: number) => void
  noDate?: boolean
  selectedStore?: number
  setStoreName?: (storeName: string) => void,
  showFrotaCard?: boolean
}

function DateSelector({
  initialDateValue,
  setInitialDateValue,
  finalDateValue,
  setFinalDateValue,
  children,
  noDate,
  setStore,
  selectedStore,
  setStoreName,
  showFrotaCard
}: DateSelectorProps) {
  const { storeList } = useSelector((state: RootState) => state.auth)

  function handleStoreChange(storeCode: string) {
    if (!setStore) return

    setStore(Number(storeCode))

    if (!setStoreName) return

    const currentStore: UserStore =
      storeList?.find(
        (store: UserStore) => store.CodigoExterno === Number(storeCode)
      ) || ({} as UserStore)

    if (!currentStore) return

    setStoreName(
      `${currentStore?.Sigla && `${currentStore.Sigla} - `}${
        currentStore?.Nome
      }`
    )
  }

  return (
    <DateSelectorContainer>
      <div className="inputs">
        {!noDate && setInitialDateValue && setFinalDateValue && (
          <>
            <div className="input-group">
              <p>Data início</p>
              <input
                type="date"
                className="form-control"
                value={initialDateValue}
                onChange={e => setInitialDateValue(e.target.value)}
              />
            </div>
            <div className="input-group">
              <p>Data fim</p>
              <input
                type="date"
                className="form-control"
                value={finalDateValue}
                onChange={e => setFinalDateValue(e.currentTarget.value)}
              />
            </div>
          </>
        )}
        {storeList && storeList.length > 0 && (
          <>
            <div className="input-group">
              <p>Unidades</p>
              <select
                className="form-control"
                value={selectedStore}
                onChange={e => handleStoreChange(e.target.value)}
              >
                {storeList.map((store: UserStore) => (
                  <option value={store.CodigoExterno} key={store.CodigoExterno}>
                    {store.CodigoExterno} - {store.Nome}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </div>

      {children && <div className="actions">{children}</div>}

      {
        showFrotaCard && (
          <FrotaCard />
        )
      }
    </DateSelectorContainer>
  )
}
export default DateSelector
