/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

import { sidebarData } from './data/sidebarData'
import { SidebarContainer } from './styles'
import LogoAgileDarkMode from '../../../../assets/images/logo/icone-agile-branco.png'
import LogoAgileLightMode from '../../../../assets/images/logo/icone-agile-roxo-fundo-removido.png'
import {
  X,
  AlertTriangle,
  CheckSquare,
  ChevronsRight,
  Banknote
} from 'lucide-react'
import SidebarItem from './components/SidebarItem'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import ScaleLoader from '../../../../components/ScaleLoader/ScaleLoader'
import SubModal from '../../../../components/Order/components/SubOrderModal'
import agile from '../../../../assets/images/logo/icone-agile-branco.png'
import {
  ErrorAlert,
  SuccessAlert
} from '../../../../components/SweetAlert/SweetAlert'

type SidebarProps = {
  isOpen: boolean
  close: () => void
}

export function Sidebar({ isOpen, close }: SidebarProps) {
  const { theme } = useSelector((state: RootState) => state.theme)

  const {
    selectedEnvironment,
    userToken,
    licenseStoreCode,
    storeList
  } = useSelector((state: RootState) => state.auth)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [storeStatus, setStoreStatus] = useState<string>('')
  const [isWorking, setIsWorking] = useState<boolean>(false)
  const [openStoreModal, setOpenStoreModal] = useState<boolean>(false)
  const [responseOpenStore, setResponseOpenStore] = useState<any>()
  const [openValue, setOpenValue] = useState<number>(0)
  const [syncInProgress, setSyncInProgress] = useState<boolean>()
  const [storeInfo, setStoreInfo] = useState<any>()
  const handleGetStatusReport = useCallback(async () => {
    const env = process.env.REACT_APP_MOVEMENT

    if (!env || !userToken || !selectedEnvironment.Url) {
      return null
    }

    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

    try {
      const response = await axios({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setStoreStatus(response.data)

      setIsWorking(true)
      setIsLoading(false)
    } catch (err: any) {
      const error = err?.response?.data?.Messages?.[0]

      setStoreStatus(error)
      setIsWorking(false)
      setIsLoading(false)
    }
  }, [selectedEnvironment?.Url, licenseStoreCode, userToken])

  async function openStore() {
    setIsLoading(true)
    const env = process.env.REACT_APP_MOVEMENT

    const ValuePayload = {
      ValorAbertura: openValue
    }
    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

    try {
      const response = await axios({
        method: 'POST',
        data: ValuePayload,
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setResponseOpenStore(response.data)
      handleGetStatusReport()
      SuccessAlert('Sucesso !', `Caixa Aberto com sucesso !`)
      setOpenStoreModal(false)
      setIsLoading(false)
      setIsWorking(true)
    } catch (err: any) {
      const error =
        err?.response?.data?.Messages?.[0] ||
        'Ocorreu uma falha durante o processo de abrir caixa, tente novamente !'
      ErrorAlert('Atenção', error)
      setResponseOpenStore(error)
      setOpenStoreModal(false)
      setIsLoading(false)
    }
  }

  async function syncCardapio() {
    setIsLoading(true)

    const env = process.env.REACT_APP_SYNC_CARDAPIO
    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

    try {
      await axios({
        method: 'POST',
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      SuccessAlert('Sucesso', 'Sincronização Iniciada !')
      setSyncInProgress(true)
      setIsLoading(false)
    } catch (err: any) {
      const error =
        err?.response?.data?.Messages?.[0] ||
        'Ocorreu uma falha durante o processo de sincronização de Cardapio. Tente Novamente!'
      ErrorAlert('Atenção', error)

      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetStatusReport()
  }, [handleGetStatusReport, responseOpenStore])

  useEffect(() => {
    if (storeList.length > 0) {
      const store = storeList.find(
        item => item.CodigoExterno === licenseStoreCode
      )
      setStoreInfo(store)
    }
  }, [licenseStoreCode, storeList])
  useEffect(() => {
    getSyncStatus()
  }, [])

  const getSyncStatus = useCallback(
    async (callback?: () => void) => {
      const env = process.env.REACT_APP_SYNC_CARDAPIO
      const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

      try {
        const response = await axios({
          method: 'GET',
          url,
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })

        if (response.status === 200) {
          setSyncInProgress(true)
        }
        if (response.status === 204) {
          setSyncInProgress(false)
        }
      } catch (e) {}
    },
    [licenseStoreCode, selectedEnvironment.Url, userToken]
  )

  useEffect(() => {
    if (syncInProgress) {
      setInterval(getSyncStatus, 180000)
    }
  }, [getSyncStatus, syncInProgress])

  return (
    <>
      <SidebarContainer
        className={`card-box-shadow ${isOpen && 'open'} thematic-scroll`}
      >
        <nav className={`nav-menu`}>
          <div className="nav-header">
            <div className="logo-wrapper">
              <img
                src={theme === 'dark' ? LogoAgileDarkMode : LogoAgileLightMode}
                className="agile-logo"
                alt="Logo Agile"
              />
            </div>
            <button type="button" onClick={close}>
              <X />
            </button>
          </div>
          <div className="info-card-container">
            <h3>
              {storeInfo?.Sigla} - {storeInfo?.Nome}
            </h3>
            <p className="environment-description">
              {selectedEnvironment?.Nome}
            </p>
            <p>Versão {process.env.REACT_APP_VERSION_SYSTEM}</p>
          </div>
          <div
            className={isWorking ? 'status-store-card' : 'status-store-offline'}
          >
            {isWorking ? <CheckSquare /> : <AlertTriangle />}

            <strong>
              <p>Status do movimento</p>
              <p className="store-status">{storeStatus}</p>
            </strong>
          </div>
          {!isWorking && (
            <div className="Open-Store" onClick={() => setOpenStoreModal(true)}>
              <button>
                Abrir movimento
                <ChevronsRight />
              </button>
            </div>
          )}

          <div className="Update-Menu">
            <button
              title={
                syncInProgress
                  ? 'Aguarde o fim da Sincronização, leva de 2 a 10 minutos.'
                  : ''
              }
              className={syncInProgress ? 'isSync' : ''}
              disabled={syncInProgress}
              onClick={syncCardapio}
            >
              {syncInProgress
                ? 'Sincronização em Andamento'
                : 'Sincronizar Cardapio'}
              <ChevronsRight />
            </button>
          </div>

          <ul className="nav-menu-items">
            {sidebarData.map(item => (
              <SidebarItem key={item.id} item={item} closeSidebar={close} />
            ))}
          </ul>
        </nav>
      </SidebarContainer>
      {openStoreModal && (
        <SubModal
          svg={agile}
          title={'Valor de Abertura do Caixa'}
          confirm={openStore}
          close={() => setOpenStoreModal(false)}
        >
          <div className="currency-container">
            <Banknote />
            <input
              type="number"
              className="form-control"
              min={0}
              defaultValue={openValue}
              onChange={e => setOpenValue(e.target.valueAsNumber)}
            />
          </div>
        </SubModal>
      )}
      {isLoading && <ScaleLoader />}
    </>
  )
}
