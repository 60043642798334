/* eslint-disable react-hooks/exhaustive-deps */

import ReactDOM from 'react-dom'

import { ChangeEvent, useEffect, useState, useMemo } from 'react'
import Overlay from '../../../../../components/Overlay'
import { CreateEditModalContainer } from '../../../styles'
import { Minus, Plus, X } from 'lucide-react'
import { ErrorAlert } from '../../../../../components/SweetAlert/SweetAlert'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import axios from 'axios'
import useFormError from '../../../../../hooks/useFormError'

type CreateEditModalProps = {
  close: () => void
  action: (obj: any) => void
  editObj?: any
}

function CreateEditModal({ close, action, editObj }: CreateEditModalProps) {
  const [complement, setComplement] = useState<any>(
    editObj?.familiaComplementos || { Codigo: -1 }
  )

  const [min, setMin] = useState<number>(editObj?.QtdeMinima || 0)
  const [max, setMax] = useState<number>(editObj?.QtdeMaxima || 0)

  function handleComplementChange(e: ChangeEvent<HTMLSelectElement>) {
    const selectedComplement = others.find(
      (complement: any) => complement.Codigo === Number(e.target.value)
    )
    
    if (!selectedComplement) return

    setComplement(selectedComplement)
  }

  function handleDecreaseMin() {
    setMin(prevState => (prevState === 0 ? prevState : prevState - 1))
  }

  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] = useFormError()

  function handleDecreaseMax() {
    setMax(prevState => (prevState === 0 ? prevState : prevState - 1))
  }
  const [others, setOthers] = useState([])
  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )
  const technicalFileModal = document.getElementById('technical-file-modal')

  async function getTecnicalFileOthers() {
    const env = process.env.REACT_APP_CARDAPIO_TECHNICAL_FILE_OTHER_GET

    if (!env || !userToken || !selectedEnvironment.Url) return

    const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`

    try {
      const response = await axios({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setOthers(response.data)
    } catch (err: any) {
      ErrorAlert('Atenção', err)
    }
  }

  function validateFields(): boolean {
    if (complement.Codigo === -1) {
      setNewError('complement', 'O campo Complemento é obrigatório')
      return false
    }

    cleanErrorsByFieldname('complement')
    return true
  }

  function handleAction(data: any) {
    if (!validateFields()) return

    action(data)
  }

  useEffect(() => {
    getTecnicalFileOthers()
  }, [])

  const ingredientItemData = useMemo(
    () => ({
      Codigo:
        editObj?.Codigo !== undefined ? editObj?.Codigo : complement.Codigo,
      Nome: editObj?.Nome !== undefined ? editObj?.Nome : complement.Nome,
      QtdeMinima: min,
      QtdeMaxima: max
    }),
    [
      editObj?.Codigo,
      complement.Codigo,
      complement.Nome,
      max,
      min,
      editObj?.Nome
    ]
  )
  
  if (!technicalFileModal) return null

  return ReactDOM.createPortal(
    <Overlay>
      <CreateEditModalContainer>
        <div className="heading">
          <h2>{editObj?.Nome ? 'Editar' : 'Criar'} Complemento</h2>

          <button type="button" onClick={close}>
            <X />
          </button>
        </div>

        {/* <div className="field">
          <label>
            <span>Nome</span>
            <input
              className="form-control"
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
            />
          </label>
        </div> */}
        <div className="field">
          <label>
            <span>Complemento</span>
            <select
              className="form-control"
              value={complement.Codigo}
              onChange={handleComplementChange}
            >
              <option value={-1} disabled>
                Selecione um complemento...
              </option>
              {others.map((complement: any) => (
                <option value={complement.Codigo} key={complement.Codigo}>
                  {complement.Nome}
                </option>
              ))}
            </select>
            <span className="error">{getErrorByFieldname('complement')?.message}</span>
          </label>
        </div>
        <div className="quantity-fields">
          <div className="quantity-field">
            <div className="label">
              <span>Qtd. Mínima</span>
            </div>
            <div className="field-group">
              <button onClick={handleDecreaseMin}>
                <Minus />
              </button>
              <input
                className="form-control"
                type="text"
                value={min}
                disabled
              />
              <button onClick={() => setMin(prevState => prevState + 1)}>
                <Plus />
              </button>
            </div>
          </div>
          <div className="quantity-field">
            <div className="label">
              <span>Qtd. Máxima</span>
            </div>
            <div className="field-group">
              <button onClick={handleDecreaseMax}>
                <Minus />
              </button>
              <input
                className="form-control"
                type="text"
                value={max}
                disabled
              />
              <button onClick={() => setMax(prevState => prevState + 1)}>
                <Plus />
              </button>
            </div>
          </div>
        </div>
        <div className="actions">
          <button type="button" onClick={() => handleAction(ingredientItemData)}>
            Adicionar
          </button>
          <button type="button" onClick={close}>
            Cancelar
          </button>
        </div>
      </CreateEditModalContainer>
    </Overlay>,
    technicalFileModal
  )
}
export default CreateEditModal
