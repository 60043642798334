import { useState } from 'react'

type IntegratorCardProps = {
  title: string,
  counter: number,
  toggleFilter: (filter: string) => void,
  actionKey: string,
  active: boolean
}

export function IntegratorCard({ title, counter, actionKey, toggleFilter, active }: IntegratorCardProps) {
  const [isActive, setIsActive] = useState<boolean>(active)

  function handleIntegratorCardClick() {
    setIsActive((prevState) => !prevState)

    toggleFilter(actionKey)
  }

  return (
    <button
      type="button"
      className={`integrator-card card-box-shadow ${isActive && 'active'}`}
      onClick={handleIntegratorCardClick}
    >
      [{counter}] {title}
    </button>
  )
}
