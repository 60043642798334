/* eslint-disable react-hooks/exhaustive-deps */

import { X } from 'lucide-react'
import { useState } from 'react'
import Overlay from '../../../../../../../components/Overlay'
import { CloneModalContainer } from './styles'
import { useEffect } from 'react'

type CloneModalProps = {
  close: () => void
  sizeSelected: any
  fichaTecnica: any
  changeSizeSelected: (value: any) => void
  cloneSizesFunction: (origem: any, destino: any) => void
}

function CloneModal({
  close,
  sizeSelected,
  fichaTecnica,
  cloneSizesFunction
}: CloneModalProps) {
  useEffect(() => {
    const sizes = fichaTecnica.filter(
      (e: any) => e?.CodigoTamanho !== sizeSelected?.CodigoTamanho
    )
    setCloneSizesAvailable(sizes)
    setCodigoTamanhoDestino(sizes[0]?.CodigoTamanho)
  }, [fichaTecnica])

  const [cloneSizesAvailable, setCloneSizesAvailable] = useState([])
  const [codigoTamanhoDestino, setCodigoTamanhoDestino] = useState<number>()

  const cloneSize = () => {
    const tamanhoOrigem = sizeSelected
    const tamanhoDestino = cloneSizesAvailable.find(
      (tamanho: any) => tamanho?.CodigoTamanho === codigoTamanhoDestino
    )

    if (!tamanhoOrigem || !tamanhoDestino) return

    cloneSizesFunction(tamanhoOrigem, tamanhoDestino)
    close()
  }

  // async function CloneSelectedSize() {
  //   const env = process.env.REACT_APP_CARDAPIO_TECHNICAL_FILE_CLONESIZE

  //   if (!env || !selectedEnvironment.Url || !userToken || !sizeSelected) return

  //   try {
  //     const payload = {
  //       CodTamanhoOrigem: sizeSelected.CodigoTamanho,
  //       CodTamanhoDestino: codigoTamanhoDestino
  //     }
  //     const response = await axios({
  //       method: 'POST',
  //       url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
  //       data: payload,
  //       headers: {
  //         Authorization: `Bearer ${userToken}`
  //       }
  //     })
  //     SuccessAlert(
  //       'Sucesso',
  //       `Tamanho origem ${sizeSelected.NomeTamanho} foi clonado com sucesso.`
  //     )
  //     close()
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  
  return (
    <Overlay>
      <CloneModalContainer>
        <div className="heading">
          <h2>
            Clonar Tamanho <u>{sizeSelected?.NomeTamanho}</u>
          </h2>

          <button onClick={close}>
            <X color="#f3f4f8" />
          </button>
        </div>
        <div className="form-group">
          <label htmlFor="">Tamanho Destino</label>
          <select
            id="Tamanho-destino"
            className="form-control"
            value={codigoTamanhoDestino}
            onChange={e => setCodigoTamanhoDestino(Number(e.target.value))}
          >
            {cloneSizesAvailable?.map((tamanho: any) => (
              <option
                value={tamanho?.CodigoTamanho}
                key={tamanho?.CodigoTamanho}
              >
                {tamanho?.NomeTamanho}
              </option>
            ))}
          </select>
        </div>
        <div className="actions-group">
          <button className="clone" onClick={cloneSize}>
            Clonar
          </button>
          <button className="cancel" onClick={close}>
            Cancelar
          </button>
        </div>
      </CloneModalContainer>
    </Overlay>
  )
}

export default CloneModal
