import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../store/store'

const FrotaCardContainer = styled.div`
  width: 100%;
  max-width: 150px;

  .heading {
    background: #ffff00;
    color: #000000;

    font-size: 1.2rem;

    text-align: center;

    padding: 0.4rem;

    border-top-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
  }

  .content {
    background: #ffffff;
    color: #000000;

    font-size: 1.2rem;
    font-weight: bolder;

    padding: 0.2rem 0.6rem;

    display: flex;
    flex-direction: column;

    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }
`

function FrotaCard() {
  const { frotaOnline } = useSelector((state: RootState) => state.auth)

  if (!frotaOnline) return null

  return (
    <FrotaCardContainer>
      <div className="heading">
        <h3>Entregadores</h3>
      </div>
      <div className="content">
        <p>Ativos: {frotaOnline.OnlineFleet}</p>
        <p>Disponiveis: {frotaOnline.AvailableFleet}</p>
      </div>
    </FrotaCardContainer>
  )
}
export default FrotaCard
