import {
  AlertTriangle,
  ChevronUp,
  Edit2,
  MoreVertical,
  Plus,
  Trash2
} from 'lucide-react'
import { useState } from 'react'
import { ComplementCard } from './styles'
import FamilyItem from './familyItem/familyItem'
import ComplementsSideModal from '../complementsModal/ComplementsModal'

type complementFamilyProps = {
  complementFamilyObj: any
  complementos: any
  orderBy: string
  handleDuplicateComplementFamily?: (complementInfo: any) => void
  handleDeleteComplementFamily: (complementInfo: any) => void
  handleDuplicateComplementItem: (complementInfo: any) => void
  handleDeleteComplementItem: (complementInfo: any) => void
  handleChangeComplementFamilyStatus: (
    complementInfo: any,
    status: string
  ) => void

  handleChangeComplementItemStatus: (
    complementInfo: any,
    status: string
  ) => void
  handleGetComplementosItems: () => void
}

const getActiveFilter = (
  itemFilterActive: string,
  arg1: any,
  arg2: any
): any => {
  if (itemFilterActive === 'AZ') {
    return arg1?.Nome > arg2?.Nome ? 1 : -1
  } else if (itemFilterActive === 'ZA') {
    return arg1?.Nome < arg2?.Nome ? 1 : -1
  } else if (itemFilterActive === 'Paused') {
    return arg1?.Ativo ? 1 : -1
  } else if (itemFilterActive === 'Activated') {
    return arg1?.Ativo === false ? 1 : -1
  }
}

function ComplementFamily({
  complementFamilyObj,
  orderBy,
  handleDuplicateComplementFamily,
  handleDeleteComplementFamily,
  handleDuplicateComplementItem,
  handleDeleteComplementItem,
  handleChangeComplementFamilyStatus,
  handleChangeComplementItemStatus,
  handleGetComplementosItems,
  complementos
}: complementFamilyProps) {
  const [hideChildren, sethideChildren] = useState<boolean>(true)
  const [activeActions, setActiveActions] = useState<boolean>(false)
  const [addFamilyItem, setAddFamilyItem] = useState<boolean>(false)
  const [editFamilyItem, setEditFamilyItem] = useState<boolean>(false)

  return (
    <ComplementCard key={complementFamilyObj.CodigoCategoria}>
      <div className="complement-card">
        <div className="complement-card-title">
          {/*  {complementFamilyObj.PDV} - */}
          <p>{complementFamilyObj.DescricaoCategoria}</p>
        </div>
        <div className="complement-card-actions">
          <div className="actions">
            <button
              className={
                !complementFamilyObj?.Ativo ? 'pause pause-active' : 'pause'
              }
              onClick={() =>
                handleChangeComplementFamilyStatus(
                  complementFamilyObj,
                  'Pausar'
                )
              }
              disabled={!complementFamilyObj?.Ativo}
            >
              {!complementFamilyObj?.Ativo ? 'Pausado' : 'Pausar'}
            </button>
            <button
              className={
                complementFamilyObj?.Ativo
                  ? 'actived actived-active'
                  : 'actived'
              }
              onClick={() =>
                handleChangeComplementFamilyStatus(
                  complementFamilyObj,
                  'Ativar'
                )
              }
              disabled={complementFamilyObj?.Ativo === true}
            >
              {complementFamilyObj?.Ativo ? 'Ativado' : 'Ativar'}
            </button>
          </div>

          <button
            onClick={() => sethideChildren(!hideChildren)}
            className={!hideChildren ? 'inactive' : 'active'}
            disabled={complementFamilyObj.length < 1}
            title={
              complementFamilyObj.length < 1 ? 'Não há itens para mostrar' : ''
            }
          >
            <ChevronUp color="#f3f4f8" />
          </button>
          <div className="dropdownCardapio">
            <button onClick={() => setActiveActions(!activeActions)}>
              <MoreVertical color="#f3f4f8" />
              {activeActions && (
                <div className="dropdown-content">
                  <button onClick={() => setEditFamilyItem(true)}>
                    <Edit2 color="#f3f4f8" />
                    <abbr>Editar</abbr>
                  </button>
                  <button
                    onClick={() =>
                      handleDeleteComplementFamily(complementFamilyObj)
                    }
                  >
                    <Trash2 color="#f3f4f8" />
                    <abbr>Excluir</abbr>
                  </button>
                  {/* <button
                    onClick={() =>
                      handleDuplicateComplementFamily(complementFamilyObj)
                    }
                  >
                    <Copy color="#f3f4f8" />
                    <abbr>Duplicar</abbr>
                  </button> */}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      {hideChildren && complementFamilyObj.Complementos.length > 0 && (
        <div className="items-table">
          <table>
            <thead>
              <tr className="thead-children">
                <th>Item</th>
                {complementFamilyObj.Tipo === 'Massas' ? (
                  <th>Aceita Borda?</th>
                ) : (
                  ''
                )}
                <th>Tipo</th>

                <th>Status de vendas</th>
              </tr>
            </thead>

            <tbody>
              {complementFamilyObj.Complementos.sort((a: any, b: any) =>
                getActiveFilter(orderBy, a, b)
              ).map((item: any) => (
                <FamilyItem
                  key={item.Codigo}
                  FamilyItemObj={item}
                  fatherType={complementFamilyObj?.Tipo}
                  handleDuplicateComplementItem={handleDuplicateComplementItem}
                  handleDeleteComplementItem={handleDeleteComplementItem}
                  handleChangeComplementItemStatus={
                    handleChangeComplementItemStatus
                  }
                  handleGetComplementosItems={handleGetComplementosItems}
                  complementos={complementos}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="addItem-container">
        <p>
          {complementFamilyObj.Complementos.length === 0 ? (
            <>
              <AlertTriangle color="red" />
              Nenhum Item Cadastrado
            </>
          ) : (
            ''
          )}
        </p>
        <button onClick={() => setAddFamilyItem(true)}>
          <Plus color="#f3f4f8" />
          Adicionar complemento
        </button>
      </div>
      {addFamilyItem && (
        //adicionar item familia
        <ComplementsSideModal
          complementos={complementos}
          HeaderTitle={'Adicionar Item de Complementos'}
          isAddItem
          close={() => setAddFamilyItem(false)}
          fatherCode={complementFamilyObj?.CodigoCategoria}
          handleGetComplementosItems={handleGetComplementosItems}
        />
      )}

      {editFamilyItem && (
        //Editar item familia
        <ComplementsSideModal
          complementos={complementos}
          HeaderTitle={` ${complementFamilyObj.CodigoCategoria} - ${complementFamilyObj.DescricaoCategoria}`}
          isEditGroup
          close={() => setEditFamilyItem(false)}
          FamilyObject={complementFamilyObj}
          handleGetComplementosItems={handleGetComplementosItems}
        />
      )}
    </ComplementCard>
  )
}

export default ComplementFamily
