import ReactDOM from 'react-dom'
import Overlay from '../Overlay'
import { ScaleLoaderContainer } from './styles'

function ScaleLoader () {
  const loaderPortalElement = document.getElementById('loader-portal')

  if (!loaderPortalElement) return null

  return ReactDOM.createPortal((
    <Overlay highZIndex>
      <ScaleLoaderContainer>
        <div />
        <div />
        <div />
        <div />
      </ScaleLoaderContainer>
    </Overlay>
  ), loaderPortalElement)
}

export default ScaleLoader
