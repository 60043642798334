/* eslint-disable react-hooks/exhaustive-deps */

import { Trash } from 'lucide-react'
import { useState } from 'react'

import Switch from 'react-switch'
import Swal from 'sweetalert2'
import { useEffect } from 'react'

type IngredientItemProps = {
  ingredient: any
  setIngredientes: (value: any) => void
  replaceFunction: (value: any) => void
}

function IngredientItem({
  ingredient,
  setIngredientes,
  replaceFunction
}: IngredientItemProps) {
  const [sequence, setSequence] = useState<number>(ingredient?.Sequencia || 0)

  const [mandatory, setMandatory] = useState<boolean>(
    ingredient?.PodeRemover === 'NAO' ? true : false
  )

  useEffect(() => {
    if (!ingredient?.Codigo || !ingredient?.Nome) return

    const ingredientItemData = {
      Codigo: ingredient?.Codigo,
      Nome: ingredient?.Nome,
      Sequencia: sequence,
      Checked: mandatory,
      PodeRemover: ingredient?.PodeRemover
    }

    replaceFunction(ingredientItemData)
  }, [
    sequence,
    mandatory,
    ingredient?.Codigo,
    ingredient?.Nome,
    ingredient?.PodeRemover
  ])

  useEffect(() => {
    setIngredientes((prevState: any) => {
      return prevState.map((ing: any) => ing.Codigo === ingredient.Codigo ? ({
        ...ing,
        PodeRemover: mandatory ? 'NAO' : 'SIM'
      }): ing)
    })
  }, [mandatory])

  function DeleteIngrediente(ingrediente: any) {
    Swal.fire({
      title: `Deseja Excluir o Ingrediente: ${ingrediente.Codigo} -  ${ingrediente.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.isConfirmed) {
        setIngredientes((prevState: any) =>
          prevState.filter((value: any) => value.Codigo !== ingrediente.Codigo)
        )
        // Swal.fire({
        //   title: `Ingrediente ${ingrediente.Codigo} - ${ingrediente.Nome} Excluido com Sucesso`,
        //   icon: 'success',
        //   background: '#283046',
        //   color: '#f3f4f8'
        // })
      }
    })
  }

  return (
    <tr key={ingredient?.Codigo}>
      <td>
        {ingredient?.Codigo} - {ingredient?.Nome}
      </td>
      <td>
        <div className="sequence-container">
          <input
            min={0}
            type="number"
            value={sequence}
            style={{ backgroundColor: '#f2f2f2' }}
            onChange={e => setSequence(Number(e.target.value))}
          />
        </div>
      </td>
      <td>
        <div>
          <Switch
            name="Promoção"
            onChange={($event: boolean) => setMandatory($event)}
            checked={mandatory}
            offColor="#dc0000"
          />
        </div>
      </td>
      <td>
        <div className="ingredients-actions">
          <button
            className="remove"
            onClick={() => DeleteIngrediente(ingredient)}
            disabled={ingredient.PodeRemover === 'NAO'}
            title={
              ingredient.PodeRemover === 'NAO'
                ? 'Não é possivel remover esse ingrediente'
                : ''
            }
          >
            <Trash color="#f3f4f8" />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default IngredientItem
