import { configureStore } from '@reduxjs/toolkit'
import authSlice from './features/auth/authSlice'
import expeditionSlice from './features/expedition/expeditionSlice'
import genericSlice from './features/generic/genericSlice'
import themeSlice from './features/theme/themeSlice'

const store = configureStore({
  reducer: {
    auth: authSlice,
    generic: genericSlice,
    theme: themeSlice,
    expedition: expeditionSlice
  },
  middleware: (curryGetDefaultMiddleware) => curryGetDefaultMiddleware({
    serializableCheck: false
  })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export default store
