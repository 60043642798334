import styled from "styled-components";

export const StoreListContainer = styled.div`
height: 100%;

.StoreFilter{
  background: ${({ theme }) => theme.colors.dark.main};
  display: flex;
  padding: 20px;
  border-radius: 0.7rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  
  > button{
    margin-bottom: 1rem;
    padding: 10px;
    border-radius: 0.7rem;
    background: #0096FF;
  }

  > input{
    padding: 10px;
  }

  > .dropdownCardapio{
    > button{
      margin-bottom: 1rem;
      padding: 10px;
      background: #A66CFF;
    }
  }
}

.content{
  background: ${({ theme }) => theme.colors.dark.main};
  height: auto;
  width: 100%;
  margin-top: 1rem;
  border-radius: 0.7rem;

  table{
    width: 100%;
    display: table;
    height: auto;
    border-collapse: collapse;
    color: ${({ theme }) => theme.colors.dark.cardText};
    > tbody{
      
      > tr{
        border: 1px solid #fff;
        height: 80px;
        &:hover{
          background: #554994;
        }
        >td{
          padding: 10px;
          border: 1px solid #fff;
          > div{
            display: flex;
            gap: 2rem;
            align-items: center;
            > .list-img{
              display: flex;
              justify-content: center;
              min-width: 80px;
              > img{
              height: 50px;
              border-radius: 0.7rem;

              &:hover{
                  transition: 300ms;
                  transform: scale(1.1);
                }
            }
            }
           
            > .text-container{
              
              > h2{
                text-align: left;
              }
            }
           
            > .store-information{
              display: flex;
              gap: 1rem;
              margin-left: auto;
              text-align: center;
              justify-content: right;
              
              > .store-status{
                width: 80px;
                border: 1px solid #fff;
                padding: 5px;
                margin-left: 1rem;
                border-radius: 1rem;
                
                &:hover{
                  transition: 300ms;
                  transform: scale(1.1);
                }
              }

              > .opened{
                
                background: #39A388;
              }
            
              > .closed{
                background: #EB1D36;
              }

              >.altgreen{
                background: #39A388;
              }

           
             
           

            }
            >.actions-container{
              
              margin-right: 1rem;
              > button{
                padding: 10px;

                &:hover{
                  transition: 700ms;
                  transform: scale(1.1);
                }
              }
            }
           
          }
        }
      }
    }
  
  }
}

@media(max-width:1100px ){
  .content{
    width: 100%;
    > table{
      width: 100%;
      
      tbody{
        tr{
          width: 100%;
          td{
            
            div{
              display: block;
              padding: 10px;
              font-size: 16px;

              > .text-container{
                > h2{
                text-align: center;
              }
                text-align: center;
              }
              > .store-information{
                display: flex;
                justify-content: center;
              }

              >.actions-container{
              display: flex;
              justify-content: center;
             
            }
            }
          }
        }
      }
    }
  }

}
`