import { TableContainer } from './styles'
import TableRow from './TableRow'
import formatBRL from '../../utils/formatBRL'

export type rowHeaderItem = {
  value: string
  alternateValue?: string
  alternateStringValue?: string
  format?:
    | 'date'
    | 'currency'
    | 'percentage'
    | 'nf'
    | 'img'
    | 'date&hour'
    | 'whats'
    | 'tel'
    | 'address'
  sub?: string
  neighbourhood?: string
  hour?: string
  ddd?: string
}

type TableProps = {
  items: any
  headItems: string[]
  rowHeaders: rowHeaderItem[]
  noIndex?: boolean
  noCanal?: boolean
  actionButtons?: boolean
  action?: (value: string) => void
  infoProperty?: string
  currentModalInfoCode?: number
  personalizationAllowed?: boolean
  needTotal?: boolean
  hasExtraTr?: boolean
  extraTrTitle?: string
}

function Table({
  items,
  headItems,
  rowHeaders,
  noIndex,
  noCanal,
  action,
  infoProperty,
  currentModalInfoCode,
  personalizationAllowed,
  needTotal,
  hasExtraTr,
  extraTrTitle
}: TableProps) {
  return (
    <TableContainer>
      <table>
        <thead>
          {hasExtraTr ? (
            <tr className="extra-tr">
              <th colSpan={headItems?.length}>{extraTrTitle}</th>
            </tr>
          ) : (
            ''
          )}
          <tr>
            {headItems.map(headItem => (
              <th key={headItem}>{headItem}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item: any, index: number) => (
            <TableRow
              item={item}
              index={index + 1}
              key={index}
              rowHeaders={rowHeaders}
              noIndex={noIndex || false}
              noCanal={noCanal || false}
              getInfo={(value: string) => action?.(value)}
              infoProperty={infoProperty || ''}
              currentModalInfoCode={currentModalInfoCode || 0}
              personalizationAllowed={personalizationAllowed}
            />
          ))}
          {needTotal && (
            <tr className="totalValues">
              <td colSpan={5}>Totais</td>
              <td>
                {formatBRL(
                  items
                    .map((data: any) => data.SUBTOTAL_PEDIDO)
                    .reduce((a: any, b: any) => a + b)
                )}
              </td>
              <td>
                {formatBRL(
                  items
                    .map((data: any) => data.DESCONTO)
                    .reduce((a: any, b: any) => a + b)
                )}
              </td>
              <td>
                {formatBRL(
                  items
                    .map((data: any) => data.TAXA_ENTREGA)
                    .reduce((a: any, b: any) => a + b)
                )}
              </td>
              <td>
                {formatBRL(
                  items
                    .map((data: any) => data.VALOR_TOTAL)
                    .reduce((a: any, b: any) => a + b)
                )}
              </td>
              <td colSpan={2}></td>
              <td>
                {formatBRL(
                  items
                    .map((data: any) => data.VALOR_COMISSAO_AGILE)
                    .reduce((a: any, b: any) => a + b)
                )}
              </td>
              <td>
                {formatBRL(
                  items
                    .map((data: any) => data.VALOR_TAXA_TRANSACAO_ONLINE)
                    .reduce((a: any, b: any) => a + b)
                )}
              </td>
              <td>
                {formatBRL(
                  items
                    .map((data: any) => data.VALOR_LIQUIDO)
                    .reduce((a: any, b: any) => a + b)
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </TableContainer>
  )
}

export default Table
