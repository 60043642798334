import { IntegratorSearcherContainer } from './filterIntegrator'

type FilterIntegratorProps = {
  orderKey: any
  setOrderKey: (value: any) => void
}

export function FilterIntegrator({
  orderKey,
  setOrderKey
}: FilterIntegratorProps) {
  return (
    <IntegratorSearcherContainer>
      <div className="form">
        <div>
          <label htmlFor="">
            <input
              type="text"
              className="form-control"
              placeholder="Pesquise por informações do pedido."
              value={orderKey}
              onChange={e => setOrderKey(e.target.value)}
            />
          </label>
        </div>
      </div>
    </IntegratorSearcherContainer>
  )
}
