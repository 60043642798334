import styled from 'styled-components';

const FiltersContainer = styled.div`
  background: ${({ theme }) => theme.colors.dark.main};
  border-radius: 1rem;
  padding: .8rem 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-fragment {
    display: flex;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  .filter-field {
    margin-right: 1rem;

    label {
      display: flex;
      flex-direction: column;
    }

    span {
      color: #f3f4f8;
      font-size: 1.2rem;
    }
  }
`

const ShippedOrdersContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  .table-wrapper {
    flex: 1;

    > p {
      text-align: center;

      margin-top: 2rem;

      font-size: 1.4rem;
    }
  }
`

export {
  ShippedOrdersContainer,
  FiltersContainer
}