import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  error: '',
  pageDarkFilm: false
}

const genericSlice = createSlice({
  name: 'generic',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    toggleDarkFilm: (state) => {
      state.pageDarkFilm = !state.pageDarkFilm
    }
  }
})

export default genericSlice.reducer
export const { setError, toggleDarkFilm } = genericSlice.actions