import { X } from 'lucide-react'
import { useState, ChangeEvent } from 'react'

import ReactDOM from 'react-dom'

import Overlay from '../../../components/Overlay'
import useFormError from '../../../hooks/useFormError'
import { Deliveryman, ShippedOrder } from '../../../types/orders'
import { validateEmptyField } from '../../../utils/fieldValidations'
import { numberMask } from '../../../utils/masks'
import { ExpeditionModalContainer } from '../styles'

type ExpeditionModalProps = {
  orders: ShippedOrder[]
  deliverymanList: Deliveryman[]
  close: () => void
  action: (tip: string, selectedDeliveryman: Deliveryman) => void
  noTip?: boolean
}

function ExpeditionModal({
  orders,
  deliverymanList,
  close,
  action,
  noTip
}: ExpeditionModalProps) {
  const [tip, setTip] = useState<string>('R$ 0,00')
  const [selectedDeliveryman, setSelectedDeliveryman] = useState<Deliveryman>()

  const [setNewError, getErrorByFieldname, cleanErrorsByFieldname] =
    useFormError()

  function validateFields(): boolean {
    const isTipFieldValid = validateEmptyField(
      tip,
      'tip',
      'O campo Troco é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    const isDeliverymanFieldValid = validateEmptyField(
      selectedDeliveryman?.Nome || '',
      'deliveryman',
      'O campo Entregador é obrigatório',
      setNewError,
      cleanErrorsByFieldname
    )

    return isTipFieldValid || isDeliverymanFieldValid
  }

  function handleChangeTip(e: ChangeEvent<HTMLInputElement>) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })

    setTip(formatter.format(Number(numberMask(e.target.value)) / 100))
  }

  function handleCallAction() {
    if (validateFields() && selectedDeliveryman) {
      action(tip, selectedDeliveryman)
    }
  }

  function handleChangeSelectedDeliveryman(e: ChangeEvent<HTMLSelectElement>) {
    const selectedDeliveryman = deliverymanList.find(
      deliveryman => deliveryman.Codigo === Number(e.target.value)
    )

    if (!selectedDeliveryman) return

    setSelectedDeliveryman(selectedDeliveryman)
  }

  const expeditionModalPortal = document.getElementById(
    'expedition-modal-portal'
  )

  if (!expeditionModalPortal) return null

  return ReactDOM.createPortal(
    <Overlay>
      <ExpeditionModalContainer>
        <div className="header">
          <h3>Expedindo o pedido</h3>
          <button type="button" onClick={close}>
            <X />
          </button>
        </div>
        <div className="content">
          <div className="info">
            {orders.length < 2 ? (
              <>
                <p>
                  Pedido
                  <span>
                    {orders[0]?.CodigoDia} - {orders[0]?.CodigoSeq}
                  </span>
                </p>
                <p>
                  Cliente <span>{orders[0]?.NomeCliente}</span>
                </p>
                <p>
                  Bairro <span>{orders[0]?.Bairro}</span>
                </p>
                <p>
                  Valor <span>R$ {orders[0]?.ValorTotal.toFixed(2)}</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  Pedidos:{' '}
                  <span>{orders.map(order => `${order?.CodigoDia}, `)}</span>
                </p>
              </>
            )}
          </div>
          <div className="fields">
            <div className="field">
              <span>Entregador</span>
              <select
                onChange={handleChangeSelectedDeliveryman}
                defaultValue={selectedDeliveryman?.Nome || ''}
                className="form-control"
              >
                <option value="" disabled>
                  Escolha um entregador
                </option>
                {deliverymanList.map(deliveryman => (
                  <option value={deliveryman.Codigo}>{deliveryman.Nome}</option>
                ))}
              </select>
              {getErrorByFieldname('deliveryman')?.message && (
                <span className="error-message">
                  {getErrorByFieldname('deliveryman')?.message}
                </span>
              )}
            </div>
            {!noTip && (
              <div className="field">
                <span>Valor do troco</span>
                <input
                  className="form-control"
                  type="text"
                  value={tip}
                  onChange={handleChangeTip}
                />
                {getErrorByFieldname('tip')?.message && (
                  <span className="error-message">
                    {getErrorByFieldname('tip')?.message}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="actions">
            <div className="button-wrapper">
              <button
                className="bold"
                type="button"
                onClick={handleCallAction}
                disabled={!selectedDeliveryman}
                title={
                  !selectedDeliveryman
                    ? 'Você precisa selecionar um entregador para efetivar a expedição'
                    : ''
                }
              >
                Expedir pedido(s)
              </button>
            </div>
          </div>
        </div>
      </ExpeditionModalContainer>
    </Overlay>,
    expeditionModalPortal
  )
}
export default ExpeditionModal
