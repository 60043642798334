import { RefreshCw } from 'lucide-react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import DateSelector from '../../components/DateSelector'
import PageCard from '../../components/PageCard'
import useDateInterval from '../../hooks/useDateInterval'
import { RootState } from '../../store/store'
import { Graphics } from './components/Graphics'
import { IndicatorsContainer } from './styles'

function Indicators() {
  const { licenseStoreCode, licenseStoreName } = useSelector(
    (state: RootState) => state.auth
  )

  const [selectedStoreCode, setSelectedStoreCode] =
    useState<number>(licenseStoreCode)
  const [selectedStoreLabel, setSelectedStoreLabel] =
    useState<string>(licenseStoreName)

  const [updateCounter, setUpdateCounter] = useState<number>(0)

  const [
    initialDateValue,
    setInitialDateValue,
    finalDateValue,
    setFinalDateValue
  ] = useDateInterval()

  function update() {
    setUpdateCounter(prevState => prevState + 1)
  }

  return (
    <PageCard title="Indicadores">
      <IndicatorsContainer>
        <DateSelector
          initialDateValue={initialDateValue}
          setInitialDateValue={setInitialDateValue}
          finalDateValue={finalDateValue}
          setFinalDateValue={setFinalDateValue}
          setStore={(storeCode: number) => setSelectedStoreCode(storeCode)}
          setStoreName={(storeName: string) => setSelectedStoreLabel(storeName)}
          selectedStore={selectedStoreCode}
        >
          <button className="refresh-button" type="button" onClick={update}>
            <RefreshCw color="#f3f4f8" />
          </button>
        </DateSelector>

        {initialDateValue && finalDateValue && (
          <Graphics
            key={updateCounter}
            initialDateValue={initialDateValue}
            finalDateValue={finalDateValue}
            storeCode={selectedStoreCode}
            storeLabel={selectedStoreLabel}
          />
        )}
      </IndicatorsContainer>
    </PageCard>
  )
}

export default Indicators
