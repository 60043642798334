import styled, { css } from 'styled-components'

type SubModalContainerProps = {
  big?: boolean
}

const SubModalContainer = styled.div<SubModalContainerProps>`

*{
    ::-webkit-scrollbar {
      width: 0.5em;
      height: 1em;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.dark.main};
      border-radius: 100vw;
      margin-block: 0.5em;
    }

    ::-webkit-scrollbar-thumb {
      background: #7A86B6;   
      border-radius: 100vw;
    }
  }

  .currency-container{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
  .error {
    margin-bottom: 1rem;

    span {
      font-size: 1.1rem;
      color: #ee5522;
    }
  }

  position: relative;
  z-index: 1100;
  background: ${({ theme }) => theme.colors.dark.main};
  color: ${({ theme }) => theme.colors.dark.cardText};
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #937DC2;

  .form-button{
    width: 100%;
    color: ${({ theme }) => theme.colors.dark.cardText};
    background-color: #283046;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #7367F0;

    &:disabled{
      opacity: 0.2;
    }

  
  }
  .title-modal{
    
    display: flex;
    gap: 1rem;
    width: 100%;
    height: 20px;

    button{
      color: ${({ theme }) => theme.colors.dark.cardText};
      margin-left: auto;
      justify-self: center;
    }
  }

  .heading {
    margin-bottom: 1rem;
    display: flex;
    padding: 1rem;
    justify-content: center;
  }

  input[type=time]{
    font-size: 18px;

    &::-webkit-calendar-picker-indicator{
      filter: invert(100%)
    }
  }
 
  .action {
    color: ${({ theme }) => theme.colors.dark.cardText};
    width: 100%;
    display: flex;
    margin-top: 2rem;

    button{
      flex: 1;
      color: ${({ theme }) => theme.colors.dark.cardText};

      padding: 1rem;
      transition: .25s ease-in;

      &:hover{
        transform: scale(.9);
      }

      &:nth-child(1) {
        background-color: #3EC70B;
        margin-right: 1rem;
      }

      &:nth-child(2) {
        background-color:#E64848
      }
    }
  }

  .content{
    margin-top: 1rem;
    max-width: 90rem;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    color: ${({ theme }) => theme.colors.dark.cardText};
    .json-display-container{
      background-color: ${({ theme }) => theme.colors.dark.json};
      
    }
    .data__type{
      font-size: 12px;
      max-width: 500px;
    }

    ${({ big }) => big && css`
      height: 50rem;
      max-height: 50rem;
    `


  }
      
    .tab-switch{
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
      
      > .tab{
        width: 100%;
        padding: 1rem;
        color: ${({ theme }) => theme.colors.dark.cardText};
        background-color: #5B4B8A;
        border-radius: 0.357rem;
      }

      .active {
        background-color: #A149FA;
        
      }

      button:disabled{
        cursor: not-allowed;
      }
    }

    .tab-wrapper{
      width: 100%;
      min-width: 660px;
      
          > div {
            width: 100%;
          }

          .message-container{
              display: flex;
              align-self: center;
              position: relative;
              flex-wrap: wrap;
      }
    }



    pre{ 
      
      position: absolute;
      font-size: 1.2rem;
      font-family: 'Courier New', Courier, monospace, Arial, Helvetica, sans-serif;
    }
    .form-group{
      position: relative;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      padding: 1rem 1rem;
      

      .row{
        flex-direction: row;
        gap: 2rem;
        display: flex;  
       
        }
        .row > div {
          width: 100%;
        }    

       
      }  
    .css-1s2u09g-control{
        background-color:${({ theme }) => theme.colors.dark.main};
        height: 30px;
        color: ${({ theme }) => theme.colors.dark.cardText};

        option{
          color: ${({ theme }) => theme.colors.dark.input};

        }
      }

      .css-3iigni-container{
        margin-bottom: 2rem;
        height: 34px;
        width: 100%;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.45;
        color: ${({ theme }) => theme.colors.dark.input};
        background-color: ${({ theme }) => theme.colors.dark.main};
        border-color: ${({ theme }) => theme.colors.button.main};
      }
      .css-1insrsq-control{
        width: 100%;
        height: 100%;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.45;
        color: ${({ theme }) => theme.colors.dark.input};
        background-color: ${({ theme }) => theme.colors.dark.main};
        border-color: ${({ theme }) => theme.colors.button.main};

    &:focus{
      color: ${({ theme }) => theme.colors.dark.input};
      background-color: ${({ theme }) => theme.colors.dark.main};
    }
     }
      
    
      .zapActions{
        display: flex;
        gap: 1rem;
        
        button{
          width: 100%;
          height: 40px;
          color: ${({ theme }) => theme.colors.dark.cardText};
          background-color: #937DC2;
          transition: 200ms;
          border-radius: 0.5rem;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
         
          &:hover{
            
            opacity: 0.8;
          }
        }
      }
  }

  @media(max-width: 768px) {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;

   .content{
    width: 100%;
    
    .tab-switch{
      width:100%;
      display: block;
      padding: 5px;
      > button{
        margin-bottom: 0.7rem;
      }
    }
    .tab-wrapper{
      min-width: 200px;
    }

    .form-group{
      width:100%;

      > input{
        width: 100%;
      }
      .row{
        width: 100%;
        display: block;
      }
    }

    .zapActions{
      
      width: 100%;
      display: block;
      padding: 5px;
      > button{

      }
    }
   }

  }
  

`

const OrderContainer = styled.div`
  *{
    ::-webkit-scrollbar {
      width: 0.5em;
      height: 1em;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.dark.main};
      border-radius: 100vw;
      margin-block: 0.5em;
    }

    ::-webkit-scrollbar-thumb {
      background: #7A86B6;
      
      border-radius: 100vw;
    }
  }

  *{
    ::-webkit-scrollbar {
      width: 0.5em;
      height: 1em;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.dark.main};
      border-radius: 100vw;
      margin-block: 0.5em;
    }

    ::-webkit-scrollbar-thumb {
      background: #7A86B6;   
      border-radius: 100vw;
    }
  }

  background: ${({ theme }) => theme.colors.dark.main};
  color: ${({ theme }) => theme.colors.dark.cardText};
  border: 1px solid #937DC2;
  max-width: 40rem;
  
  padding: 1rem;
  border-radius: 1rem;

  position: relative;
  z-index: 1000;

  .heading {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    img{
      height: 30px;
    }
    button {
      color: ${({ theme }) => theme.colors.dark.cardText};
      
    }
  }

  .content {
    font-size: 1.1rem;
    max-height: 50rem;
    overflow: auto;

    pre{
      line-height: 1rem;
      text-align: left;
      font-size: 1.2rem;
      font-family: 'Courier New', Courier, monospace, Arial, Helvetica, sans-serif;

      overflow-x: hidden;
      overflow-y: hidden;
    }

 
  }

  .action{
    width: 100%;
    display: flex;
    margin-top: 2rem;

    button{
      flex: 1;
      color: ${({ theme }) => theme.colors.dark.cardText};

      padding: 1rem;
      transition: .25s ease-in;

      &:hover{
        transform: scale(.9);
      }

      &:nth-child(1) {
        background-color: #3EC70B;
        margin-right: 1rem;
      }

      &:nth-child(2) {
        background-color:#E64848
      }
    }
  }

  
`

export {
  OrderContainer,
  SubModalContainer
}