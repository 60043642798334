import styled from "styled-components";

export const SearchFilteringContainer = styled.div`

.filter-container{
    background:${({ theme }) => theme.colors.dark.main};
    display: block; 
    padding: 12px;
    margin-bottom: 1rem;
    border-radius: 0.7rem;
    align-items: center;
    justify-content: center;
    
    > .form-container{
      display: flex;
      gap: 2rem;
     

      > input{
        width: 100%;
      }

      > select{
        width: 100%;
      }
      > button{
      width: 56px;
      min-width: 48px;
      padding: 6px;
      border-radius: 0.8rem;
      margin-bottom: 0.5rem;
      will-change: transform;

      &:hover {
      transform: translateY(-2px);}
      &:active {
      box-shadow: none;
      transform: translateY(0);
        
    }  } 
      > .search-button{
        background: #0096FF;
      }

      > .add-button{
        background: #EB1D36;
      }

      > .dropdownCardapio{
        margin-left: 0;
        .order-button{
          
          background: #A66CFF;
        }
      }
     

       
    }
    
  }
`