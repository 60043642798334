import { ShippedOrder } from '../../../../types/orders'
import OrderTableRow from './orderTableRow/orderTableRow'
import { OrderTableContainer } from './styles'

type orderTableprops = {
  items: any
  title: string
  headers: any
  row: any
  handleSingleExpeditionOrder: (order: ShippedOrder) => void
  callMotorCycle: (order: ShippedOrder) => void
  cancelMotorCycle: (order: ShippedOrder) => void
  handleOrderSelection: (order: ShippedOrder) => void
  selectedOrders: ShippedOrder[]
}

function OrderTable({
  items,
  title,
  headers,
  row,
  handleSingleExpeditionOrder,
  callMotorCycle,
  cancelMotorCycle,
  handleOrderSelection,
  selectedOrders
}: orderTableprops) {
  return (
    <OrderTableContainer>
      <div className="table-card thematic-scroll">
        <div className="heading">
          <p>{title}</p>
        </div>

        <table>
          <thead>
            {headers.map((header: any) => (
              <th
                key={header}
                className={header === 'Ações' ? 'actionsTh' : ''}
              >
                {header}
              </th>
            ))}
          </thead>
          <tbody>
            {items.map((item: any, index: any) => (
              <OrderTableRow
                key={index}
                item={item}
                rowheader={row}
                handleSingleExpeditionOrder={handleSingleExpeditionOrder}
                callMotorCycle={callMotorCycle}
                cancelMotorCycle={cancelMotorCycle}
                handleOrderSelection={handleOrderSelection}
                selectedOrders={selectedOrders}
              />
            ))}
          </tbody>
        </table>
      </div>
    </OrderTableContainer>
  )
}

export default OrderTable
