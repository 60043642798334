import { useState } from 'react'
import SubModal from '../../../components/Order/components/SubOrderModal'
import agile from '../../../assets/images/logo/icone-agile-branco.png'
import ifood from '../../../assets/images/svg/ifood-43.svg'
type ApproveModalProps = {
  handleApproveOrder: (timestamp: string) => void
  close: () => void
  requestCode: string
  ifoodIcon?: boolean
}

function ApproveModal({
  handleApproveOrder,
  close,
  requestCode,
  ifoodIcon
}: ApproveModalProps) {
  const [interval, setInterval] = useState<string>('00:15')

  return (
    <SubModal
      title={`Aprovar Pedido Nº ${requestCode}`}
      heading="Informe o tempo estimado para Entrega/Retirada"
      confirm={() => handleApproveOrder(interval)}
      close={close}
      svg={ifoodIcon ? ifood : agile}
    >
      <label>Tempo Entrega/Retirada</label>
      <input
        type="time"
        className="form-control"
        value={interval}
        onChange={e => setInterval(e.target.value)}
      />
    </SubModal>
  )
}

export default ApproveModal
