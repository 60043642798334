import { Building, CalendarCheck2, Edit, Sunrise, Sunset } from 'lucide-react'
import React from 'react'
import ProfileModal from '../ProfileModal/profileModal'
import { useState } from 'react'
import defaultLogo from '../../../../assets/images/logo/defaultlogo.png'
type StoreItemProps = {
  storeData: any
  updateAPI: () => void
}

function StoreItem({ storeData, updateAPI }: StoreItemProps) {
  const [editStore, setEditStore] = useState<boolean>(false)

  const InformationsAboutStore = [
    {
      id: 1,
      className:
        storeData.LojaAberta === 'SIM'
          ? 'store-status opened'
          : 'store-status closed',
      label: storeData.LojaAberta === 'SIM' ? 'Aberta Agora' : 'Fechada',
      icon: <Building color="#f3f4f8" />,
      style: {}
    },
    {
      id: 2,
      className:
        storeData.AbreHoje === 'SIM'
          ? 'store-status opened'
          : 'store-status closed',
      label: storeData.AbreHoje === 'SIM' ? 'Abre Hoje' : 'Não Abre Hoje',
      icon: <CalendarCheck2 color="#f3f4f8" />,
      style: storeData.LojaAberta === 'SIM' ? { display: 'none' } : {}
    },
    {
      id: 3,
      className: 'store-status altgreen',
      label: ` Abre às ${storeData.HorarioAtendimentoInicial}`,
      icon: <Sunrise color="#f3f4f8" />,
      style: storeData.AbreHoje === 'NAO' ? { display: 'none' } : {}
    },
    {
      id: 4,
      className: 'store-status closed',
      label: ` Fecha às ${storeData.HorarioAtendimentoFinal}`,
      icon: <Sunset color="#f3f4f8" />,
      style: storeData.AbreHoje === 'NAO' ? { display: 'none' } : {}
    }
  ]
  return (
    <>
      <tr key={storeData.Id}>
        <td>
          <div>
            <div className="list-img">
              <img
                src={storeData.LogoUri || defaultLogo}
                alt={storeData.nome}
              />
            </div>

            <div className="text-container">
              <h2>
                {storeData.Sigla} {storeData.Nome} - {storeData.CodigoExterno}
              </h2>
              <p>
                {storeData.Endereco.Log}
                {storeData.Endereco.Numero},{storeData.Endereco.Bai.d}
                {storeData.cidade}/{storeData.Endereco.Uf}
              </p>
            </div>
            <div className="store-information">
              {InformationsAboutStore.map(info => (
                <div className={info.className} style={info.style}>
                  {info.icon}
                  <p>{info.label}</p>
                </div>
              ))}
            </div>

            <div className="actions-container">
              <button onClick={() => setEditStore(true)}>
                <Edit color="#f3f4f8" />
              </button>
            </div>
          </div>
        </td>
      </tr>
      {editStore && (
        <ProfileModal
          obj={storeData}
          close={() => setEditStore(false)}
          updateAPI={updateAPI}
        />
      )}
    </>
  )
}

export default StoreItem
