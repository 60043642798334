import ReactDOM from 'react-dom'

import Overlay from '../Overlay'
import { OverlayNotificationContainer } from './styles'

type OverlayNotificationProps = {
  text: string,
  close: () => void
}

function OverlayNotification({ text, close }: OverlayNotificationProps) {
  const errorPortal = document.getElementById('error-portal')

  if (!errorPortal) return null

  return ReactDOM.createPortal((
    <Overlay highZIndex>
      <OverlayNotificationContainer>
        <p>{text}</p>
        <div className="action">
          <button type="button" onClick={close}>Fechar</button>
        </div>
      </OverlayNotificationContainer>
    </Overlay>
  ), errorPortal)
}
export default OverlayNotification