/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { Sheet } from 'lucide-react'
import { Search } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { default as ErrorComponent } from '../../components/OverlayNotification'
import PageCard from '../../components/PageCard'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import { RootState } from '../../store/store'
import { FinancialRequest } from '../../types/financial'
import { FinancesContainer } from './styles'
import { CSVLink } from 'react-csv'
import DateSelector from '../../components/DateSelector'
import useDateInterval from '../../hooks/useDateInterval'
import Table, { rowHeaderItem } from '../../components/Table'

function Finances() {
  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )

  const [financialReport, setFinancialReport] = useState<FinancialRequest>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [selectedStoreCode, setSelectedStoreCode] =
    useState<number>(licenseStoreCode)

  const firstRender = useRef(true)

  const [
    initialDateValue,
    setInitialDateValue,
    finalDateValue,
    setFinalDateValue
  ] = useDateInterval()

  async function handleGetFinancialReport() {
    const env = process.env.REACT_APP_FINANCIAL_REPORT
    if (!env || !userToken || !selectedEnvironment.Url) return

    setIsLoading(true)

    const url = `${selectedEnvironment.Url}/${env}/${selectedStoreCode}/${initialDateValue}/${finalDateValue}`

    try {
      const response = await axios({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      const data: FinancialRequest = response.data

      setFinancialReport(data)

      setIsLoading(false)
    } catch (err: any) {
      const error =
        err?.response?.data?.Messages?.[0] ||
        'Algum erro ocorreu durante a obtenção dos dados'

      setIsLoading(false)
      setError(error)
    }
  }

  // handle first get financial report
  useEffect(() => {
    if (initialDateValue && finalDateValue && firstRender.current) {
      handleGetFinancialReport()

      firstRender.current = false
    }
  }, [initialDateValue, finalDateValue, handleGetFinancialReport])

  const tableHeadItems: string[] = [
    'Linha',
    'Canal',
    'Meio de Venda',
    'Data',
    'Pedido',
    'SubTotal',
    'Desconto',
    'Taxa de Entrega',
    'Total R$',
    'PG Online',
    '% Comissão Agile',
    'Comissão Agile R$',
    'Taxa Transação Online',
    'Valor Líquido'
  ]

  const tableRowHeaders: rowHeaderItem[] = [
    {
      value: 'CANAL'
    },
    {
      value: 'MEIO_VENDA'
    },
    {
      value: 'DATA',
      format: 'date'
    },
    {
      value: 'PEDIDO'
    },
    {
      value: 'SUBTOTAL_PEDIDO',
      format: 'currency'
    },
    {
      value: 'DESCONTO',
      format: 'currency'
    },
    {
      value: 'TAXA_ENTREGA',
      format: 'currency'
    },
    {
      value: 'VALOR_TOTAL',
      format: 'currency'
    },
    {
      value: 'PAGO_ONLINE'
    },
    {
      value: 'PERCENTUAL_COMISSAO_AGILE',
      format: 'percentage'
    },
    {
      value: 'VALOR_COMISSAO_AGILE',
      format: 'currency'
    },
    {
      value: 'VALOR_TAXA_TRANSACAO_ONLINE',
      format: 'currency'
    },
    {
      value: 'VALOR_LIQUIDO',
      format: 'currency'
    }
  ]

  return (
    <PageCard title="Financeiro">
      <FinancesContainer>
        <DateSelector
          initialDateValue={initialDateValue}
          setInitialDateValue={setInitialDateValue}
          finalDateValue={finalDateValue}
          setFinalDateValue={setFinalDateValue}
          setStore={(storeCode: number) => setSelectedStoreCode(storeCode)}
          selectedStore={selectedStoreCode}
        >
          <>
            {financialReport && financialReport.length > 0 && (
              <CSVLink
                data={financialReport}
                filename={`AgileRelatorio-${initialDateValue}-ate-${finalDateValue}.csv`}
              >
                <Sheet color="#f3f4f8" />
              </CSVLink>
            )}
            <button
              type="button"
              className="search-button"
              onClick={handleGetFinancialReport}
            >
              <Search color="#f3f4f8" />
            </button>
          </>
        </DateSelector>
        {financialReport && financialReport.length > 0 ? (
          <>
            <Table
              items={financialReport}
              headItems={tableHeadItems}
              rowHeaders={tableRowHeaders}
              noIndex={false}
              noCanal={true}
              needTotal
            />
          </>
        ) : (
          <p className="message">
            Não existem registros no intervalo selecionado.
          </p>
        )}
      </FinancesContainer>
      {isLoading && <ScaleLoader />}
      {error && <ErrorComponent text={error} close={() => setError('')} />}
    </PageCard>
  )
}
export default Finances
