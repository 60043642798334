/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { toggleDarkFilm } from '../../store/features/generic/genericSlice'
import { AppDispatch, RootState } from '../../store/store'
import Header from './components/Header'
import { Sidebar } from './components/Sidebar'
import { PageWrapperContainer } from './styles'

function PageWrapper() {
  const dispatch = useDispatch<AppDispatch>()
  const { pageDarkFilm } = useSelector((state: RootState) => state.generic)

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

  const [pageWidth, setPageWidth] = useState<number>(0)

  useEffect(() => {
    setPageWidth(window.innerWidth)

    function handlePageResize(e: UIEvent) {
      const target = e.target as Window

      setPageWidth(target.innerWidth)
    }

    window.addEventListener('resize', handlePageResize)

    return () => {
      window.removeEventListener('resize', handlePageResize)
    }
  }, [])

  useEffect(() => {
    const resizeToDesktopWithDarkFilmOpen = pageWidth > 978 && pageDarkFilm
    const resizeToMobileWithDarkFilmClosed =
      pageWidth <= 978 && !pageDarkFilm && isSidebarOpen

    if (resizeToDesktopWithDarkFilmOpen || resizeToMobileWithDarkFilmClosed) {
      dispatch(toggleDarkFilm())
    }
  }, [pageWidth, dispatch, pageDarkFilm, isSidebarOpen])

  function toggleSidebar() {
    setIsSidebarOpen(prevState => !prevState)

    if (pageWidth <= 978) {
      dispatch(toggleDarkFilm())
    }
  }

  return (
    <PageWrapperContainer>
      <Sidebar isOpen={isSidebarOpen} close={toggleSidebar} />
      <div className="page_content">
        <div id="top" />
        <Header openSidebar={toggleSidebar} />
        <Outlet />
      </div>
    </PageWrapperContainer>
  )
}
export default PageWrapper
