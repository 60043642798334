import { Menu, User } from 'lucide-react'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../store/store'
import { HeaderContainer } from './styles'
import { Moon, Sun } from 'lucide-react'
import { toggleTheme } from '../../../../store/features/theme/themeSlice'
import { useDispatch } from 'react-redux'

type HeaderProps = {
  openSidebar: () => void
}

function Header({ openSidebar }: HeaderProps) {
  const { userData } = useSelector((state: RootState) => state.auth)
  const { theme } = useSelector((state: RootState) => state.theme)

  const dispatch = useDispatch<AppDispatch>()

  function handleChangeTheme() {
    dispatch(toggleTheme())
  }

  return (
    <HeaderContainer className="card-box-shadow">
      <button type="button" onClick={openSidebar}>
        <Menu />
      </button>

      <div className="theme-switcher">
        <button onClick={handleChangeTheme}>
          {theme === 'dark' ? <Sun /> : <Moon />}
        </button>
      </div>

      <div className="options">
        <div className="profile-info">
          <div className="user-info">
            <p>{userData.Name}</p>
          </div>
          <User size={32} />
        </div>
      </div>
    </HeaderContainer>
  )
}

export default Header
