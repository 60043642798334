/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react'
import PageCard from '../../components/PageCard'
import { ExpeditionMapContainer } from './styles'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store/store'
import { Deliveryman, ShippedOrder } from '../../types/orders'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import ExpeditionModal from './components/ExpeditionModal'
import {
  ErrorAlert,
  SuccessAlert
} from '../../components/SweetAlert/SweetAlert'
import Map from './components/Map'
import { useDispatch } from 'react-redux'
import { getExpeditionOrders } from '../../store/features/expedition/expeditionSlice'
import { numberMask } from '../../utils/masks'
import DateSelector from '../../components/DateSelector'
import { rowHeaderItem } from '../../components/Table'
import NoItems from '../../components/NoItems'
import OrderTable from './components/orderTable/orderTable'
import Swal from 'sweetalert2'
import { getFrota } from '../../store/features/auth/authSlice'

function ExpeditionMap() {
  const {
    selectedEnvironment,
    licenseStoreCode,
    userToken,
    accessToken,
    storeList
  } = useSelector((state: RootState) => state.auth)
  const {
    expeditionOrders,
    isLoading: isExpeditionLoading,
    firstRequest
  } = useSelector((state: RootState) => state.expedition)

  const [hasIntegration, setHasIntegration] = useState<boolean>()
  const [selectedStoreCode, setSelectedStoreCode] =
    useState<number>(licenseStoreCode)

  const dispatch = useDispatch<AppDispatch>()
  const [selectedOrders, setSelectedOrders] = useState<ShippedOrder[]>([])

  const [singleExpeditionOrder, setSingleExpeditionOrder] =
    useState<ShippedOrder>()

  const [deliverymanList, setDeliverymanList] = useState<Deliveryman[]>([])

  const [isDeliverymanListLoading, setIsDeliverymanListLoading] =
    useState<boolean>(false)
  const [isExpeditionActionLoading, setIsExpeditionActionLoading] =
    useState<boolean>(false)

  const [isExpeditionModalOpen, setIsExpeditionModalOpen] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  function handleUpdate() {
    setSingleExpeditionOrder(undefined)
    setIsExpeditionModalOpen(false)
    handleGetExpeditionOrderMapInfo()
  }

  const handleGetExpeditionOrderMapInfo = useCallback(async () => {
    if (!selectedStoreCode || !selectedEnvironment?.Url || !userToken) return

    const getExpeditionOrdersProps = {
      storeCode: selectedStoreCode,
      url: selectedEnvironment.Url,
      token: userToken
    }

    dispatch(getExpeditionOrders(getExpeditionOrdersProps))
  }, [selectedEnvironment?.Url, selectedStoreCode, userToken, dispatch])

  const handleGetDeliverymanList = useCallback(async () => {
    const env = process.env.REACT_APP_DELIVERYMAN_LIST

    if (!env || !selectedEnvironment?.Url || !selectedStoreCode || !userToken)
      return

    setIsDeliverymanListLoading(true)

    const response = await axios({
      method: 'GET',
      url: `${selectedEnvironment.Url}/${env}/${selectedStoreCode}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    setIsDeliverymanListLoading(false)
    setDeliverymanList(response.data)
  }, [selectedEnvironment?.Url, selectedStoreCode, userToken])

  async function handleExpedition(
    tip: string,
    selectedDeliveryman: Deliveryman
  ) {
    const env = process.env.REACT_APP_EXPEDITION

    if (!env || !selectedEnvironment?.Url || !licenseStoreCode || !userToken)
      return

    setIsExpeditionActionLoading(true)

    try {
      const expeditionData = {
        CodigoEntregador: selectedDeliveryman?.Codigo,
        ValorTroco: singleExpeditionOrder
          ? Number(numberMask(tip)) / 100 || 0
          : 0,
        CodigosPedidos: singleExpeditionOrder
          ? [singleExpeditionOrder.CodigoSeq]
          : selectedOrders.map(order => order.CodigoSeq)
      }

      await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        },
        data: expeditionData
      })

      setIsExpeditionActionLoading(false)
      SuccessAlert('Sucesso', 'Pedido(s) expedido(s) com sucesso', handleUpdate)
    } catch (err: any) {
      const message =
        err?.response?.data?.Messages?.[0] ||
        'Algum erro ocorreu durante a expedição do(s) pedido(s)'

      setIsExpeditionActionLoading(false)
      ErrorAlert('Erro', message)
    }
  }

  function handleToggleSelectedOrder(order: ShippedOrder) {
    const isOrderSelected = selectedOrders.find(
      selectedOrder => selectedOrder.CodigoSeq === order.CodigoSeq
    )

    if (isOrderSelected) {
      setSelectedOrders(prevState =>
        prevState.filter(
          selectedOrder => selectedOrder.CodigoSeq !== order.CodigoSeq
        )
      )
    } else {
      setSelectedOrders(prevState => [...prevState, order])
    }
  }

  function handleToggleOrderByCode(code: number) {
    const order = expeditionOrders.find(order => order.CodigoSeq === code)

    if (!order) return

    handleToggleSelectedOrder(order)
  }

  function handleCloseExpeditionModal() {
    setIsExpeditionModalOpen(false)
  }

  function handleSingleExpeditionOrder(order: any) {
    if (order?.hashCodigoEntrega !== '') {
      Swal.fire({
        title:
          'Este pedido está sendo entregue pelo operador logístico e ainda não foi finalizado. Deseja realmente realizar a expedição deste pedido?',
        icon: 'warning',
        background: '#283046',
        color: '#f3f4f8',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#CF303C',
        showCancelButton: true,
        showCloseButton: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          setSingleExpeditionOrder(order)
        } else {
          return
        }
      })
    } else {
      setSingleExpeditionOrder(order)
    }
  }

  async function expeditionEventProcessing() {
    const env = process.env.REACT_APP_HUSKY_EVENT_PROCESSING

    if (!env || !selectedEnvironment?.Url || !selectedStoreCode || !accessToken)
      return

    try {
      await axios({
        method: 'POST',
        url: `${selectedEnvironment.Url}/${env}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    } finally {
    }
  }

  const handleGetFrota = useCallback(() => {
    dispatch(
      getFrota({
        url: selectedEnvironment.Url,
        token: accessToken
      })
    )
  }, [dispatch, selectedEnvironment, accessToken])

  useEffect(() => {
    handleGetExpeditionOrderMapInfo()
    handleGetDeliverymanList()

    const interval = setInterval(handleGetExpeditionOrderMapInfo, 10000)

    return () => clearInterval(interval)
  }, [handleGetExpeditionOrderMapInfo, handleGetDeliverymanList])

  useEffect(() => {
    handleGetFrota()

    const interval = setInterval(() => {
      expeditionEventProcessing()
      handleGetFrota()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!storeList) return

    const selectedStore = storeList.find(
      store => store.CodigoExterno === licenseStoreCode
    )
    const HasIntegrationParam = selectedStore?.Parametros?.find(
      (item: any) => item.Chave === 'MV_POSSUIINTEGRACAOIJUMP'
    )
    if (expeditionOrders?.length > 0) {
      if (HasIntegrationParam?.Valor === 'SIM') {
        setHasIntegration(true)
      } else return
    }
  }, [storeList, expeditionOrders])

  async function callMotorCycle(order: ShippedOrder) {
    Swal.fire({
      title: `O Pedido ${order?.CodigoDia} ${order?.CodigoSeq} será entregue para o Operador logistico, deseja prosseguir? `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_HUSKY_CALLMOTORCYCLE

        if (
          !env ||
          !selectedEnvironment?.Url ||
          !selectedStoreCode ||
          !accessToken ||
          !order
        )
          return

        try {
          await axios({
            method: 'POST',
            url: `${selectedEnvironment.Url}/${env}/${order.CodigoSeq}`,
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          SuccessAlert(
            'Sucesso',
            `Pedido ${order.CodigoDia} - ${order.CodigoSeq} entregue para a empresa de logistica.`
          )
          handleGetExpeditionOrderMapInfo()
          expeditionEventProcessing()
        } finally {
          setIsLoading(false)
        }
      }
    })
  }

  async function cancelMotorCycle(order: ShippedOrder) {
    Swal.fire({
      title: ' Deseja realmente cancelar a chamada deste entregador?',
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_HUSKY_CANCELMOTORCYCLE

        if (
          !env ||
          !selectedEnvironment?.Url ||
          !selectedStoreCode ||
          !accessToken ||
          !order
        )
          return

        try {
          await axios({
            method: 'DELETE',
            url: `${selectedEnvironment.Url}/${env}/${order.CodigoSeq}`,
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          SuccessAlert(
            'Sucesso',
            `A atribuição do Pedido ${order.CodigoDia} - ${order.CodigoSeq} para o operador logístico foi cancelada. `
          )
          handleGetExpeditionOrderMapInfo()
          expeditionEventProcessing()
        } finally {
          setIsLoading(false)
        }
      } else {
        return
      }
    })
  }

  const ToExpedictTableHeadItemsWithoutLogistics: string[] = [
    'Tempo fila (min)',
    'Pedido e Codigo Sequencia',
    'Data',
    'Situação',
    'Valor Pedido',
    'Taxa',
    'Valor Total',
    'Nome Cliente',
    'Telefone',
    'Contatar Cliente',
    'Endereço Cliente',
    'Ações'
  ]

  const ToExpedictTableRowHeadersWithoutLogistics: rowHeaderItem[] = [
    {
      value: 'TempoEspera'
    },
    {
      sub: 'CodigoDia',
      value: 'CodigoSeq'
    },
    { value: 'DataHora', hour: 'HoraPedido', format: 'date' },
    { value: 'Pago' },
    { value: 'ValorSubTotal', format: 'currency' },
    { value: 'ValorTaxa', format: 'currency' },
    { value: 'ValorTotal', format: 'currency' },
    { value: 'NomeCliente' },
    { value: 'Telefone', ddd: 'DDD', format: 'tel' },
    { value: 'Telefone', ddd: 'DDD', format: 'whats' },
    { value: 'EnderecoCliente', neighbourhood: 'Bairro', format: 'address' }
  ]

  const ToExpedictTableHeadItems: string[] = [
    'Tempo fila (min)',
    'Pedido e Codigo Sequencia',
    'Data',
    'Situação',
    'Valor Pedido',
    'Taxa',
    'Valor Total',
    'Nome Cliente',
    'Telefone',
    'Contatar Cliente',
    'Endereço Cliente',
    'Entregador Atribuido',
    'Status',
    'Ações'
  ]

  const ToExpedictTableRowHeaders: rowHeaderItem[] = [
    {
      value: 'TempoEspera'
    },
    {
      sub: 'CodigoDia',
      value: 'CodigoSeq'
    },
    { value: 'DataHora', hour: 'HoraPedido', format: 'date' },
    { value: 'Pago' },
    { value: 'ValorSubTotal', format: 'currency' },
    { value: 'ValorTaxa', format: 'currency' },
    { value: 'ValorTotal', format: 'currency' },
    { value: 'NomeCliente' },
    { value: 'Telefone', ddd: 'DDD', format: 'tel' },
    { value: 'Telefone', ddd: 'DDD', format: 'whats' },
    { value: 'EnderecoCliente', neighbourhood: 'Bairro', format: 'address' },

    { value: 'strNomeEntregadorLogi' },
    { value: 'strStatusLogi' }
  ]
  return (
    <PageCard title="Mapa de Expedição">
      <ExpeditionMapContainer>
        <div className="content">
          <div className="map-wrapper">
            <Map
              selectedOrders={selectedOrders}
              selectByCode={handleToggleOrderByCode}
            />
          </div>
        </div>
        <div className="filter-container">
          <DateSelector
            noDate
            setStore={(storeCode: number) => setSelectedStoreCode(storeCode)}
            selectedStore={selectedStoreCode}
            showFrotaCard
          />
        </div>
        <div className="expedition-orders">
          {expeditionOrders?.length > 0 ? (
            <>
              <OrderTable
                title={'Pedidos à Expedir'}
                items={expeditionOrders}
                headers={
                  hasIntegration
                    ? ToExpedictTableHeadItems
                    : ToExpedictTableHeadItemsWithoutLogistics
                }
                row={
                  hasIntegration
                    ? ToExpedictTableRowHeaders
                    : ToExpedictTableRowHeadersWithoutLogistics
                }
                handleSingleExpeditionOrder={(order: ShippedOrder) =>
                  handleSingleExpeditionOrder(order)
                }
                callMotorCycle={(order: ShippedOrder) => callMotorCycle(order)}
                cancelMotorCycle={(order: ShippedOrder) =>
                  cancelMotorCycle(order)
                }
                handleOrderSelection={(order: ShippedOrder) =>
                  handleToggleSelectedOrder(order)
                }
                selectedOrders={selectedOrders}
              />
            </>
          ) : (
            <>
              <NoItems title={'Nenhum pedido para expedir'} mt={'1rem'} />
            </>
          )}
        </div>

        {isExpeditionModalOpen && (
          <ExpeditionModal
            orders={selectedOrders}
            deliverymanList={deliverymanList}
            close={handleCloseExpeditionModal}
            action={handleExpedition}
            noTip
          />
        )}
        {singleExpeditionOrder && (
          <ExpeditionModal
            orders={[singleExpeditionOrder]}
            deliverymanList={deliverymanList}
            close={() => setSingleExpeditionOrder(undefined)}
            action={handleExpedition}
          />
        )}
        {(isDeliverymanListLoading ||
          isExpeditionActionLoading ||
          (isExpeditionLoading && firstRequest)) && <ScaleLoader />}
      </ExpeditionMapContainer>
      {isLoading && <ScaleLoader />}
    </PageCard>
  )
}

export default ExpeditionMap
