/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import PageCard from '../../components/PageCard'
import SearchFiltering from '../../components/SearchFiltering'
import ComplementFamily from './components/family/complementFamily'
import { MenuComplementsContainer } from './styles'
import NoItems from '../../components/NoItems'
import ComplementsSideModal from './components/complementsModal/ComplementsModal'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import axios from 'axios'
import {
  ErrorAlert,
  SuccessAlert
} from '../../components/SweetAlert/SweetAlert'
import Swal from 'sweetalert2'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'

function MenuComplements() {
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [ActiveFilter, setActiveFilter] = useState<string>('AZ')
  const [searchByKeyWord, setSearchByKeyword] = useState<string>('')
  const [addFamily, setAddFamily] = useState<boolean>(false)
  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )
  const [complementos, setComplementos] = useState<any[]>([])
  const [selectedStoreCode, setSelectedStoreCode] =
    useState<number>(licenseStoreCode)

  const [filteredComplements, setFilteredComplements] = useState<any[]>([])

  useEffect(() => {
    const searchTerm = searchByKeyWord.toLowerCase()

    setFilteredComplements(
      complementos
        .filter(
          (item: any) =>
            item?.DescricaoCategoria.toLowerCase().includes(searchTerm) ||
            item?.Complementos.find((complement: any) =>
              complement?.Nome.toLowerCase().includes(searchTerm)
            )
        )
        .map((item: any) =>
          item?.DescricaoCategoria.toLowerCase().includes(searchTerm)
            ? item
            : {
                ...item,
                Complementos: item.Complementos.filter((item: any) =>
                  item?.Nome.toLowerCase().includes(searchTerm)
                )
              }
        )
    )
  }, [searchByKeyWord, complementos])

  useEffect(() => {
    handleGetComplementosItems()
  }, [])

  async function handleGetComplementosItems() {
    const env = process.env.REACT_APP_COMPLEMENTS_LIST_GET
    if (!env || !userToken || !selectedEnvironment.Url) return

    setIsLoading(true)
    const url = `${selectedEnvironment.Url}/${env}/${selectedStoreCode}`

    try {
      const response = await axios({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      const data = response.data

      setComplementos(data)
    } catch (err: any) {
      const error =
        err?.response?.data?.Messages?.[0] ||
        'Algum erro ocorreu durante a obtenção dos dados'

      ErrorAlert('Atenção', error)
    } finally {
      setIsLoading(false)
    }
  }

  /*
  async function handleDuplicateComplementFamily(itemInfo: any) {
    Swal.fire({
      title: `Deseja Duplicar o Grupo de Complementos: ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_COMPLEMENT_CHANGE_FAMILY

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/duplicar/${licenseStoreCode}/${itemInfo.CodigoCategoria}`

        try {
          await axios({
            method: 'POST',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Familia Complementos ${itemInfo.CodigoCategoria} ${itemInfo.DescricaoCategoria} foi duplicada com sucesso.`
          )
          handleGetComplementosItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Duplicação do grupo de complementos ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} cancelada. `
        )
      }
    })
  }
  */

  async function handleDeleteComplementFamily(itemInfo: any) {
    Swal.fire({
      title: `Deseja excluir o Grupo de Complementos: ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_COMPLEMENT_CHANGE_FAMILY

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${itemInfo.CodigoCategoria}`

        try {
          await axios({
            method: 'DELETE',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Familia Complementos ${itemInfo.CodigoCategoria} ${itemInfo.DescricaoCategoria} foi excluida com sucesso.`
          )
          handleGetComplementosItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Exclusão do grupo de complementos ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} foi cancelada.`
        )
      }
    })
  }

  async function handleDuplicateComplementItem(itemInfo: any) {
    Swal.fire({
      title: `Deseja Duplicar o item de Complementos: ${itemInfo.Codigo} -  ${itemInfo.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_COMPLEMENT_CHANGE_ITEM

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/duplicar/${licenseStoreCode}/${itemInfo.Codigo}`

        try {
          await axios({
            method: 'POST',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Item Complemento ${itemInfo.Codigo} ${itemInfo.Nome} foi duplicado com sucesso.`
          )
          handleGetComplementosItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Duplicação do item de complemento ${itemInfo.Codigo} -  ${itemInfo.Nome} cancelado. `
        )
      }
    })
  }

  async function handleDeleteComplementItem(itemInfo: any) {
    Swal.fire({
      title: `Deseja excluir o item de Complementos: ${itemInfo.Codigo} -  ${itemInfo.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_COMPLEMENT_CHANGE_ITEM

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${itemInfo.Codigo}`

        try {
          await axios({
            method: 'DELETE',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Item Complemento ${itemInfo.Codigo} ${itemInfo.Nome} foi excluido com sucesso.`
          )
          handleGetComplementosItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Exclusão do item de complementos ${itemInfo.Codigo} -  ${itemInfo.Nome} foi cancelado.`
        )
      }
    })
  }

  async function handleChangeComplementFamilyStatus(
    itemInfo: any,
    status: string
  ) {
    Swal.fire({
      title: `Deseja ${status} a familia de complementos: ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_COMPLEMENT_CHANGE_FAMILY

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/status/${licenseStoreCode}/${itemInfo.CodigoCategoria}`

        try {
          await axios({
            method: 'PUT',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Familia de complementos ${itemInfo.CodigoCategoria} ${
              itemInfo.DescricaoCategoria
            } foi ${status === 'Pausar' ? 'pausada' : 'ativada'} com sucesso.`
          )
          handleGetComplementosItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Mudança de Status da Familia de Complementos  ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} foi cancelada.`
        )
      }
    })
  }

  async function handleChangeComplementItemStatus(
    itemInfo: any,
    status: string
  ) {
    Swal.fire({
      title: `Deseja ${status} o item de complementos: ${itemInfo.Codigo} -  ${itemInfo.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_COMPLEMENT_CHANGE_ITEM

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/status/${licenseStoreCode}/${itemInfo.Codigo}`

        try {
          await axios({
            method: 'PUT',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Item de complementos ${itemInfo.Codigo} ${itemInfo.Nome} foi ${
              status === 'Pausar' ? 'pausado' : 'ativado'
            } com sucesso.`
          )
          handleGetComplementosItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Mudança de Status do item de complementos ${itemInfo.Codigo} -  ${itemInfo.Nome} foi cancelada.`
        )
      }
    })
  }

  return (
    <PageCard title={'Complementos'}>
      <MenuComplementsContainer>
        <SearchFiltering
          inputPlaceholder={'Buscar nos grupos de complementos...'}
          inputValue={searchByKeyWord}
          onChangeInputFunction={(value: any) => setSearchByKeyword(value)}
          ButtonAddFamilyFunction={() => setAddFamily(true)}
          ButtonAddTitle={'Adicionar'}
          OpenFilterOptionsFunction={() => setFilterOpen(!filterOpen)}
          FilterOpenState={filterOpen}
          FilterState={ActiveFilter}
          functionChangeFilter={(value: string) => setActiveFilter(value)}
          setStore={(storeCode: number) => setSelectedStoreCode(storeCode)}
          selectedStore={selectedStoreCode}
          getList={() => handleGetComplementosItems()}
        />
        {filteredComplements && filteredComplements.length > 0 ? (
          filteredComplements.map((itemFamily: any) => (
            <ComplementFamily
              key={itemFamily.CodigoCategoria}
              complementos={complementos}
              complementFamilyObj={itemFamily}
              orderBy={ActiveFilter}
              //Duplica Familia de Complemento
              // handleDuplicateComplementFamily={(complementInfo: any) =>
              //   handleDuplicateComplementFamily(complementInfo)
              // }
              //Deleta Familia de Complemento
              handleDeleteComplementFamily={(complementInfo: any) =>
                handleDeleteComplementFamily(complementInfo)
              }
              //Duplica item de complemento
              handleDuplicateComplementItem={(complementInfo: any) =>
                handleDuplicateComplementItem(complementInfo)
              }
              //Deleta item de complemento
              handleDeleteComplementItem={(complementInfo: any) =>
                handleDeleteComplementItem(complementInfo)
              }
              //Mudar Status familia de complemento
              handleChangeComplementFamilyStatus={(
                complementInfo: any,
                status: string
              ) => handleChangeComplementFamilyStatus(complementInfo, status)}
              //Mudar Status item de complemento
              handleChangeComplementItemStatus={(
                complementInfo: any,
                status: string
              ) => handleChangeComplementItemStatus(complementInfo, status)}
              //Refresh
              handleGetComplementosItems={handleGetComplementosItems}
            />
          ))
        ) : (
          <NoItems title={'Nenhum Item de Complementos Encontrado !'} />
        )}
      </MenuComplementsContainer>
      {addFamily && (
        <ComplementsSideModal
          complementos={complementos}
          HeaderTitle={'Adicionar Grupo de Complementos'}
          isAddGroup
          close={() => setAddFamily(false)}
          handleGetComplementosItems={handleGetComplementosItems}
        />
      )}
      {isLoading && <ScaleLoader />}
    </PageCard>
  )
}

export default MenuComplements
