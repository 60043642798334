export default function formatDateandHour(value: any) {
  const fakeData = "1900-01-01T00:00:00" || '' || null;
  if (value === fakeData) {
    return ''
  }
  return new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    day: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }).format(new Date(value))
}