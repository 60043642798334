/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { Copy, Plus } from 'lucide-react'
import { useMemo, useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import NoItems from '../../../../../components/NoItems'
import { RootState } from '../../../../../store/store'
import ComplementsItem from './ComplementsItem'
import CreateEditModal from './CreateEditModal'
import CloneModal from './technicalModal/cloneModal/cloneModal'
import {
  ErrorAlert
} from '../../../../../components/SweetAlert/SweetAlert'

type TechnicalFileProps = {
  FichaTecnica: any
  setFichaTecnica1: (obj: any) => void
  replaceFicha: (obj: any, tamanhoId: number, isEdit: boolean) => void
  deleteFicha: (obj: any, codTamanho: number) => void
  cloneSizesFunction: (origem: any, destino: any) => void
  handleChangeBorda: (data: any, bordaId: number, listaBordas: any) => void
  handleChangeMassa: (data: any, massaId: number, listaMassas: any) => void
  handleChangeAdicional: (
    data: any,
    adicionalId: number,
    listAdicionais: any
  ) => void
}

function TechnicalFile({
  FichaTecnica,
  replaceFicha,
  deleteFicha,
  cloneSizesFunction,
  handleChangeBorda,
  handleChangeMassa,
  handleChangeAdicional
}: TechnicalFileProps) {
  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )

  const [selectedTechnicalFileCode, setSelectedTechnicalFileCode] = useState(
    FichaTecnica?.length > 0 ? FichaTecnica[0]?.CodigoTamanho : ''
  )

  const [itemToBeEddited, setItemToBeEddited] = useState<any>()

  const [isCreateComplementModalOpen, setIsCreateComplementModalOpen] =
    useState<boolean>(false)
  const [isEditComplementModalOpen, setIsEditComplementModalOpen] =
    useState<boolean>(false)

  const [massas, setMassas] = useState([])
  const [bordas, setBordas] = useState([])
  const [adicionais, setAdicionais] = useState([])

  const selectedTechnicalFile = useMemo(
    () =>
      FichaTecnica.find(
        (Ficha: any) => Ficha.CodigoTamanho === selectedTechnicalFileCode
      ),

    [FichaTecnica, selectedTechnicalFileCode]
  )

  const selectedMassa = useMemo(() => (
    selectedTechnicalFile?.FamiliaTipoMassa?.CodigoFamilia || ''
  ), [selectedTechnicalFile])

  const selectedBorda = useMemo(() => (
    selectedTechnicalFile?.FamiliaBordas?.CodigoFamilia || ''
  ), [selectedTechnicalFile])

  const selectedAdicionais = useMemo(() => (
    selectedTechnicalFile?.FamiliaAdicionais?.CodigoFamilia || ''
  ), [selectedTechnicalFile])

  const handleChange = (idTamanho: any) => {
    const selectedTechnicalFile = FichaTecnica?.find(
      (file: any) => file?.CodigoTamanho === idTamanho
    )

    setSelectedTechnicalFileCode(selectedTechnicalFile?.CodigoTamanho)
  }

  function handleEditModal(obj: any) {
    setItemToBeEddited(obj)
    setIsEditComplementModalOpen(true)
  }

  function handleCloseModal() {
    setIsCreateComplementModalOpen(false)
    setIsEditComplementModalOpen(false)
    setItemToBeEddited({})
  }

  function createComplement(newOutros: any) {
    if (
      selectedTechnicalFile?.OutrosComplementos?.some(
        (item: any) => item.Codigo === newOutros.Codigo
      )
    ) {
      ErrorAlert(
        'Atenção',
        `A listagem já contém o item ${newOutros.Codigo} - ${newOutros.Nome}`
      )
    } else {
      replaceFicha(newOutros, selectedTechnicalFileCode, false)
      // SuccessAlert('Sucesso', `Complemento ${newOutros.Nome} foi adicionado.`)
      handleCloseModal()
    }
  }

  function updateComplement(obj: any) {
    replaceFicha(obj, selectedTechnicalFileCode, true)
    // SuccessAlert('Sucesso', `Complemento ${obj.Nome} foi editado.`)
    handleCloseModal()
  }

  const [cloneModalOpen, setCloneModalOpen] = useState<boolean>(false)

  async function getMassas() {
    const env = process.env.REACT_APP_CARDAPIO_TECHNICAL_FILE_MASSAS_GET
    if (!env || !selectedEnvironment.Url || !userToken) return

    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setMassas(response.data)
    } catch (e) { }
  }

  async function getBordas() {
    const env = process.env.REACT_APP_CARDAPIO_TECHNICAL_FILE_BORDAS_GET
    if (!env || !selectedEnvironment.Url || !userToken) return

    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setBordas(response.data)
    } catch (e) { }
  }

  async function getAdicionais() {
    const env = process.env.REACT_APP_CARDAPIO_TECHNICAL_FILE_ADICIONAIS_GET
    if (!env || !selectedEnvironment.Url || !userToken) return

    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setAdicionais(response.data)
    } catch (e) { }
  }

  // async function getMeioaMeio() {
  //   const env = process.env.REACT_APP_CARDAPIO_TECHNICAL_FILE_MEIOAMEIO_GET
  //   if (!env || !selectedEnvironment.Url || !userToken) return

  //   try {
  //     const response = await axios({
  //       method: 'GET',
  //       url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
  //       headers: {
  //         Authorization: `Bearer ${userToken}`
  //       }
  //     })
  //     setMeioaMeio(response.data)
  //   } catch (e) {}
  // }

  useEffect(() => {
    getAdicionais()
    getBordas()
    getMassas()
    // getMeioaMeio()
  }, [])

  return (
    <>
      <div className="container-technicalFile">
        <>
          <div className="technical-card">
            <div>
              <label htmlFor="Tamanhos">Tamanhos</label>
              <select
                id="Tamanho Origem"
                className="form-control"
                onChange={e => handleChange(parseInt(e.target.value))}
              >
                {FichaTecnica === undefined || FichaTecnica.length < 1 ? (
                  <option>Nenhum Tamanho cadastrado</option>
                ) : (
                  ''
                )}
                {FichaTecnica?.map((tamanho: any, index: number) => (
                  <option value={tamanho.CodigoTamanho} key={index}>
                    {tamanho.NomeTamanho}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="technical-clone-btn"
              onClick={() => setCloneModalOpen(true)}
              disabled={FichaTecnica === undefined || FichaTecnica.length < 1}
              title={
                FichaTecnica === undefined ? 'Não há Tamanhos para clonar' : ''
              }
            >
              <Copy color="#f3f4f8" />
            </button>
          </div>

          {FichaTecnica !== undefined && FichaTecnica.length > 0 ? (
            <>
              <div className="technical-card" id="massa">
                <div>
                  <label htmlFor={'massa'}>Tipos de Massa</label>
                  <select
                    className="form-control"
                    id={'massa'}
                    value={selectedMassa}
                    onChange={e =>
                      handleChangeMassa(
                        selectedTechnicalFile,
                        Number(e.target.value),
                        massas
                      )
                    }
                  >
                    <option value=''>Nenhum</option>
                    {massas.map((massa: any) => (
                      <option key={massa.Codigo} value={massa.Codigo}>
                        {massa.Nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="technical-card" id="Bordas">
                <div>
                  <label htmlFor={'Bordas'}>Tipos de Bordas</label>
                  <select
                    className="form-control"
                    id={'Bordas'}
                    value={selectedBorda}
                    onChange={e =>
                      handleChangeBorda(
                        selectedTechnicalFile,
                        Number(e.target.value),
                        bordas
                      )
                    }
                  >
                    <option value=''>Nenhum</option>
                    {bordas.map((borda: any) => (
                      <option key={borda.Codigo} value={borda.Codigo}>
                        {borda.Nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="technical-card" id="Adicionais">
                <div>
                  <label htmlFor={'Adicionais'}>Tipos de Adicionais</label>
                  <select
                    className="form-control"
                    id={'Adicionais'}
                    value={selectedAdicionais}
                    onChange={e =>
                      handleChangeAdicional(
                        selectedTechnicalFile,
                        Number(e.target.value),
                        adicionais
                      )
                    }
                  >
                    <option value=''>Nenhum</option>
                    {adicionais.map((adicional: any) => (
                      <option key={adicional.Codigo} value={adicional.Codigo}>
                        {adicional.Nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <>
                <div className="technical-crud-card">
                  <h1>Outros Complementos</h1>
                  <button onClick={() => setIsCreateComplementModalOpen(true)}>
                    <Plus color="#f3f4f8" />
                  </button>
                </div>

                {selectedTechnicalFile?.OutrosComplementos != null &&
                  selectedTechnicalFile?.OutrosComplementos?.length > 0 ? (
                  <div className="technical-crud-table" id="OUTROS">
                    <table>
                      <thead>
                        <tr>
                          <td id="name">Nome</td>
                          <td>Qtde Minima</td>
                          <td>Qtde Máxima</td>
                          <td>Ações</td>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedTechnicalFile?.OutrosComplementos.map(
                          (outros: any) => (
                            <ComplementsItem
                              obj={outros}
                              editAction={() => handleEditModal(outros)}
                              sizeSelected={selectedTechnicalFile}
                              deleteFicha={deleteFicha}
                            />
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoItems
                    title={'Nenhum Item de outros complementos para exibir'}
                  />
                )}
              </>
            </>
          ) : (
            <>
              <NoItems title={'Nenhum Tamanho cadastrado nesse item.'} />
              <div className="noItems"></div>
            </>
          )}
        </>
      </div>

      {(isCreateComplementModalOpen || isEditComplementModalOpen) && (
        //Adicionar ou editar complementos
        <CreateEditModal
          close={handleCloseModal}
          action={
            isCreateComplementModalOpen ? createComplement : updateComplement
          }
          editObj={itemToBeEddited}
        />
      )}

      {cloneModalOpen && (
        <CloneModal
          changeSizeSelected={(value: any) => handleChange(value)}
          close={() => setCloneModalOpen(false)}
          sizeSelected={selectedTechnicalFile}
          fichaTecnica={FichaTecnica}
          cloneSizesFunction={cloneSizesFunction}
        />
      )}
    </>
  )
}

export default TechnicalFile
