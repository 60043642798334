import styled from 'styled-components';

const ExpeditionModalContainer = styled.div`
  width: 88%;  
  max-width: 40rem;

  border-radius: 1rem;

  .header {
    position: relative;

    background: ${({ theme }) => theme.colors.dark.text};
    color: ${({ theme }) => theme.colors.dark.background};

    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;

    text-align: center;

    padding: .4rem 1rem;

    font-size: 1.4rem;

    button {
      position: absolute;
      right: .4rem;
      top: .4rem;

      border-top-right-radius: 1rem;

      svg {
        color: ${({ theme }) => theme.colors.dark.background};
      }
    }
  }

  .content {
    background: ${({ theme }) => theme.colors.dark.background};
    color: ${({ theme }) => theme.colors.dark.text};

    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    padding: 1rem;
  }

  .info {
    margin-bottom: 2rem;

    font-size: 1.2rem;

    p {
      margin: .6rem 0;

      font-weight: bolder;

      span {
        color: #ec9006;

        margin-left: 1rem;

        font-weight: 500;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      background: ${({ theme }) => theme.colors.dark.text};
      border-radius: .6rem;
      color: ${({ theme }) => theme.colors.dark.background};

      padding: .4rem 1rem;

      &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  span {
    font-size: 1.2rem;
  }

  .field {
    width: 100%;

    input, select {
      width: 100%;

      margin: .4rem 0 1rem;
    }
  }

  .error-message {
    color: #ff0000;

    display: block;

    margin: -.6rem 0 1rem;
  }
`

const OrderCardContainer = styled.div`
  background: ${({ theme }) => theme.colors.dark.background};
  border-radius: .6rem;

  width: 100%;

  margin-bottom: 1rem;

  &.active {
    background: red;
  }

  .card-header {
    background: ${({ theme }) => theme.colors.dark.text};
    color: ${({ theme }) => theme.colors.dark.background};

    border-top-right-radius: .6rem;
    border-top-left-radius: .6rem;
  }

  > div {
    padding: .4rem 1rem;
  }

  h2 {
    text-align: left;
  }

  p {
    font-size: 1.2rem;
  }

  h2, p {
    margin: .4rem 0;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    margin: .4rem 0;
  }
`

const ExpeditionMapContainer = styled.div`
  height: 100%;

  .expedition-orders{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .content {
    height: 45%;

    display: flex;

    > div {
      height: 100%;
    }
  }

  .map-wrapper {
    flex: 7;
  }

  .actions {
    margin-bottom: 1rem;

    display: flex;

    button:first-child {
      margin-right: 1rem;
    }

    button:nth-child(2) {
      background: ${({ theme }) => theme.colors.dark.background};
      color: ${({ theme }) => theme.colors.dark.text};
    }

    @media (max-width: 1260px) {
      /* flex-direction: row; */

      button {
        padding: 1rem 0;
      }

      button:first-child {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  button {
    background: ${({ theme }) => theme.colors.dark.text};
    color: ${({ theme }) => theme.colors.dark.background};

    border-radius: .6rem;
    padding: .4rem 1rem;

    transition: transform .14s ease-in;

    &:hover {
      transform: scale(.95);
    }
  }

  .request-list {
    height: 100%;
    overflow-y: auto;

    padding-right: .6rem;

    flex: 3;

    margin-left: 2rem;

    .error-alert {
      margin-top: 1.4rem;

      font-size: 1.4rem;
      text-align: center;

      height: 85%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        margin-top: 1rem;
      }
    }
  }
`

export {
  ExpeditionModalContainer,
  OrderCardContainer,
  ExpeditionMapContainer
}