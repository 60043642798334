import {
  ChevronUp,
  MoreVertical,
  Plus,
  Edit2,
  Trash2,
  AlertTriangle
} from 'lucide-react'
import { useState } from 'react'

import SideModal from '../sideModal/sideModal'
import CategoryItem from './categoryItem/categoryItem'
import { CategoryCard } from './styles'

type CategoriesProps = {
  category: any
  fullObj: any[]
  itemFilterActive: string
  handleDuplicateCardapioCategory?: (categoryInfo: any) => void
  handleDeleteCardapioCategory: (categoryInfo: any) => void
  handleDuplicateCardapioItem: (itemInfo: any) => void
  handleDeleteCardapioItem: (itemInfo: any) => void
  handleChangeCategoryStatus: (itemInfo: any, status: string) => void
  handleChangeCategoryItemStatus: (itemInfo: any, status: string) => void
  handleGetCardapioItems: () => void
}
function Categories({
  category,
  fullObj,
  itemFilterActive,
  handleDuplicateCardapioCategory,
  handleDeleteCardapioCategory,
  handleDuplicateCardapioItem,
  handleDeleteCardapioItem,
  handleChangeCategoryStatus,
  handleChangeCategoryItemStatus,
  handleGetCardapioItems
}: CategoriesProps) {
  const [hideChildren, sethideChildren] = useState<boolean>(true)
  const [activeActions, setActiveActions] = useState<boolean>(false)
  const [openSideModal, setOpenSideModal] = useState<boolean>(false)
  const [editCategory, setEditCategory] = useState<boolean>(false)

  const {
    CodigoCategoria,
    DescricaoCategoria,
    Cardapios,
    PodeMeioMeio,
    Sequencia,
    Ativo
  } = category

  const getActiveFilter = (
    itemFilterActive: string,
    arg1: any,
    arg2: any
  ): any => {
    if (itemFilterActive === 'AZ') {
      return arg1?.Nome > arg2?.Nome ? 1 : -1
    } else if (itemFilterActive === 'ZA') {
      return arg1?.Nome < arg2?.Nome ? 1 : -1
    } else if (itemFilterActive === 'Paused') {
      return arg1?.Ativo ? 1 : -1
    } else if (itemFilterActive === 'Activated') {
      return arg1?.Ativo === false ? 1 : -1
    }
  }

  return (
    <>
      <CategoryCard>
        <div className="category-card">
          <h3>{DescricaoCategoria}</h3>
          <div className="actions">
            <button
              className={!Ativo ? 'pause pause-active' : 'pause'}
              onClick={() => handleChangeCategoryStatus(category, 'Pausar')}
              disabled={!Ativo}
            >
              {!Ativo ? 'Pausado' : 'Pausar'}
            </button>
            <button
              className={Ativo ? 'actived actived-active' : 'actived'}
              onClick={() => handleChangeCategoryStatus(category, 'Ativar')}
              disabled={Ativo}
            >
              {Ativo ? 'Ativado' : 'Ativar'}
            </button>
            <div className="actions-svg">
              <button
                key={CodigoCategoria}
                onClick={() => sethideChildren(!hideChildren)}
                className={!hideChildren ? 'inactive' : 'active'}
                disabled={Cardapios.length < 1}
                title={Cardapios.length < 1 ? 'Não há itens para mostrar' : ''}
              >
                <ChevronUp color="#f3f4f8" />
              </button>
              <div className="dropdownCardapio">
                <button
                  className={activeActions ? 'dropbtn active1' : 'dropbtn'}
                  onClick={() => setActiveActions(!activeActions)}
                >
                  <MoreVertical color="#f3f4f8" />
                  {activeActions && (
                    <div className="dropdown-content">
                      <button onClick={() => setEditCategory(true)}>
                        <Edit2 color="#f3f4f8" />
                        <abbr>Editar</abbr>
                      </button>
                      <button
                        onClick={() => handleDeleteCardapioCategory(category)}
                      >
                        <Trash2 color="#f3f4f8" />
                        <abbr>Excluir</abbr>
                      </button>
                      {/* <button
                        onClick={() =>
                          handleDuplicateCardapioCategory(category)
                        }
                      >
                        <Copy color="#f3f4f8" />
                        <abbr>Duplicar</abbr>
                      </button> */}
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {hideChildren && Cardapios.length > 0 && (
          <div className="children">
            <table>
              <thead>
                <tr className="thead-children">
                  <th>Item</th>
                  <th>Status de vendas</th>
                </tr>
              </thead>

              <tbody>
                {Cardapios.sort((a: any, b: any) =>
                  getActiveFilter(itemFilterActive, a, b)
                )
                  // .filter((item: any) =>
                  //   item.Nome.toLowerCase().includes(keyWordSearch)
                  // )

                  .map((children: any) => (
                    <>
                      <CategoryItem
                        key={children.id}
                        children={children}
                        fullObj={fullObj}
                        handleDuplicateCardapioItem={
                          handleDuplicateCardapioItem
                        }
                        handleDeleteCardapioItem={handleDeleteCardapioItem}
                        handleChangeCategoryItemStatus={
                          handleChangeCategoryItemStatus
                        }
                        handleGetCardapioItems={handleGetCardapioItems}
                      />
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="addItem-container">
          <p>
            {' '}
            {Cardapios.length === 0 ? (
              <>
                <AlertTriangle color="red" />
                Nenhum Item Cadastrado
              </>
            ) : (
              ''
            )}
          </p>
          <button onClick={() => setOpenSideModal(true)}>
            <Plus color="#f3f4f8" />
            Adicionar Item
          </button>
        </div>
      </CategoryCard>
      {openSideModal && (
        //adicionar item//
        <SideModal
          addItem
          sequence={Sequencia}
          fullObj={fullObj}
          title={'Adicionar Item'}
          caption={'Detalhes do Novo Item:'}
          close={() => setOpenSideModal(false)}
          item={category}
          handleGetCardapioItems={handleGetCardapioItems}
        />
      )}
      {editCategory && (
        //Editar categoria//
        <SideModal
          middle={PodeMeioMeio}
          editCategory
          addCategory
          fullObj={fullObj}
          id={CodigoCategoria}
          title={DescricaoCategoria}
          sequence={Sequencia}
          caption={'Detalhes da Categoria'}
          close={() => setEditCategory(false)}
          item={category}
          handleGetCardapioItems={handleGetCardapioItems}
        />
      )}
    </>
  )
}
export default Categories
