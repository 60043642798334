import formatBRL from '../../../../../utils/formatBRL'

import { ShippedOrder } from '../../../../../types/orders'

import formatDate from '../../../../../utils/formatDate'
import Zap from '../../../../../assets/images/svg/whats.svg'

type OrderTableRowProps = {
  item: any
  rowheader: any
  handleSingleExpeditionOrder: (order: ShippedOrder) => void
  callMotorCycle: (order: ShippedOrder) => void
  cancelMotorCycle: (order: ShippedOrder) => void
  handleOrderSelection: (order: ShippedOrder) => void
  selectedOrders: ShippedOrder[]
}

function OrderTableRow({
  item,
  rowheader,
  handleSingleExpeditionOrder,
  callMotorCycle,
  cancelMotorCycle,
  handleOrderSelection,
  selectedOrders
}: OrderTableRowProps) {

  return (
    <tr
      onDoubleClick={() => handleOrderSelection(item)}
      style={
        selectedOrders.map(order => order?.CodigoSeq).includes(item?.CodigoSeq)
          ? { background: '#FF4A4A' }
          : { background: '' }
      }
    >
      {rowheader.map((row: any) => {
        let value = item[row.value]
        let sub = item[row.sub]
        let neighbourhood = item[row?.neighbourhood]
        let hour = item[row?.hour]
        let ddd = item[row?.ddd]
        if (row.format === 'currency') {
          value = formatBRL(value)
        }
        if (row.format === 'date') {
          value = formatDate(value)
          return (
            <td key={row.value}>
              {value || '00/00/0000'} {hour || '00:00'}
            </td>
          )
        }
        if (row.format === 'tel') {
          if (value === null || row.ddd === null) {
            return <td key={row.value}>Não Informado</td>
          }
          let telefone = `(${ddd || '00'}) 
          ${
            value?.toString().substring(0, 4) +
              '-' +
              value?.toString().substring(4, 9) || '0'
          }`

          return <td key={row.value}>{telefone}</td>
        }

        if (row.format === 'whats') {
          if (value != null && row.ddd) {
            let sub = item[row.ddd]

            value = (
              <a
                target={'_blank'}
                href={`https://api.whatsapp.com/send?phone=55${`${sub}${value}`}`}
                rel="noreferrer"
              >
                <img height={25} width={25} src={Zap} alt="whats" />
              </a>
            )
          } else {
            value = (
              <img
                height={25}
                width={25}
                src={Zap}
                alt="whats"
                style={{ opacity: 0.5, cursor: 'not-allowed' }}
                title="Telefone do cliente não disponivel"
              />
            )
          }
        }

        if (row.format === 'address') {
          return (
            <td key={row.value}>
              {value || ''} - {neighbourhood}
            </td>
          )
        }
        if (row?.sub) {
          return (
            <td key={row.value}>
              {sub} - {value || ''}
            </td>
          )
        } else {
          return <td key={row.value}>{value || ''}</td>
        }
      })}

      <td>
        <div className="actions">
          <button
            className="expedition-button"
            onClick={() => handleSingleExpeditionOrder(item)}
          >
            Expedir
          </button>

          {item?.PossuiLogisticaIntegrada === 'SIM' &&
            item?.hashCodigoEntrega === '' && (
              <button
                className="motorcycle-notCalled"
                onClick={() => callMotorCycle(item)}
              >
                Chamar Moto
              </button>
            )}

          {item?.PossuiLogisticaIntegrada === 'SIM' &&
            item?.hashCodigoEntrega !== '' && (
              <button
                className="motorcycle-called"
                onClick={() => cancelMotorCycle(item)}
              >
                Cancelar Moto
              </button>
            )}
        </div>
      </td>
    </tr>
  )
}
export default OrderTableRow
