/* eslint-disable react-hooks/exhaustive-deps */

import { useRef, useState } from 'react'
import Switch from 'react-switch'
import indisponivel from '../../../../../assets/images/generic/indisponivel.png'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import ScaleLoader from '../../../../../components/ScaleLoader/ScaleLoader'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import { ErrorAlert } from '../../../../../components/SweetAlert/SweetAlert'

type DetailProps = {
  item: any
  addItem: boolean
  fullObj: any
  getError: (key: string) => any,
  codCategoria: number
  setCodCategoria: (num: number) => void
  NomePrato: string
  setNomePrato: (nome: string) => void
  saidaImpressao: string
  setSaidaImpressao: (saida: string) => void
  oferta: string
  setOferta: (oferta: string) => void
  sugestaoChef: string
  setSugestaoChef: (sugestao: string) => void
  descricaoProduto: string
  setDescricaoProduto: (descricao: string) => void
}
function Detail({
  item,
  addItem,
  fullObj,
  getError,
  codCategoria,
  setCodCategoria,
  NomePrato,
  setNomePrato,
  saidaImpressao,
  setSaidaImpressao,
  oferta,
  setOferta,
  sugestaoChef,
  setSugestaoChef,
  descricaoProduto,
  setDescricaoProduto
}: DetailProps) {
  const [apiImage, setApiImage] = useState<any>(
    item?.FotoUri != null ? item?.FotoUri : indisponivel || indisponivel
  )

  const [image, setImage] = useState<File>()
  const fileInputRef = useRef<HTMLInputElement>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )

  const [outSourceExitArr, setOutSourceExitArr] = useState([])

  async function GetOutsourceExit() {
    const env = process.env.REACT_APP_CARDAPIO_DETAIL_SAIDAIMPRESSAO_GET
    if (!env || !selectedEnvironment.Url || !userToken) return

    setIsLoading(true)
    try {
      const response = await axios({
        method: 'GET',
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      setOutSourceExitArr(response.data)
      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setApiImage(reader.result as string)

        handleImageBase64(reader.result as string)
      }

      reader.readAsDataURL(image)
    }
  }, [image])

  useEffect(() => {
    if (!outSourceExitArr || outSourceExitArr.length === 0 || saidaImpressao) return

    setSaidaImpressao(outSourceExitArr[0])
  }, [outSourceExitArr])

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/jpeg': [] },
    onDrop: (acceptedFiles: any) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        setApiImage(reader.result as string)
        handleImageBase64(reader.result as string)
      }
      reader.readAsDataURL(acceptedFiles[0])
    }
  })

  useEffect(() => {
    GetOutsourceExit()
  }, [])

  const handleImageBase64 = async (base64: string) => {
    const env = process.env.REACT_APP_CARDAPIO_ITEM_IMG_UPLOAD_POST
    if (base64 === '') {
      ErrorAlert('Ops', 'Falha ao selecionar a imagem, tente novamente !')
      return
    }

    try {
      setIsLoading(true)
      const payload = {
        Type: 'item',
        ContentType: 'image/jpg',
        Base64: base64
      }

      await axios({
        method: 'POST',
        data: payload,
        url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${item.Codigo}`,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      
    } catch (err: any) {
      ErrorAlert('Erro', err)
    }

    setIsLoading(false)
  }

  return (
    <>
      <div className="addItem-container">
        <div className="addItem-form">
          <div className="field-group">
            <label>Categoria</label>
            <select
              className="form-control"
              value={codCategoria}
              onChange={e => setCodCategoria(Number(e.target.value))}
            >
              {fullObj?.map((category: any, index: number) => (
                <option value={category?.CodigoCategoria} key={index}>
                  {category.DescricaoCategoria}
                </option>
              ))}
            </select>
            <p className="error">{getError('codCategoria')?.message}</p>
          </div>
          <div className="field-group">
            <label> Nome do Prato</label>
            <input
              type="text"
              className="form-control"
              placeholder="Exemplo: Pizza de Calabresa Especial."
              value={NomePrato}
              onChange={e => setNomePrato(e.target.value)}
            />
            <p className="error">{getError('nomePrato')?.message}</p>
          </div>
          {/* <label> Codigo PDV</label>
        <input type="text" className="form-control" /> */}
          <div className="field-group">
            <label> Saida de Impressão</label>
            <select
              className="form-control"
              value={saidaImpressao}
              onChange={e => setSaidaImpressao(e.target.value)}
            >
              {outSourceExitArr.map((outsource, index) => (
                <option value={outsource} key={index}>
                  {outsource}
                </option>
              ))}
            </select>
            <span className="error">{getError('saidaImpressao')?.message}</span>
          </div>
          <div className="switch-container">
            <div>
              <label htmlFor="Promoção">Promoção?</label>
              <Switch
                name="Promoção"
                onChange={e => setOferta(e === true ? 'SIM' : 'NAO')}
                checked={oferta === 'NAO' ? false : true}
                offColor="#dc0000"
              />
            </div>
            <div>
              <label htmlFor="Promoção">Sugestão do Chef?</label>
              <Switch
                onChange={e => setSugestaoChef(e === true ? 'SIM' : 'NAO')}
                checked={sugestaoChef === 'NAO' ? false : true}
                offColor="#dc0000"
              />
            </div>
          </div>
        </div>
        {
          !addItem && (
            <div className="addItem-img">
              <div className="img-container" {...getRootProps()}>
                <img
                  // apiImage ? apiImage :
                  src={apiImage}
                  alt={item?.Nome}
                  onError={(e: any) => {
                    e.target.src = indisponivel
                  }}
                  // itemTitle ? itemTitle :
                />
              </div>
              <div className="crud-addimg">
                <label htmlFor="img">Enviar arquivo </label>
                <input
                  {...getInputProps()}
                  type="file"
                  name="img"
                  id="img"
                  accept="image/jpeg"
                  ref={fileInputRef as any}
                  onChange={(event: any) => {
                    const file = event?.target?.files[0]
                    if (file && file.type.substr(0, 5) === 'image') {
                      setImage(file)
                    } else {
                      setImage(undefined)
                    }
                  }}
                />

                <button
                  onClick={() => setApiImage(indisponivel)}
                  disabled={apiImage === indisponivel}
                >
                  Remover
                </button>
              </div>
            </div>
          )
        }
      </div>
      <div className="addItem-description">
        <label>Descrição do Item</label>
        <textarea
          cols={5}
          rows={10}
          placeholder="Aqui você pode personalizar a descrição do seu prato."
          className="form-control"
          value={descricaoProduto}
          onChange={e => setDescricaoProduto(e.target.value)}
        ></textarea>
        {isLoading && <ScaleLoader />}
      </div>
    </>
  )
}

export default Detail
