import styled from "styled-components";

const OrderTableContainer = styled.div`
  width: 100%;
  height: 100%;
 

  .table-card{
    padding: 20px;
    border-radius: 0.7rem;
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
    overflow: auto;

    > .heading{
      display: flex;
      width: 100%;
      box-shadow: 0px 1px 10px #000;
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      background-color: #6F38C5;
      font-size: 16px;
      text-align: center;
      justify-content: space-around;

      > p{
        color: #f2f2f2;
        margin-left: auto;
        margin-right: auto;
      }
      > .actions{
        justify-self: right;
        > button{
          height: 16px;
        }
      }
 }
   
  }

  table {
    width: 100%;
    background: ${({ theme }) => theme.colors.dark.main};
    color: #f3f4f8;
    width: 100%;
    text-align: center;
    border: 0;
    border-collapse: collapse;

    th, td{
      text-align: center;
      padding: 0.5rem;
      border: 1px solid;
    }

  thead{
    > .actionsTh{
      width: 20%;


    }
  }
    /* thead tr {
      cursor: default;

      &:hover {
        background-color: initial;
      }
      
    } */

    tbody{
     > tr{
      width: 100%;

      > td {
        
        > .actions{
          justify-content: center;
          display: flex;
          align-content: center;
         
          > .expedition-button{
            font-size: 10px;
          background: #6F38C5;
          color: #f2f2f2;
        }
        > .motorcycle-notCalled{
          font-size: 10px;
          background: #6F38C5;
          color: #f2f2f2;
        }
        > .motorcycle-called{
          font-size: 10px;
          background: #EB4747;
          color: #f2f2f2;
        }
        }
      
      }
     }
    }

    tr {
      cursor: pointer;

      &.active {
        background-color: #554994;
      }

      &:hover {
        background-color: #554994;  
      }
    }
    
  }

  @media (max-width: 1262px) {
    table{
      > tbody{
        > tr {
          > td{
            > .actions{
              flex-direction: column;
              width: 100%;
            }
          }
        }
      }
    }
  }

`

export { OrderTableContainer }