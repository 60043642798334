/* eslint-disable react-hooks/exhaustive-deps */

import PageCard from '../../components/PageCard'
import { MenuRegistrationContainer } from './styles'
import Categories from './components/categories/categories'
import { useState, useEffect } from 'react'
import SideModal from './components/sideModal/sideModal'
import NoItems from '../../components/NoItems'
import SearchFiltering from '../../components/SearchFiltering'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import {
  ErrorAlert,
  SuccessAlert
} from '../../components/SweetAlert/SweetAlert'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import axios from 'axios'
import Swal from 'sweetalert2'

function MenuRegistration() {
  const [addCategory, setAddCategory] = useState<boolean>(false)
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [ActiveFilter, setActiveFilter] = useState<string>('AZ')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cardapioItem, setCardapioItem] = useState([])
  const [filteredMenuItems, setFilteredMenuItems] = useState<any[]>([])

  const [keyWordSearch, setKeyWordSearch] = useState('')

  const { selectedEnvironment, licenseStoreCode, userToken } = useSelector(
    (state: RootState) => state.auth
  )
  const [selectedStoreCode, setSelectedStoreCode] =
    useState<number>(licenseStoreCode)

  useEffect(() => {
    setActiveFilter(ActiveFilter)
  }, [ActiveFilter])

  useEffect(() => {
    const searchTerm = keyWordSearch.toLowerCase()

    setFilteredMenuItems(
      cardapioItem
        ?.sort((a: any, b: any) => {
          return a?.Sequencia > b?.Sequencia ? 1 : -1
        })
        ?.filter(
          (item: any) =>
            item.DescricaoCategoria.toLowerCase().includes(searchTerm) ||
            item.CodigoCategoria.toString().includes(searchTerm) ||
            item.Cardapios.find((item: any) =>
              item?.Nome.toLowerCase().includes(searchTerm)
            )
        )
        ?.map((item: any) =>
          item.DescricaoCategoria.toLowerCase().includes(searchTerm) ||
          item.CodigoCategoria.toString().includes(searchTerm)
            ? item
            : {
                ...item,
                Cardapios: item.Cardapios.filter((item: any) =>
                  item?.Nome.toLowerCase().includes(searchTerm)
                )
              }
        )
    )
  }, [keyWordSearch, cardapioItem])

  useEffect(() => {
    handleGetCardapioItems()
  }, [])

  async function handleGetCardapioItems() {
    const env = process.env.REACT_APP_CARDAPIO_LIST_GET
    if (!env || !userToken || !selectedEnvironment.Url) return

    setIsLoading(true)

    const url = `${selectedEnvironment.Url}/${env}/${selectedStoreCode}`

    try {
      const response = await axios({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })

      const data = response.data

      setCardapioItem(data)

      setIsLoading(false)
    } catch (err: any) {
      const error =
        err?.response?.data?.Messages?.[0] ||
        'Algum erro ocorreu durante a obtenção dos dados'

      setIsLoading(false)
      ErrorAlert('Atenção', error)
    }
  }

  // async function handleDuplicateCardapioCategory(itemInfo: any) {
  //   Swal.fire({
  //     title: `Deseja Duplicar a Categoria do Cardapio: ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} `,
  //     icon: 'warning',
  //     background: '#283046',
  //     color: '#f3f4f8',
  //     confirmButtonText: 'Sim',
  //     cancelButtonText: 'Cancelar',
  //     cancelButtonColor: '#CF303C',
  //     showCancelButton: true,
  //     showCloseButton: true,
  //     allowOutsideClick: () => !Swal.isLoading()
  //   }).then(async result => {
  //     if (result.isConfirmed) {
  //       setIsLoading(true)
  //       const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_FAMILY

  //       if (
  //         !env ||
  //         !selectedEnvironment.Url ||
  //         !userToken ||
  //         !licenseStoreCode ||
  //         !itemInfo
  //       )
  //         return

  //       const url = `${selectedEnvironment.Url}/${env}/duplicar/${licenseStoreCode}/${itemInfo.CodigoCategoria}`

  //       try {
  //         await axios({
  //           method: 'POST',
  //           url,
  //           headers: {
  //             Authorization: `Bearer ${userToken}`
  //           }
  //         })

  //         SuccessAlert(
  //           'Sucesso',
  //           `Categoria do Cardapio ${itemInfo.CodigoCategoria} ${itemInfo.DescricaoCategoria} foi duplicada com sucesso.`
  //         )
  //         handleGetCardapioItems()
  //       } finally {
  //         setIsLoading(false)
  //       }
  //     } else {
  //       ErrorAlert(
  //         'Atenção',
  //         `Duplicação da categoria do Cardapio ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} cancelada. `
  //       )
  //     }
  //   })
  // }

  async function handleDeleteCardapioCategory(itemInfo: any) {
    Swal.fire({
      title: `Deseja excluir a Categoria do Cardapio: ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_FAMILY

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${itemInfo.CodigoCategoria}`

        try {
          await axios({
            method: 'DELETE',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Categoria do Cardapio ${itemInfo.CodigoCategoria} ${itemInfo.DescricaoCategoria} foi excluida com sucesso.`
          )
          handleGetCardapioItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Exclusão da categoria do cardapio ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} foi cancelada.`
        )
      }
    })
  }

  async function handleDuplicateCardapioItem(itemInfo: any) {
    Swal.fire({
      title: `Deseja Duplicar o item: ${itemInfo.Codigo} -  ${itemInfo.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_ITEM

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/duplicar/${licenseStoreCode}/${itemInfo.Codigo}`

        try {
          await axios({
            method: 'POST',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Item ${itemInfo.Codigo} ${itemInfo.Nome} foi duplicado com sucesso.`
          )
          handleGetCardapioItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Duplicação do item de complemento ${itemInfo.Codigo} -  ${itemInfo.Nome} cancelado. `
        )
      }
    })
  }

  async function handleDeleteCardapioItem(itemInfo: any) {
    Swal.fire({
      title: `Deseja excluir o item: ${itemInfo.Codigo} -  ${itemInfo.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_ITEM

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${itemInfo.Codigo}`

        try {
          await axios({
            method: 'DELETE',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Item ${itemInfo.Codigo} ${itemInfo.Nome} foi excluido com sucesso.`
          )
          handleGetCardapioItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Exclusão do item  ${itemInfo.Codigo} -  ${itemInfo.Nome} foi cancelada.`
        )
      }
    })
  }

  async function handleChangeCategoryStatus(itemInfo: any, status: string) {
    Swal.fire({
      title: `Deseja ${status} a categoria: ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_FAMILY

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/status/${licenseStoreCode}/${itemInfo.CodigoCategoria}`

        try {
          await axios({
            method: 'PUT',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Item ${itemInfo.CodigoCategoria} ${
              itemInfo.DescricaoCategoria
            } foi ${status === 'Pausar' ? 'pausada' : 'ativada'} com sucesso.`
          )
          handleGetCardapioItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Mudança de Status da Categoria  ${itemInfo.CodigoCategoria} -  ${itemInfo.DescricaoCategoria} foi cancelada.`
        )
      }
    })
  }

  async function handleChangeCategoryItemStatus(itemInfo: any, status: string) {
    Swal.fire({
      title: `Deseja ${status} o item: ${itemInfo.Codigo} -  ${itemInfo.Nome} `,
      icon: 'warning',
      background: '#283046',
      color: '#f3f4f8',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#CF303C',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const env = process.env.REACT_APP_CARDAPIO_ADD_NEW_ITEM

        if (
          !env ||
          !selectedEnvironment.Url ||
          !userToken ||
          !licenseStoreCode ||
          !itemInfo
        )
          return

        const url = `${selectedEnvironment.Url}/${env}/status/${licenseStoreCode}/${itemInfo.Codigo}`

        try {
          await axios({
            method: 'PUT',
            url,
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          })

          SuccessAlert(
            'Sucesso',
            `Item ${itemInfo.Codigo} ${itemInfo.Nome} foi ${
              status === 'Pausar' ? 'pausado' : 'ativado'
            } com sucesso.`
          )
          handleGetCardapioItems()
        } finally {
          setIsLoading(false)
        }
      } else {
        ErrorAlert(
          'Atenção',
          `Mudança de Status do item  ${itemInfo.Codigo} -  ${itemInfo.Nome} foi cancelada.`
        )
      }
    })
  }
  return (
    <PageCard title="Cardápio">
      <MenuRegistrationContainer>
        <SearchFiltering
          inputPlaceholder={'Buscar por Categoria ou sequência'}
          inputValue={keyWordSearch}
          onChangeInputFunction={(value: any) => setKeyWordSearch(value)}
          ButtonAddFamilyFunction={() => setAddCategory(true)}
          ButtonAddTitle={'Adicionar'}
          OpenFilterOptionsFunction={() => setFilterOpen(!filterOpen)}
          FilterOpenState={filterOpen}
          FilterState={ActiveFilter}
          functionChangeFilter={(value: string) => setActiveFilter(value)}
          setStore={(storeCode: number) => setSelectedStoreCode(storeCode)}
          selectedStore={selectedStoreCode}
          getList={() => handleGetCardapioItems()}
        />
        {filteredMenuItems && filteredMenuItems.length > 0 ? (
          filteredMenuItems.map((category: any) => (
            <>
              <Categories
                key={category?.CodigoCategoria}
                category={category}
                fullObj={cardapioItem}
                itemFilterActive={ActiveFilter}
                //Duplicar categoria
                // handleDuplicateCardapioCategory={(categoryInfo: any) =>
                //   handleDuplicateCardapioCategory(categoryInfo)
                // }
                //excluir categoria
                handleDeleteCardapioCategory={(categoryInfo: any) =>
                  handleDeleteCardapioCategory(categoryInfo)
                }
                //duplicar item categoria
                handleDuplicateCardapioItem={(itemInfo: any) =>
                  handleDuplicateCardapioItem(itemInfo)
                }
                //excluir item categoria
                handleDeleteCardapioItem={(itemInfo: any) =>
                  handleDeleteCardapioItem(itemInfo)
                }
                //Mudar status categoria
                handleChangeCategoryStatus={(itemInfo: any, status: string) =>
                  handleChangeCategoryStatus(itemInfo, status)
                }
                //Mudar status categoria Item
                handleChangeCategoryItemStatus={(
                  itemInfo: any,
                  status: string
                ) => handleChangeCategoryItemStatus(itemInfo, status)}
                handleGetCardapioItems={handleGetCardapioItems}
              />
            </>
          ))
        ) : (
          <NoItems title={'Nenhuma Categoria Encontrada !'} />
        )}
      </MenuRegistrationContainer>
      {addCategory && (
        //adicionar categoria//
        <SideModal
          editCategory={false}
          addCategory
          title={'Adicionar Categoria'}
          caption={'Detalhes da Nova Categoria'}
          close={() => setAddCategory(false)}
          item={null}
          handleGetCardapioItems={handleGetCardapioItems}
        />
      )}
      {isLoading && <ScaleLoader />}
    </PageCard>
  )
}

export default MenuRegistration
