import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  clearAuth,
  connectUser,
  disconnect,
  getLicenseToken,
  refreshUser,
  reset,
  setEnvironment
} from '../../store/features/auth/authSlice'
import { AppDispatch, RootState } from '../../store/store'
import { numberMask } from '../../utils/masks'
import agileLogo from '../../assets/images/logo/icone-agile-branco.png'
import backgroundAgile from '../../assets/images/logo/background-agile1.jpg'
import { useNavigate } from 'react-router-dom'
import { AuthContainer } from './styles'
import ScaleLoader from '../../components/ScaleLoader/ScaleLoader'
import { LicenseInfo, UserInfo } from '../../types/storage'
import { ErrorAlert } from '../../components/SweetAlert/SweetAlert'
import { EnvironmentProfile } from '../../types/auth'
import { decode } from 'js-base64'
import useQuery from '../../hooks/useQuery'

function Auth() {
  const {
    accessToken,
    userToken,
    environments,
    errorType,
    selectedEnvironment: currentEnvironment,
    message,
    isSuccess: isAuthSuccess,
    isLoading: isAuthLoading,
    isError: isAuthError,
    userData,
    storeList
  } = useSelector((state: RootState) => state.auth)

  const [license, setLicense] = useState<string>('')
  const [licenseVerified, setLicenseVerified] = useState<boolean>(false)

  const [licenseOnLocalStorage, setLicenseOnLocalStorage] = useState<string>('')

  const [selectedEnvironment, setSelectedEnvironment] = useState<string>('')

  const [queryParam, setQueryParam] = useState<boolean>(true)

  const [loginOpen, setLoginOpen] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [dataToGather, setDataToGather] = useState<boolean>(false)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const query = useQuery()

  useEffect(() => {
    if (isAuthError && message.length > 0) {
      ErrorAlert('Atenção', message)
    }
  }, [isAuthError, message])

  function resetScreen() {
    setLicenseVerified(false)
    setSelectedEnvironment('')
    setLoginOpen(false)
    setUsername('')
    setPassword('')
    dispatch(reset())
    dispatch(clearAuth())
    dispatch(disconnect())
  }

  function handleLicenseChange(event: ChangeEvent<HTMLInputElement>) {
    setLicense(numberMask(event.target.value) || '')
    resetScreen()

    localStorage.removeItem('appAgileHUBLicenseInfo')
    localStorage.removeItem('appAgileHUBUserInfo')

    setLicenseOnLocalStorage('')
  }

  function handleEnvironmentChange(event: ChangeEvent<HTMLSelectElement>) {
    setSelectedEnvironment(event.target.value)

    const currentEnv = environments.find(
      env => env.Id === Number(event.target.value)
    )

    if (!currentEnv) {
      setSelectedEnvironment('')
      closeProcess()
      return
    }

    localStorage.setItem(
      'appAgileHUBLicenseInfo',
      JSON.stringify({
        licenseNumber: license,
        envProfileId: currentEnv.Id
      } as LicenseInfo)
    )

    dispatch(setEnvironment(currentEnv))
  }

  const verifyLicense = useCallback(() => {
    if (!license) return

    dispatch(getLicenseToken({ license }))
  }, [dispatch, license])

  const connect = useCallback(() => {
    const connectUserParams = {
      username,
      password,
      url: currentEnvironment.Url,
      accessToken
    }

    dispatch(connectUser(connectUserParams))
  }, [accessToken, currentEnvironment?.Url, dispatch, password, username])

  const closeProcess = useCallback(() => {
    setDataToGather(false)
    dispatch(reset())
  }, [dispatch])

  const handleRefreshUser = useCallback(
    (token: string) => {
      dispatch(
        refreshUser({
          token: token,
          url: currentEnvironment.Url
        })
      )
    },
    [currentEnvironment?.Url, dispatch]
  )

  useEffect(() => {
    const authToken = query.get('z')

    if (!authToken) {
      setQueryParam(false)
      return
    }

    let transformedParam
    let licenseNumber

    try {
      transformedParam = decode(authToken)?.split('#')

      licenseNumber = transformedParam[0].split('@')[1]
    } catch (err: any) {
      setQueryParam(false)
      return
    }

    if (!licenseNumber) {
      setQueryParam(false)
      return
    }

    setLicenseOnLocalStorage(licenseNumber)
    dispatch(getLicenseToken({ license: licenseNumber }))
  }, [query, dispatch])

  useEffect(() => {
    const authToken = query.get('z')

    if (!authToken) return

    let transformedParam
    let envProfileId

    try {
      transformedParam = decode(authToken)?.split('#')
      envProfileId = transformedParam[1].split('@')[1]
    } catch (err: any) {
      return
    }

    const licenseInfo = envProfileId

    if (licenseInfo != null && licenseInfo !== undefined) {
      setSelectedEnvironment(licenseInfo)

      const currentEnv = environments.find(
        env => env.Id === Number(licenseInfo)
      )

      dispatch(setEnvironment(currentEnv as EnvironmentProfile))
      localStorage.setItem('appAgileHUBLicenseInfo', JSON.stringify({
        licenseNumber: license,
        envProfileId
      }))
    }
  }, [query, dispatch, environments, license])

  useEffect(() => {
    if (!license || queryParam) return

    const licenseInfo = JSON.parse(
      localStorage.getItem('appAgileHUBLicenseInfo') as string
    )?.envProfileId

    if (licenseInfo != null && licenseInfo !== undefined) {
      setSelectedEnvironment(licenseInfo)

      const currentEnv = environments.find(
        env => env.Id === Number(licenseInfo)
      )

      dispatch(setEnvironment(currentEnv as EnvironmentProfile))
    }
  }, [dispatch, environments, license, queryParam])

  useEffect(() => {
    function handleEnterKeyPress(e: KeyboardEvent) {
      if (e.code !== 'Enter' && e.code !== 'NumpadEnter') return

      if (!licenseVerified) {
        verifyLicense()
        return
      }

      if (
        licenseVerified &&
        username.length &&
        password.length &&
        currentEnvironment?.Url
      ) {
        connect()
        return
      }
    }

    document.addEventListener('keydown', handleEnterKeyPress)

    return () => document.removeEventListener('keydown', handleEnterKeyPress)
  }, [
    licenseVerified,
    verifyLicense,
    connect,
    currentEnvironment?.Url,
    password.length,
    username.length,
    storeList?.length
  ])

  useEffect(() => {
    if (queryParam) return

    const licenseInformation = localStorage.getItem('appAgileHUBLicenseInfo')

    const parsedLicenseInformation =
      licenseInformation && JSON.parse(licenseInformation)
    const localStorageLicense = parsedLicenseInformation?.licenseNumber

    if (!localStorageLicense) return

    dispatch(getLicenseToken({ license: localStorageLicense }))
    setLicenseOnLocalStorage(localStorageLicense)
  }, [dispatch, queryParam])

  useEffect(() => {
    if (isAuthSuccess && licenseOnLocalStorage) {
      setLicense(licenseOnLocalStorage)
    }
  }, [isAuthSuccess, licenseOnLocalStorage])
  ///////////////////////////////////////////

  // user info and environment gathering handler
  useEffect(() => {
    if (accessToken && dataToGather && environments) {
      const environment = environments.find(
        env => env.Id === Number(selectedEnvironment)
      )

      if (!environment) {
        closeProcess()
        return
      }

      dispatch(setEnvironment(environment))

      const localUserInfo = localStorage.getItem('appAgileHUBUserInfo')

      if (!localUserInfo?.length) {
        closeProcess()
        return
      }

      const parsedLocalUserInfo: UserInfo = JSON.parse(localUserInfo)
      handleRefreshUser(parsedLocalUserInfo.token)
    }
  }, [
    accessToken,
    dataToGather,
    closeProcess,
    handleRefreshUser,
    environments,
    dispatch,
    selectedEnvironment
  ])

  // license verifier handler
  useEffect(() => {
    if (accessToken) {
      setLicenseVerified(true)

      if (selectedEnvironment) {
        setLoginOpen(true)
      }
    }
  }, [accessToken, selectedEnvironment])

  // authentication success handler
  useEffect(() => {
    if (userData && userToken && storeList?.length > 0) {
      localStorage.setItem(
        'appAgileHUBUserInfo',
        JSON.stringify({
          name: userData.Name,
          token: userData.refresh_token
        } as UserInfo)
      )

      navigate('/')
    }
  }, [navigate, storeList?.length, userData, userToken])

  // error handling effect
  useEffect(() => {
    if (isAuthError && message) {
      if (!dataToGather) {
        // setError(message)
        ErrorAlert('Atenção', message)
      }

      if (errorType === 'license') {
        setLicense('')
      }
    }
  }, [isAuthError, message, errorType, dataToGather])

  return (
    <AuthContainer>
      <div className="split left">
        <div>
          <img src={agileLogo} className="agile-logo" alt="Logo Agile" />
        </div>
        <div className="centered">
          <img src={backgroundAgile} alt="bg-agile" />
        </div>
      </div>

      <div className="split right">
        <img src={agileLogo} className="agile-logo-form" alt="Logo Agile" />
        <div className="centered">
          <h3 className="label-theme-dark">Bem vindo ao Gestor de Pedidos!</h3>

          <div>
            <label className="input-field-name">Número da Licença</label>

            <input
              className="form-control"
              type="password"
              value={license}
              onChange={handleLicenseChange}
            />

            {environments && environments.length > 0 && (
              <select
                className="form-control "
                aria-label="Default select example"
                onChange={handleEnvironmentChange}
                value={selectedEnvironment}
              >
                <option className="global-option" value="" disabled>
                  Ambiente
                </option>
                {environments.map(env => (
                  <option className="global-option" key={env.Id} value={env.Id}>
                    {env.Nome}
                  </option>
                ))}
              </select>
            )}

            {loginOpen && (
              <div className="form-container">
                <input
                  type="text"
                  className="form-control"
                  value={username}
                  placeholder="Usuário"
                  onChange={e => setUsername(e.target.value)}
                />

                <input
                  type="password"
                  value={password}
                  placeholder="Senha"
                  className="form-control"
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            )}

            {!licenseVerified && (
              <button
                type="button"
                className="form-button"
                onClick={verifyLicense}
                disabled={!license.length}
              >
                Validar
              </button>
            )}

            {licenseVerified && (
              <button
                type="button"
                className="form-button"
                onClick={connect}
                disabled={
                  !(
                    username.length &&
                    password.length &&
                    currentEnvironment?.Url
                  )
                }
              >
                Conectar
              </button>
            )}
            <div className="version-controller">
              <p>Versão {process.env.REACT_APP_VERSION_SYSTEM}</p>
            </div>
          </div>
        </div>
      </div>
      {isAuthLoading && <ScaleLoader />}
      {/* {error && <Error text={error} close={handleCloseError} />} */}
    </AuthContainer>
  )
}

export default Auth
