import { Search } from 'lucide-react'
import { ReactNode } from 'react'
import { PageCardContainer } from './styles'

type PageCardProps = {
  title: string
  children: ReactNode
  button?: boolean
  buttonAction?: () => void
}

function PageCard({ title, children, button, buttonAction }: PageCardProps) {
  return (
    <PageCardContainer>
      <header>
        <h1>{title}</h1>
        
        {button === true && (
          <button
            type="button"
            onClick={buttonAction}
          >
            <Search />
          </button>
        )}
      </header>

      {children}
    </PageCardContainer>
  )
}

export default PageCard
