import Swal from 'sweetalert2'

export function ErrorAlert(
  title: string,
  text: string,
  action: () => void = () => {}
) {
  Swal.fire({
    icon: 'error',
    title: title,
    text: text,
    background: '#283046',
    color: '#f3f4f8',
    confirmButtonText: 'Ok',
    didClose: action
  })
}

export function SuccessAlert(
  title: string,
  text: string,
  action: () => void = () => {}
) {
  Swal.fire({
    icon: 'success',
    title: title,
    text: text,
    background: '#283046',
    color: '#f3f4f8',
    confirmButtonText: 'Ok',
    timer: 3500,
    didClose: action
  })
}
