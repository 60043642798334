import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import ScaleLoader from '../../../components/ScaleLoader/ScaleLoader'
import { RootState } from '../../../store/store'
import { ShippedOrder } from '../../../types/orders'

import mapPin from '../../../assets/images/pins/red_map_pin.png'
// import purpleMapPin from '../../../assets/images/pins/purple_bike.svg'
// import redMapPin from '../../../assets/images/pins/red_bike.svg'
import purpleMapPin from '../../../assets/images/pins/purple.png'
import redMapPin from '../../../assets/images/pins/red.png'

type MapProps = {
  selectedOrders: ShippedOrder[]
  selectByCode: (code: number) => void
}

const mapStyles = {
  width: '100%',
  height: '100%'
}

function Map({ selectedOrders, selectByCode }: MapProps) {
  const { storeLocation } = useSelector((state: RootState) => state.auth)
  const { expeditionOrders } = useSelector(
    (state: RootState) => state.expedition
  )

  const center = useMemo(
    () => ({
      lat: Number(storeLocation?.lat),
      lng: Number(storeLocation?.lng)
    }),
    [storeLocation]
  )

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || ''
  })

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          center={center}
          zoom={15}
          options={{ minZoom: 12 }}
        >
          <Marker
            position={{
              lat: Number(storeLocation.lat),
              lng: Number(storeLocation.lng)
            }}
            icon={mapPin}
          />
          {expeditionOrders.map(order => (
            <Marker
              key={order.CodigoSeq}
              position={{
                lat: Number(order?.Latitude),
                lng: Number(order?.Longitude)
              }}
              icon={
                selectedOrders.find(
                  selectedOrder => selectedOrder.CodigoSeq === order.CodigoSeq
                )
                  ? redMapPin
                  : purpleMapPin
              }
              onClick={() => selectByCode(order.CodigoSeq)}
            />
          ))}
        </GoogleMap>
      )}
      {!isLoaded && <ScaleLoader />}
    </>
  )
}

export default memo(Map)
