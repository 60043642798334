/* eslint-disable react-hooks/exhaustive-deps */
import { GraphicCardContainer } from '../styles'
import { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Legend
} from 'chart.js'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { Grid, BarChart3, DownloadCloud } from 'lucide-react'
import formatBRL from '../../../../../utils/formatBRL'
import getEnv from '../../../../../utils/getEnv'
import { colors } from '../../../../../utils/colors'
import Table, { rowHeaderItem } from '../../../../../components/Table'
import formatDate from '../../../../../utils/formatDate'
import { CSVLink } from 'react-csv'
import { ErrorAlert } from '../../../../../components/SweetAlert/SweetAlert'

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  locale: 'pt-BR',
  scales: {
    y: {
      ticks: {
        font: {
          size: 11
        },
        callback: (data: any) => {
          return formatBRL(data)
        }
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        padding: 20
      },
      position: 'right',
      Align: 'start'
    }
  }
}

type GraphicCardProps = {
  title: string
  Element: any
  type: number
  initialDateValue: string
  finalDateValue: string
  plusLoadingCounter: () => void
  minusLoadingCounter: () => void
  valueProperty: string
  labelProperty: string
  storeCode: number
  storeLabel: string
}

function GraphicCard({
  title,
  Element,
  type,
  initialDateValue,
  finalDateValue,
  plusLoadingCounter,
  minusLoadingCounter,
  valueProperty,
  labelProperty,
  storeCode,
  storeLabel
}: GraphicCardProps) {
  const { userToken, selectedEnvironment } = useSelector(
    (state: RootState) => state.auth
  )

  const [isGrid, setIsGrid] = useState<boolean>(false)

  const [serviceResponse, setServiceResponse] = useState<any>([])
  const [data, setData] = useState<any>({})

  useEffect(() => {
    ;(async () => {
      const env = getEnv(type)

      if (!env) return

      plusLoadingCounter()

      try {
        const response = await axios({
          method: 'GET',
          url: `${selectedEnvironment.Url}/${env}/${storeCode}/${initialDateValue}/${finalDateValue}`,
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        })

        setServiceResponse(response.data)

        const datasetLabels: any =
          type === 1
            ? [storeLabel]
            : Array.from(
                new Set(response.data.map((data: any) => data[labelProperty]))
              )

        let labels = response.data.map((report: any) => formatDate(report.DATA))

        labels.sort((a: any, b: any) => {
          const newA = a.split('/').reverse().join('-')
          const newB = b.split('/').reverse().join('-')
          return +new Date(newA) - +new Date(newB)
        })

        labels = Array.from(new Set(labels))

        const datasets =
          response.data.length > 0
            ? datasetLabels.map((label: any, index: number) => ({
                label,
                data: labels.map((dateLabel: string) => {
                  const currentItem = response.data.find((item: any) => {
                    if (type === 1) {
                      return formatDate(item.DATA) === dateLabel
                    }

                    return (
                      formatDate(item.DATA) === dateLabel &&
                      item[labelProperty] === label
                    )
                  })

                  return currentItem?.[valueProperty] || 0
                }),
                backgroundColor: colors[index],
                borderColor: colors[index]
              }))
            : []

        const data = {
          labels,
          datasets
        }

        ChartJS.defaults.color = '#f3f4f8'
        minusLoadingCounter()

        setData(data)
      } catch (err: any) {
        ErrorAlert('Atenção ', err)
        minusLoadingCounter()
      }
    })()
  }, [])

  const tableHeadItems: string[] = [
    'Código Loja',
    'Data',
    type === 1 ? '' : labelProperty === 'CANAL' ? 'Canal' : 'Meio de Venda',
    'Valor'
  ]

  const tableRowHeaders: rowHeaderItem[] = [
    {
      value: 'CODIGOLOJA'
    },
    {
      value: 'DATA',
      format: 'date'
    },
    {
      value: labelProperty
    },
    {
      value: valueProperty,
      format: 'currency'
    }
  ]

  return (
    <GraphicCardContainer>
      <div className="graph-head">
        <p>{title}</p>
        {data?.labels && data?.datasets?.length > 0 ? (
          <div className="actions">
            <CSVLink
              title="exportar para CSV"
              className="csv"
              data={serviceResponse}
              filename={`${title}-${initialDateValue}-ate-${finalDateValue}.csv`}
            >
              <DownloadCloud color="#f3f4f8" />
            </CSVLink>

            <button
              type="button"
              onClick={() => setIsGrid(true)}
              className={isGrid ? 'active' : undefined}
            >
              <Grid />
            </button>
            <button
              type="button"
              onClick={() => setIsGrid(false)}
              className={isGrid ? undefined : 'active'}
            >
              <BarChart3 />
            </button>
          </div>
        ) : null}
      </div>

      {data?.labels && data?.datasets?.length > 0 ? (
        !isGrid ? (
          <div className="chart">
            <Element options={options} data={data} />
          </div>
        ) : (
          <div className="table">
            <Table
              items={serviceResponse}
              headItems={tableHeadItems.filter(item => item !== '')}
              rowHeaders={tableRowHeaders.filter(item => item.value !== '')}
              noIndex={true}
              noCanal={true}
            />
          </div>
        )
      ) : (
        <p className="warning">Não existem dados para essa categoria.</p>
      )}
    </GraphicCardContainer>
  )
}

export default GraphicCard
