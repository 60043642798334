import { AlertCircle, ListOrdered, Plus, Search } from 'lucide-react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { UserStore } from '../../types/user'
import { SearchFilteringContainer } from './styles'

type SearchFilteringProps = {
  inputPlaceholder: string
  ButtonAddTitle: string
  OpenFilterOptionsFunction: () => void
  FilterOpenState: boolean
  FilterState: string
  functionChangeFilter: (value: string) => void
  onChangeInputFunction: (value: string) => void
  inputValue: any
  ButtonAddFamilyFunction: () => void
  setStore?: (storeCode: number) => void
  setStoreName?: (storeName: string) => void
  selectedStore?: number
  getList?: () => void
}

const typesOfFilter = [
  { id: 20, title: 'Ordenar A-Z', orderBy: 'AZ' },
  { id: 30, title: 'Ordenar Z-A', orderBy: 'ZA' },
  { id: 60, title: 'Ordenar por Ativados', orderBy: 'Activated' },
  { id: 70, title: 'Ordenar por Pausados', orderBy: 'Paused' }
]

function SearchFiltering({
  inputPlaceholder,
  FilterState,
  functionChangeFilter,
  ButtonAddFamilyFunction,
  setStore,
  setStoreName,
  selectedStore,
  inputValue,
  onChangeInputFunction,
  getList
}: SearchFilteringProps) {
  const [openFilter, setOpenFilter] = useState<boolean>(false)

  const { storeList } = useSelector((state: RootState) => state.auth)
  // const [stores, setStores] = useState<any>(storeList)

  function handleStoreChange(storeCode: string) {
    if (!setStore) return

    setStore(Number(storeCode))

    if (!setStoreName) return

    const currentStore: UserStore =
      storeList?.find(
        (store: UserStore) => store.CodigoExterno === Number(storeCode)
      ) || ({} as UserStore)

    if (!currentStore) return

    setStoreName(
      `${currentStore?.Sigla && `${currentStore.Sigla} - `}${
        currentStore?.Nome
      }`
    )
  }

  return (
    <SearchFilteringContainer>
      <div className="filter-container">
        <div className="form-container">
          <input
            type="text"
            className="form-control"
            placeholder={inputPlaceholder}
            value={inputValue}
            onChange={e => onChangeInputFunction(e.target.value)}
          />
          {storeList && storeList.length > 0 && (
            <select
              className="form-control"
              value={selectedStore}
              onChange={e => handleStoreChange(e.target.value)}
            >
              {storeList.map((store: UserStore) => (
                <option value={store.CodigoExterno} key={store.CodigoExterno}>
                  {store.CodigoExterno} - {store.Nome}
                </option>
              ))}
            </select>
          )}
          <button
            className="search-button"
            title={'Procurar Item ou categoria'}
            onClick={getList}
          >
            <Search color="#f3f4f8" />
          </button>
          <button onClick={ButtonAddFamilyFunction} className="add-button">
            <Plus color="#f3f4f8" />
          </button>
          <div className="dropdownCardapio">
            <button
              className="order-button"
              onClick={() => setOpenFilter(!openFilter)}
            >
              <ListOrdered color="#f3f4f8" />
              {openFilter && (
                <div className="dropdown-content">
                  {typesOfFilter.map(type => (
                    <button
                      key={type.id}
                      onClick={() => functionChangeFilter(type.orderBy)}
                      title={'Filtrar itens'}
                    >
                      {FilterState === type.orderBy ? (
                        <AlertCircle color="red" />
                      ) : (
                        ''
                      )}
                      {type.title}
                    </button>
                  ))}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </SearchFilteringContainer>
  )
}

export default SearchFiltering
