import styled from 'styled-components'

export const GraphicCardContainer = styled.div`
  width: 49%;
  padding: 2rem 0;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.card.background};

  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  
  .chart, .table {
    min-height: 28rem;
  }

  .table {
    width: 100%;
    height: 100%;

    padding: 0 2rem 2rem;
  }

  .graph-head{
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1rem;
    padding: 0 2rem;

    p {
      justify-content: center;
      font-size: 16px;
    }

    svg{
      color: ${({ theme }) => theme.colors.dark.cardText};

      &:hover {
        transform: scale(1.1);
      }
    }

    .actions {
      display: flex;
      
      > .csv{
        margin-top: 0.4rem;
        margin-right: 0.5rem;
      }
    }

    button {
      border-radius: .4rem;
      margin-right: 0.5rem;
      padding: .4rem;

      &:first-child {
        margin-right: 2rem;
        
      }
    }


    .active {
      outline: 2px solid ${({ theme }) => theme.colors.primary.normal};
    }
  }

  .chart {
    width: 90%;
    
    display: flex;
    align-items: center;
     
  }

  .warning {
    font-size: 1.4rem;

    margin: 1.8rem 0;

    opacity: .8;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const GraphicsContainer = styled.div`
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.dark.cardText};

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
