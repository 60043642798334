import styled from 'styled-components';

export const IntegratorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .cards-wrapper {
    display: flex;
    justify-content: space-around;
    /* Trocado de space-between por space around para atender a implantação.*/ 
    flex-wrap: wrap;

    .integrator-card{
      cursor: pointer;

      font-size: 1.2rem;

      display: flex;
      align-items: center;

      width: 18%;
      background: ${({ theme }) => theme.colors.dark.main};

      padding: 1rem;
      border-radius: 1rem;
      color: #fff;

      &.active {
        background: #A760FF;
      }
    }
  }

  .table-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
  }

  .activation-container{
    margin-top: 1.6rem;
    display: flex;
    justify-content:space-between;
    

    div{
      display: flex;
      justify-content:space-between;
      align-items: center;
      label{
      font-size: 14px;
      margin-left: 1rem;
      color: ${({ theme }) => theme.colors.dark.text};
    }
    }
    p{
      font-size: 14px;
    }

    
  }
  /* @media (max-width: 700px) {
    .integrator-card {
      width: 27%;

      margin-bottom: 1rem;
    }
  } */

  @media (max-width: 680px) {

    .cards-wrapper{
      display: block;
      
        .integrator-card {
        width: 100%;
        margin-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;

    }
    }
  
  }

  @media (min-width:1800px) {
    .cards-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .integrator-card{
    padding: 20px;

      &.active {
        background: #A760FF;
      }
    }
  }



}
`
