import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { clearAuth, reset, disconnect } from '../store/features/auth/authSlice'
import { ErrorAlert } from './SweetAlert/SweetAlert'

function StorageProtectionLayer() {
  const [credentialsOnStorage, setCredentialsOnStorage] = useState<boolean>(true)

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const localStorageUserInfo = localStorage.getItem('appAgileHUBUserInfo')
    const localStorageLicenseInfo = localStorage.getItem('appAgileHUBLicenseInfo')

    if (!localStorageLicenseInfo || !localStorageUserInfo) {
      setCredentialsOnStorage(false)
      dispatch(reset())
      dispatch(clearAuth())
      dispatch(disconnect())

      ErrorAlert('Desconectado', 'O seu token de conexão expirou ou foi perdido, por favor, refaça a autenticação.')
      return
    }

    setCredentialsOnStorage(true)
  }, [location.pathname, dispatch])

  if (credentialsOnStorage) {
    return <Outlet />
  } else {
    return <Navigate to="/auth" />
  }
}
export default StorageProtectionLayer