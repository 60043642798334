import { X } from 'lucide-react'
import ReactDOM from 'react-dom'
import { City, Neighbourhood } from '../../types/dne'
import Overlay from '../Overlay'
import SearchSelectorContainer from './styles'

type SearchSelectorProps = {
  data: City[] | Neighbourhood[]
  action: (value: City | Neighbourhood) => void
  close: () => void
  searchValue: string
  changeSearchValue: (value: string) => void
}

function SearchSelector({
  data,
  action,
  close,
  searchValue,
  changeSearchValue
}: SearchSelectorProps) {
  const searchSelectorPortal = document.getElementById('search-selector-portal')

  if (!searchSelectorPortal) return null

  function handleAction(value: City | Neighbourhood) {
    action(value)
    close()
  }

  return ReactDOM.createPortal(
    <Overlay>
      <SearchSelectorContainer>
        <div className="search-header">
          <button onClick={close}>
            <X />
          </button>
        </div>
        <div className="search-field">
          <input
            className="form-control"
            type="text"
            value={searchValue}
            onChange={e => changeSearchValue(e.target.value)}
          />
        </div>
        {searchValue.length >= 3 ? (
          <div className="search-results thematic-scroll">
            {data.map(data => (
              <button
                className="option"
                onClick={() => handleAction(data)}
                key={data.c}
              >
                {data.d}
              </button>
            ))}
          </div>
        ) : (
          <div className="instructions">
            <p>Insira pelo menos três caracteres para obter resultados.</p>
          </div>
        )}
      </SearchSelectorContainer>
    </Overlay>,
    searchSelectorPortal
  )
}

export default SearchSelector
