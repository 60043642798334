
import styled from 'styled-components'

export const HeaderContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary.normal};  
  border-radius: .8rem;
  color: ${({ theme }) => theme.colors.dark.text};
  border: 1px solid ${({ theme }) => theme.colors.card.border}; 
  width: 100%;

  padding: 1.6rem;
  margin-bottom: 2rem;

  display: flex;
  justify-content: flex-end;

  > button {
    color: ${({ theme }) => theme.colors.dark.text};
    display: none;
  }

  .theme-switcher{
    display: flex;
    margin-right:auto;
    margin-bottom: 5px;

    > button{
      margin-left: 2rem;
      color: ${({ theme }) => theme.colors.dark.text};
    }
  }
  .profile-info {
    display: flex;
    align-items: center;

    p {
      font-size: 1.4rem;
      margin-right: 1rem;
    }
  }

  @media (max-width: 968px) {
    justify-content: space-between;

    > button {
      display: block;
    }
  }
`