import styled from "styled-components";

export const ComplementCard = styled.div`
  .complement-card{
 display:flex;
 background: ${({ theme }) => theme.colors.dark.main};
 margin-bottom: 2rem;
 padding: 20px;
justify-content:space-between;

 .complement-card-title{
  > p{
    color: ${({ theme }) => theme.colors.dark.cardText};
   font-size: 20px;
 }
 }
 .complement-card-actions{
  align-items: center;
  display: flex;

  > .actions {
    display: flex;
   margin-right: 1rem;
    > .pause{
            width: 70px;
          color: ${({ theme }) => theme.colors.dark.cardText};
          padding: 1rem;
          height: 25px;
          background-color: #576f72;
          &:hover {
          opacity: 0.8;
        }
          }
      > .pause-active{
       background-color: #EB1D36;
       }
      > .actived{
        width: 70px;
          color: #fff;
          padding: 1rem;
          height: 25px;
          background-color: #576f72;
          &:hover {
          opacity: 0.8;
        }
      }
      > .actived-active{
       background-color: #7DCE13;
       }
      > .actions-svg {
        display: flex;

        > button {
          margin-left: 1rem;
        }

        .inactive {
          transition: all 0.3s;
          transform: rotate(0deg);
        }
        .active {
          transition: all 0.3s;
          transform: rotate(180deg);
        }
      }
    
  }
  
 

  > button {
         
        }

        .inactive {
          transition: all 0.3s;
          transform: rotate(0deg);
        }
        .active {
          transition: all 0.3s;
          transform: rotate(180deg);
         
        }

 }
}

.items-table{
  width: 100%;
    background: ${({ theme }) => theme.colors.dark.main};
    height: auto;
    > table {
      display: table;
      margin-bottom: 2rem;
      margin-top: -3rem;
      color: ${({ theme }) => theme.colors.dark.cardText};
      border-collapse: collapse;
      width: 100%;
      text-align: center;
      //cabeçalho tabela//
      > thead {
        
        > .thead-children {
          font-size: 14px;
          > th {
            border: 1px solid #fff;
            &:first-child {
              width: 40%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 20%;
            }
          }
        }
      }
      //corpo tabela//
      > tbody {
        height: 60px;
        > tr {
          height: 60px;
          &:hover {
            background: #554994;
          }
          > .tbody-item {
            display: flex;
           
           
          }

          > td {
            border: 1px solid #fff;
            
            > .tbody-price {
              justify-content: center;
              display: flex;
              align-items: center;
              gap: 2rem;
              > p {
                border: 1px solid hsla(0, 0%, 58.4%, 0.4);
                padding: 16px;
              }
           
            }
            > .detail-td {
              display: flex;
              justify-content: center;

              > .pause{
                  margin-left: 1rem;
                  width: 70px;
                  color: ${({ theme }) => theme.colors.dark.cardText};
                  padding: 1rem;
                  height: 25px;
                  background-color: #576f72;
              }
              > .actived{
                  width: 70px;
                  color: #fff;
                  padding: 1rem;
                  height: 25px;
                  background-color: #576f72;
              }

              > .pause-active{
                background-color: #EB1D36;
                }

              > .actived-active{
                background-color: #7DCE13;
                }

              > button {
                &:nth-child(3) {
                  margin-left: 1rem;
                  margin-right: 1rem;
                  border-radius: 1rem;
                }
                
              }
            }
          }
        }
      }
    }
}

          .addItem-container{
              padding: 10px;
              margin-top: -2rem;
              background:${({ theme }) => theme.colors.dark.main};
              margin-bottom: 1rem;
              display: flex;
              justify-content: space-between;

                      > p{
                        color: #fff;
                display: flex;
                font-size:16px;
                margin-left: 2rem;
               
               svg{
                margin-right: 1rem;
               }
              }
              > button{
                    width: 25%;           
                    margin-left: 1rem;
                    padding: 10px;
                    border-radius: 0.8rem;
                    color:${({ theme }) => theme.colors.dark.cardText};
                    margin-bottom: 0.5rem;
                    background-color: #EB1D36;

                    &:hover {
                      transform: translateY(-2px);}
                    &:active {
                      box-shadow: none;
                      transform: translateY(0);
                  
                }
                    }
            }
`