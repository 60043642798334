export const colors = [
  '#4B15B5',
  '#C2C920',
  '#FC2E7A',
  '#5E8CEB',
  '#98A6A7',
  '#9E1C17',
  '#825E1E',
  '#04FC82',
  '#500E8B',
  '#5CA0D9',
  '#E549C4',
  '#dd4400',
  '#75BF51',
  '#BC87A6',
  '#596DAA'
]