
const ifoodIntegratorData = [
  {
    label: 'Tipo Consulta',
    id: 1,
    type: 'select',
    filters: [
      {
        title: 'Todos',
        smug: 'TODOS'
      }, {
        title: 'Não integrados',
        smug: 'NAOINTEGRADOS'
      }, {
        title: 'Integrados',
        smug: 'INTEGRADOS'
      }
    ]
  },
  {
    label: 'Cliente',
    id: 2,
    type: 'text',
    area: "cliente",
    filters: []
  },

  {
    label: 'Pedido Ifood',
    id: 3,
    type: 'text',
    area: 'status',
    filter: []
  }
]

export default ifoodIntegratorData