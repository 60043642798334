import styled from 'styled-components';

export const DateSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.dark.cardText};
  background: ${({ theme }) => theme.colors.dark.main};
  border-radius: 1rem;
  padding: .8rem 2rem;
  width: 100%;


  .inputs {
    display: flex;

    .input-group {
      p {
        font-size: 1.4rem;
        margin-bottom: .6rem;
      }

      &:first-child {
        margin-right: 2rem;
      }

      &:nth-child(3) {
        margin-left: 2rem;
        
        select {
          color: ${({ theme }) => theme.colors.dark.cardText};
          height: 38px;
        }
      }
    }
  
    input {
      color: ${({ theme }) => theme.colors.dark.cardText};
      color-scheme: dark;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      width: 20px;
      height: 20px;
    }
    
  }

  .actions {
    display: flex;
    a {
      margin-right: 2rem;

      display: flex;
      align-items: center;
    }
  }

  button {
    transition: transform .1s ease-in;

    &:hover {
      transform: scale(1.15);
    }
  }

  button, a {
    color: #f3f4f8;
  }

  @media (max-width: 600px) {
    .inputs {
      flex-direction: column;

      flex: 1;

      margin-right: 2rem;

      .input-group {
        &:first-child {
          margin-right: 0;
        }

        &:nth-child(3) {
          margin-left: 0;
        }
      }
    }

    .actions {
      flex-direction: column;
   
      a {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }
`
