/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import DarkFilm from './components/DarkFilm'
import Routes from './components/Routes'
import ScaleLoader from './components/ScaleLoader/ScaleLoader'
import useQuery from './hooks/useQuery'
import {
  defineStoreInfo,
  getLicenseToken,
  getStores,
  refreshUser,
  reset,
  setEnvironment
} from './store/features/auth/authSlice'
import { setDefinedTheme } from './store/features/theme/themeSlice'
import { AppDispatch, RootState } from './store/store'
import GlobalStyle from './styles/globalStyle'
import { dark } from './styles/themes/dark'
import { light } from './styles/themes/light'
import { Address } from './types/address'
import { LicenseInfo, UserInfo } from './types/storage'

function App() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    accessToken,
    environments,
    selectedEnvironment,
    userData,
    userToken,
    licenseStoreCode,
    licenseStorage,
    isError: isAuthError,
    isSuccess: isAuthSuccess,
    storeList
  } = useSelector((state: RootState) => state.auth)

  const { theme } = useSelector((state: RootState) => state.theme)

  const query = useQuery()

  const [routesOpen, setRoutesOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [licenseInfoObject, setLicenseInfoObject] = useState<LicenseInfo>(
    {} as LicenseInfo
  )

  const [dataToGather, setDataToGather] = useState<boolean>(false)

  const closeProcess = useCallback(() => {
    setIsLoading(false)
    setDataToGather(false)
    setRoutesOpen(true)
    dispatch(reset())
  }, [dispatch])

  const verifyLicense = useCallback(
    (license: string) => {
      dispatch(getLicenseToken({ license }))
    },
    [dispatch]
  )

  const handleRefreshLogin = useCallback(
    (token: string, url: string) => {
      dispatch(
        refreshUser({
          token,
          url
        })
      )
    },
    [dispatch]
  )

  const handleDataGatheringProcess = useCallback(() => {
    const currentEnvironment = environments.find(
      env => env.Id === licenseInfoObject.envProfileId
    )

    if (!currentEnvironment) {
      closeProcess()
      return
    }

    dispatch(setEnvironment(currentEnvironment))

    const stringifiedLocalUserInfo = localStorage.getItem('appAgileHUBUserInfo')

    if (!stringifiedLocalUserInfo) {
      closeProcess()
      return
    }

    const localUserInfo: UserInfo = JSON.parse(stringifiedLocalUserInfo)

    handleRefreshLogin(localUserInfo.token, currentEnvironment?.Url)
  }, [
    dispatch,
    environments,
    handleRefreshLogin,
    licenseInfoObject.envProfileId,
    closeProcess
  ])

  const handleGetCurrentStoreInfo = useCallback(async () => {
    const env = process.env.REACT_APP_INFO

    if (
      !env ||
      !licenseStoreCode ||
      !licenseStorage ||
      !userToken ||
      !selectedEnvironment?.Url
    )
      return

    const response = await axios({
      method: 'GET',
      url: `${selectedEnvironment.Url}/${env}/${licenseStoreCode}/${licenseStorage}`,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })

    const currentStore = response.data.find(
      (store: any) => store.CodigoExterno === licenseStoreCode
    )

    const address: Address = {
      lat: currentStore?.Endereco?.Location?.Latitude || '',
      lng: currentStore?.Endereco?.Location?.Longitude || ''
    }

    if (address?.lat && address?.lng) {
      dispatch(defineStoreInfo(address))
    }
  }, [licenseStoreCode, licenseStorage, userToken, selectedEnvironment?.Url])

  const refreshHusky = useCallback(async () => {
    const env = process.env.REACT_APP_HUSKY_REFRESH

    if (!env || !selectedEnvironment?.Url || !accessToken) return

    await axios({
      method: 'POST',
      url: `${selectedEnvironment?.Url}/${env}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  }, [accessToken, selectedEnvironment?.Url])

  function getPreferredTheme() {
    const preferredTheme = localStorage.getItem('preferredTheme') as
      | 'dark'
      | 'light'

    if (!preferredTheme) {
      localStorage.setItem('preferredTheme', 'dark')
      return
    }

    dispatch(setDefinedTheme(preferredTheme))
  }

  useEffect(() => {
    const authToken = query.get('z')

    if (!authToken) return

    if (authToken) {
      navigate(`/auth?z=${authToken}`)
    }
  }, [])

  useEffect(() => {
    if (!userData?.Id) return

    refreshHusky()

    dispatch(
      getStores({
        url: selectedEnvironment.Url,
        token: userToken,
        cdemp: licenseStoreCode,
        storage: licenseStorage
      })
    )
  }, [userData, userToken, selectedEnvironment?.Url, refreshHusky])

  useEffect(() => {
    if (!dataToGather) return

    if (isAuthError) {
      closeProcess()
      return
    }

    if (isAuthSuccess && userToken && userData && storeList?.length > 0) {
      closeProcess()

      if (location.pathname === '/auth') {
        navigate('/')
      }

      return
    }
  }, [
    isAuthError,
    isAuthSuccess,
    navigate,
    userToken,
    userData,
    closeProcess,
    location.pathname,
    dataToGather,
    storeList
  ])

  useEffect(() => {
    const licenseInfo = localStorage.getItem('appAgileHUBLicenseInfo')
    const userInfo = localStorage.getItem('appAgileHUBUserInfo')

    if (licenseInfo && userInfo) {
      setIsLoading(true)
      setDataToGather(true)

      const parsedLicenseInfo: LicenseInfo = JSON.parse(licenseInfo)

      setLicenseInfoObject(parsedLicenseInfo)
      verifyLicense(parsedLicenseInfo.licenseNumber)
    } else {
      if (userInfo && !licenseInfo) {
        localStorage.removeItem('appAgileHUBUserInfo')
      }

      setRoutesOpen(true)
    }
  }, [verifyLicense])

  useEffect(() => {
    if (environments && environments.length > 0 && dataToGather) {
      handleDataGatheringProcess()
    }
  }, [environments, dataToGather, handleDataGatheringProcess])

  useEffect(() => {
    getPreferredTheme()
  }, [getPreferredTheme])

  useEffect(() => {
    handleGetCurrentStoreInfo()
  }, [handleGetCurrentStoreInfo])

  return (
    <div className="app">
      <ThemeProvider theme={theme === 'dark' ? dark : light}>
        <GlobalStyle />
        {routesOpen && <Routes />}
        <DarkFilm />
      </ThemeProvider>
      {isLoading && <ScaleLoader />}
    </div>
  )
}

export default App
