import styled from "styled-components";

export const CreateEditModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.dark.background};
  border-radius: 1rem;
  padding: 2rem 1.4rem;

  width: 90%;
  max-width: 40rem;

  span, button {
    font-size: 1.4rem;

    color: ${({ theme }) => theme.colors.dark.text};
  }

  input, select {
    width: 100%;
  }

  .error {
    color: #ff0000;
  }

  .heading {
    color: ${({ theme }) => theme.colors.dark.text};

    font-size: 1.4rem;

    position: relative;

    display: flex;
    justify-content: center;

    button {
      position: absolute;
      top: 50%;
      right: 1rem;

      transform: translateY(-50%);
    }
  }

  .field {
    margin: 1rem 0;
  }

  .quantity-fields {
    display: flex;
    gap: 2rem;

    .field-group {
      margin: 1rem 0;
      display: flex;
      align-items: center;

      button {
        margin: 0 .4rem;
      }

      input {
        margin: 0;
      }
    }
  }

  .quantity-fields button, .actions button {
    transition: transform .12s ease-in;

    &:hover {
      transform: scale(1.1);
    }
  }

  .actions {
    display: flex;
    justify-content: space-evenly;

    margin-top: 1rem;

    button {
      background: ${({ theme }) => theme.colors.dark.text};
      border-radius: 1rem;
      color: ${({ theme }) => theme.colors.dark.background};

      padding: .4rem 1rem;
    }
  }
`

export const MenuRegistrationContainer = styled.div`
  
  .filter-container{
    background:${({ theme }) => theme.colors.dark.main};
    display: block; 
    padding: 12px;
    margin-bottom: 1rem;
    border-radius: 0.7rem;
    align-items: center;
    justify-content: center;
    
    > .form-container{
      display: flex;
      gap: 2rem;
     

      > input{
        
        width: 100%;
      }
      > button{
      width: 56px;
      padding: 6px;
      border-radius: 0.8rem;
      margin-bottom: 0.5rem;
      will-change: transform;

      &:hover {
      transform: translateY(-2px);}
      &:active {
      box-shadow: none;
      transform: translateY(0);
        
    }  } 
      > .search-button{
        background: #0096FF;
      }

      > .add-button{
        background: #EB1D36;
      }

      > .dropdownCardapio{
        margin-left: 0;
        .order-button{
          
          background: #A66CFF;
        }
      }
     

       
    }
    
  }

  .addItem-container{
    padding: 10px;
    margin-top: -2rem;
    background:${({ theme }) => theme.colors.dark.main};
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

      > p{
      color: #fff;
      display: flex;
      font-size:16px;
      margin-left: 2rem; 

      svg{
        margin-right: 1rem;
      }
    }

    > button {
      width: 25%;           
      margin-left: 1rem;
      padding: 10px;
      border-radius: 0.8rem;
      color:${({ theme }) => theme.colors.dark.cardText};
      margin-bottom: 0.5rem;
      background-color: #EB1D36;

      &:hover {
        transform: translateY(-2px);
      }
      
      &:active {
        box-shadow: none;
        transform: translateY(0);
    
      }
    }
  }
      
    @media(max-width:768px){
      .filter-container{
        display: block;
        
        > button{
          width: 100%;
          margin-bottom: 1rem;
        }
        
        > select{
          width: 100%;
          margin-bottom: 1rem;
        }

        >div{
          width: 100%;
          > input{
            width: 95%;
          }
          > button{
            width: 5%;
            margin-bottom: 1rem;
          }
        }
      }
      .addItem-container{
       display: flex;
       justify-content: right;

       p{
        
        margin-right: 5rem;
       }

      
        > button{
          width: 40%; 
        }

      
      }
    }

    
`