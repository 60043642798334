import { ChangeEvent } from 'react'

type FormFieldProps = {
  info: {
    label: string
    value: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    error?: string
  }
}

function FormField({ info }: FormFieldProps) {
  const { label, ...fieldProps } = info
  
  return (
    <div>
      <label>CEP</label>
      <input
        className="form-control"
        { ...fieldProps }
      />
    </div>
  )
}

export default FormField