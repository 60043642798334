import styled from 'styled-components';

export const OverlayNotificationContainer = styled.div`
  background: ${({ theme }) => theme.colors.dark.main};
  border-radius: 1.6rem;
  color: ${({ theme }) => theme.colors.dark.cardText};

  padding: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70rem;
  p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  button {
    background: ${({ theme }) => theme.colors.dark.text};
    border-radius: 1rem;
    color: ${({ theme }) => theme.colors.dark.input};


    padding: .6rem 1.6rem;
  }
`