import { Copy, Edit2, MoreVertical, Trash2, Tags, ChefHat } from 'lucide-react'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SideModal from '../../sideModal/sideModal'
import genericImg from '../../../../../assets/images/generic/indisponivel.png'

type CategoryItemProps = {
  children: any
  fullObj?: any[]
  handleDuplicateCardapioItem: (itemInfo: any) => void
  handleDeleteCardapioItem: (itemInfo: any) => void
  handleChangeCategoryItemStatus: (itemInfo: any, status: string) => void
  handleGetCardapioItems: () => void
}

function CategoryItem({
  children,
  fullObj,
  handleDuplicateCardapioItem,
  handleDeleteCardapioItem,
  handleChangeCategoryItemStatus,
  handleGetCardapioItems
}: CategoryItemProps) {
  const [itemActiveActions, setItemActiveActions] = useState<boolean>(false)
  const [ItemModalOpen, setItemModalOpen] = useState<boolean>(false)
  // const [isPaused, setIsPaused] = useState<boolean>(false)
  // const [isActived, setIsActived] = useState<boolean>(true)

  const { Codigo, Nome, ReceitaCurta, Ativo, FotoUri } = children

  return (
    <>
      <tr key={Codigo}>
        <td className="tbody-item">
          <div className="img-container">
            <LazyLoadImage
              src={FotoUri}
              alt="Item do Cardapio"
              onError={(e: any) => {
                e.target.src = genericImg
              }}
            />
          </div>
          <div className="item-text-container">
            <h2>
              {Nome}
              <div>
                {children.Oferta === 'SIM' ? <Tags color="#EB1D36" /> : null}
              </div>
              <div>
                {children.Chef === 'SIM' ? <ChefHat color="#fff" /> : null}
              </div>
            </h2>

            <p>{ReceitaCurta}</p>
          </div>
        </td>

        <td>
          <div className="detail-td">
            <button
              className={!Ativo ? 'pause pause-active' : 'pause'}
              onClick={() => handleChangeCategoryItemStatus(children, 'Pausar')}
              disabled={!Ativo}
            >
              {!Ativo ? 'Pausado' : 'Pausar'}
            </button>
            <button
              className={Ativo ? 'actived actived-active' : 'actived'}
              onClick={() => handleChangeCategoryItemStatus(children, 'Ativar')}
              disabled={Ativo}
            >
              {Ativo ? 'Ativado' : 'Ativar'}
            </button>
            <div className="dropdownCardapio">
              <button onClick={() => setItemActiveActions(!itemActiveActions)}>
                <MoreVertical color="#f3f4f8" />
                {itemActiveActions && (
                  <div className="dropdown-content">
                    <button onClick={() => setItemModalOpen(true)}>
                      <Edit2 color="#f3f4f8" />
                      <abbr>Editar Item</abbr>
                    </button>
                    <button onClick={() => handleDeleteCardapioItem(children)}>
                      <Trash2 color="#f3f4f8" />
                      <abbr>Excluir Item</abbr>
                    </button>
                    <button
                      onClick={() => handleDuplicateCardapioItem(children)}
                    >
                      <Copy color="#f3f4f8" />
                      <abbr>Duplicar Item</abbr>
                    </button>
                  </div>
                )}
              </button>
            </div>
          </div>
        </td>
      </tr>
      {ItemModalOpen && (
        //Editar item//
        <SideModal
          item={children}
          fullObj={fullObj}
          close={() => setItemModalOpen(false)}
          caption={'Detalhes do Item'}
          editItem
          title={''}
          handleGetCardapioItems={handleGetCardapioItems}
        />
      )}
    </>
  )
}

export default CategoryItem
