import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ShippedOrder } from '../../../types/orders'
import { expeditionService } from './expeditionService'

type GetExpeditionOrdersProps = {
  storeCode: number,
  url: string,
  token: string
}

const initialState = {
  expeditionOrders: [] as ShippedOrder[],
  isLoading: false,
  isSuccess: false,
  isError: false,
  firstRequest: true,
  message: ''
}

export const getExpeditionOrders = createAsyncThunk(
  'expedition/get-orders',
  async (props: GetExpeditionOrdersProps, thunkApi) => {
    try {
      const response = await expeditionService.getExpeditionOrders(props.storeCode, props.url, props.token)

      if (response?.status !== 200) {
        throw new Error()
      }

      return response.data
    } catch (err: any) {
      const message = err?.response?.data?.Messages?.[0] || 'Não foi possível obter os pedidos para expedição'

      return thunkApi.rejectWithValue(message)
    }
  }
)

const expeditionSlice = createSlice({
  name: 'expedition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getExpeditionOrders.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getExpeditionOrders.fulfilled, (state, action: PayloadAction<ShippedOrder[]>) => {
      state.isLoading = false
      state.isSuccess = true
      state.expeditionOrders = action.payload
      state.firstRequest = false
    })
    .addCase(getExpeditionOrders.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = String(action.payload)
      state.firstRequest = false
    })
  }
})

export default expeditionSlice.reducer